/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type DeleteBusinessStatisticsInput = {
  id: string,
};

export type ModelBusinessStatisticsConditionInput = {
  updatedAt?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  lastSplitDate?: ModelStringInput | null,
  and?: Array< ModelBusinessStatisticsConditionInput | null > | null,
  or?: Array< ModelBusinessStatisticsConditionInput | null > | null,
  not?: ModelBusinessStatisticsConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type BusinessStatistics = {
  __typename: "BusinessStatistics",
  id: string,
  data?:  Array<BusinessStatisticsInfo | null > | null,
  updatedAt: string,
  owner: string,
  lastSplitDate?: string | null,
  createdAt: string,
};

export type BusinessStatisticsInfo = {
  __typename: "BusinessStatisticsInfo",
  date: string,
  totalTips: number,
  totalTipsCount: number,
};

export type DeleteProfileInput = {
  id: string,
};

export type ModelProfileConditionInput = {
  slug?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  and?: Array< ModelProfileConditionInput | null > | null,
  or?: Array< ModelProfileConditionInput | null > | null,
  not?: ModelProfileConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Profile = {
  __typename: "Profile",
  id: string,
  slug?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  bio?: Array< string | null > | null,
  picture?: string | null,
  location?: Location | null,
  createdAt: string,
  updatedAt: string,
  business?: Business | null,
  archived?: boolean | null,
  details?: ProfileDetails | null,
  employers?: ModelBusinessEmployeeConnection | null,
  experiences?: ModelExperienceConnection | null,
  jobs?: ModelProfileJobConnection | null,
};

export type Location = {
  __typename: "Location",
  searchName?: string | null,
  address?: string | null,
  address2?: string | null,
  city?: string | null,
  region?: string | null,
  postcode?: string | null,
  country?: string | null,
  latitude?: string | null,
  longitude?: string | null,
};

export type Business = {
  __typename: "Business",
  id: string,
  verified: boolean,
  name: string,
  tipping?: BusinessTippingSetting | null,
  marketing?: BusinessMarketingSetting | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
  code: string,
  answers?:  Array<Answer | null > | null,
  isOutlet?: boolean | null,
  details?: BusinessDetails | null,
  ownerProfile: Profile,
  invites?: ModelBusinessInviteConnection | null,
  employees?: ModelBusinessEmployeeConnection | null,
  brands?: ModelBusinessBrandsConnection | null,
};

export type BusinessTippingSetting = {
  __typename: "BusinessTippingSetting",
  merchantId?: string | null,
  policy?: string | null,
  type?: TippingType | null,
  active?: boolean | null,
};

export enum TippingType {
  individual = "individual",
  group = "group",
}


export type BusinessMarketingSetting = {
  __typename: "BusinessMarketingSetting",
  customerId?: string | null,
  subscriptionId?: string | null,
  active?: boolean | null,
  expirationDate?: string | null,
  sequenceId?: string | null,
  cancelled?: boolean | null,
  cancelledAt?: string | null,
};

export type Answer = {
  __typename: "Answer",
  questionId?: string | null,
  value?: Array< string | null > | null,
};

export type BusinessDetails = {
  __typename: "BusinessDetails",
  id: string,
  contactName?: string | null,
  jobRole?: string | null,
  phone?: string | null,
  email?: string | null,
  website?: string | null,
  location?: Location | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelBusinessInviteConnection = {
  __typename: "ModelBusinessInviteConnection",
  items:  Array<BusinessInvite | null >,
  nextToken?: string | null,
};

export type BusinessInvite = {
  __typename: "BusinessInvite",
  id: string,
  businessId: string,
  receiver?: string | null,
  createdAt: string,
  updatedAt: string,
  business: Business,
};

export type ModelBusinessEmployeeConnection = {
  __typename: "ModelBusinessEmployeeConnection",
  items:  Array<BusinessEmployee | null >,
  nextToken?: string | null,
};

export type BusinessEmployee = {
  __typename: "BusinessEmployee",
  id: string,
  jobRole: string,
  businessId: string,
  profileId: string,
  employeeId?: string | null,
  tippingPolicyAcknowledgedDate?: string | null,
  createdAt: string,
  updatedAt: string,
  business: Business,
  profile: Profile,
  confirmed?: boolean | null,
};

export type ModelBusinessBrandsConnection = {
  __typename: "ModelBusinessBrandsConnection",
  items:  Array<BusinessBrands | null >,
  nextToken?: string | null,
};

export type BusinessBrands = {
  __typename: "BusinessBrands",
  id: string,
  businessId: string,
  brandId: string,
  status: BrandStatus,
  createdAt: string,
  updatedAt: string,
  business: Business,
  profileId?: string | null,
  brand: Brand,
};

export enum BrandStatus {
  current = "current",
  past = "past",
  future = "future",
}


export type Brand = {
  __typename: "Brand",
  id: string,
  name: string,
  logo?: string | null,
  active?: boolean | null,
  productType?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ProfileDetails = {
  __typename: "ProfileDetails",
  id: string,
  accountType?: string | null,
  isOnboardingComplete?: boolean | null,
  email?: string | null,
  feedRegion?: string | null,
  tippingAccount?: TippingAccount | null,
  phone_number?: string | null,
  phoneNumberConfirmed?: boolean | null,
  pushNotificationToken?: string | null,
  pushNotificationReadDate?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type TippingAccount = {
  __typename: "TippingAccount",
  code?: string | null,
  merchant?: Merchant | null,
  accountId?: string | null,
  verified?: boolean | null,
  missingRequirements?:  Array<MissingRequirement | null > | null,
  pendingValidation?: Array< string | null > | null,
  bankDetails?: BankDetails | null,
};

export enum Merchant {
  STRIPE = "STRIPE",
  PAYNT = "PAYNT",
}


export type MissingRequirement = {
  __typename: "MissingRequirement",
  key?: string | null,
  description?: string | null,
};

export type BankDetails = {
  __typename: "BankDetails",
  name?: string | null,
  sortCode?: string | null,
  last4Digits?: string | null,
};

export type ModelExperienceConnection = {
  __typename: "ModelExperienceConnection",
  items:  Array<Experience | null >,
  nextToken?: string | null,
};

export type Experience = {
  __typename: "Experience",
  id: string,
  jobTitle?: string | null,
  businessId?: string | null,
  companyName: string,
  profileId: string,
  startDate?: string | null,
  endDate?: string | null,
  employmentType?: string | null,
  location?: string | null,
  description?: string | null,
  createdAt: string,
  updatedAt: string,
  business?: Business | null,
  profile?: Profile | null,
};

export type ModelProfileJobConnection = {
  __typename: "ModelProfileJobConnection",
  items:  Array<ProfileJob | null >,
  nextToken?: string | null,
};

export type ProfileJob = {
  __typename: "ProfileJob",
  id: string,
  profileId: string,
  jobId: string,
  coverLetter?: Array< string | null > | null,
  saved?: boolean | null,
  savedDate?: string | null,
  applied?: boolean | null,
  appliedDate?: string | null,
  createdAt?: string | null,
  updatedAt: string,
  profile: Profile,
  job: Job,
  rejected?: boolean | null,
};

export type Job = {
  __typename: "Job",
  id: string,
  owner?: string | null,
  jobType: string,
  businessId: string,
  jobRole: string,
  title: string,
  description?: Array< string | null > | null,
  salary?: Salary | null,
  region: string,
  requireCV?: boolean | null,
  requireCoverLetter?: boolean | null,
  createdAt?: string | null,
  archived?: boolean | null,
  startDate?: string | null,
  endDate?: string | null,
  updatedAt: string,
  business: Business,
  reported?: ModelReportInfoConnection | null,
};

export type Salary = {
  __typename: "Salary",
  type: string,
  from?: string | null,
  to?: string | null,
};

export type ModelReportInfoConnection = {
  __typename: "ModelReportInfoConnection",
  items:  Array<ReportInfo | null >,
  nextToken?: string | null,
};

export type ReportInfo = {
  __typename: "ReportInfo",
  id: string,
  postId?: string | null,
  userId?: string | null,
  commentId?: string | null,
  jobId?: string | null,
  creatorId: string,
  createdAt: string,
  message: string,
  updatedAt: string,
  creator: Profile,
};

export type DeleteProfileDetailsInput = {
  id: string,
};

export type ModelProfileDetailsConditionInput = {
  accountType?: ModelStringInput | null,
  isOnboardingComplete?: ModelBooleanInput | null,
  email?: ModelStringInput | null,
  feedRegion?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  phoneNumberConfirmed?: ModelBooleanInput | null,
  pushNotificationToken?: ModelStringInput | null,
  pushNotificationReadDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProfileDetailsConditionInput | null > | null,
  or?: Array< ModelProfileDetailsConditionInput | null > | null,
  not?: ModelProfileDetailsConditionInput | null,
};

export type UpdateBusinessInviteInput = {
  id: string,
  businessId?: string | null,
  receiver?: string | null,
};

export type ModelBusinessInviteConditionInput = {
  receiver?: ModelStringInput | null,
  and?: Array< ModelBusinessInviteConditionInput | null > | null,
  or?: Array< ModelBusinessInviteConditionInput | null > | null,
  not?: ModelBusinessInviteConditionInput | null,
};

export type DeleteBusinessInviteInput = {
  id: string,
};

export type UpdateBusinessBrandsInput = {
  id: string,
  businessId?: string | null,
  brandId?: string | null,
  status?: BrandStatus | null,
};

export type ModelBusinessBrandsConditionInput = {
  businessId?: ModelIDInput | null,
  brandId?: ModelIDInput | null,
  status?: ModelBrandStatusInput | null,
  and?: Array< ModelBusinessBrandsConditionInput | null > | null,
  or?: Array< ModelBusinessBrandsConditionInput | null > | null,
  not?: ModelBusinessBrandsConditionInput | null,
};

export type ModelBrandStatusInput = {
  eq?: BrandStatus | null,
  ne?: BrandStatus | null,
};

export type DeleteBusinessBrandsInput = {
  id: string,
};

export type DeleteBrandInput = {
  id: string,
};

export type ModelBrandConditionInput = {
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  productType?: ModelStringInput | null,
  and?: Array< ModelBrandConditionInput | null > | null,
  or?: Array< ModelBrandConditionInput | null > | null,
  not?: ModelBrandConditionInput | null,
};

export type DeleteExperienceInput = {
  id: string,
};

export type ModelExperienceConditionInput = {
  jobTitle?: ModelStringInput | null,
  businessId?: ModelIDInput | null,
  companyName?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  employmentType?: ModelStringInput | null,
  location?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelExperienceConditionInput | null > | null,
  or?: Array< ModelExperienceConditionInput | null > | null,
  not?: ModelExperienceConditionInput | null,
};

export type DeleteJobInput = {
  id: string,
};

export type ModelJobConditionInput = {
  jobType?: ModelStringInput | null,
  businessId?: ModelIDInput | null,
  jobRole?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  region?: ModelStringInput | null,
  requireCV?: ModelBooleanInput | null,
  requireCoverLetter?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  and?: Array< ModelJobConditionInput | null > | null,
  or?: Array< ModelJobConditionInput | null > | null,
  not?: ModelJobConditionInput | null,
};

export type DeleteProfileJobInput = {
  id: string,
};

export type ModelProfileJobConditionInput = {
  jobId?: ModelIDInput | null,
  coverLetter?: ModelStringInput | null,
  saved?: ModelBooleanInput | null,
  savedDate?: ModelStringInput | null,
  applied?: ModelBooleanInput | null,
  appliedDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  rejected?: ModelBooleanInput | null,
  and?: Array< ModelProfileJobConditionInput | null > | null,
  or?: Array< ModelProfileJobConditionInput | null > | null,
  not?: ModelProfileJobConditionInput | null,
};

export type DeleteUserNotificationInput = {
  id: string,
};

export type ModelUserNotificationConditionInput = {
  senderId?: ModelIDInput | null,
  type?: ModelNotificationTypeInput | null,
  targetId?: ModelIDInput | null,
  subTargetId?: ModelIDInput | null,
  additionalTargetId?: ModelIDInput | null,
  read?: ModelBooleanInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserNotificationConditionInput | null > | null,
  or?: Array< ModelUserNotificationConditionInput | null > | null,
  not?: ModelUserNotificationConditionInput | null,
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export enum NotificationType {
  postLike = "postLike",
  commentLike = "commentLike",
  postComment = "postComment",
  commentReply = "commentReply",
  userTipped = "userTipped",
  setupBusinessTipping = "setupBusinessTipping",
  profileJob = "profileJob",
  employeeRequest = "employeeRequest",
  applicationRejected = "applicationRejected",
  employeeTransfer = "employeeTransfer",
  tipsAvailable = "tipsAvailable",
  businessPolicyAccepted = "businessPolicyAccepted",
  businessEmployeeConfirmed = "businessEmployeeConfirmed",
}


export type UserNotification = {
  __typename: "UserNotification",
  id: string,
  receiverId: string,
  senderId?: string | null,
  type: NotificationType,
  targetId?: string | null,
  subTargetId?: string | null,
  additionalTargetId?: string | null,
  read: boolean,
  title?: string | null,
  message?: string | null,
  createdAt?: string | null,
  updatedAt: string,
  sender?: Profile | null,
};

export type UpdatePublicEmailInput = {
  id: string,
  email?: string | null,
  allowMarketing?: boolean | null,
};

export type ModelPublicEmailConditionInput = {
  email?: ModelStringInput | null,
  allowMarketing?: ModelBooleanInput | null,
  and?: Array< ModelPublicEmailConditionInput | null > | null,
  or?: Array< ModelPublicEmailConditionInput | null > | null,
  not?: ModelPublicEmailConditionInput | null,
};

export type PublicEmail = {
  __typename: "PublicEmail",
  id: string,
  email: string,
  allowMarketing: boolean,
  createdAt: string,
  updatedAt: string,
};

export type DeletePublicEmailInput = {
  id: string,
};

export type DeleteTipSplitInput = {
  id: string,
};

export type ModelTipSplitConditionInput = {
  amount?: ModelFloatInput | null,
  status?: ModelTransactionStatusInput | null,
  from?: ModelStringInput | null,
  to?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTipSplitConditionInput | null > | null,
  or?: Array< ModelTipSplitConditionInput | null > | null,
  not?: ModelTipSplitConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelTransactionStatusInput = {
  eq?: TransactionStatus | null,
  ne?: TransactionStatus | null,
};

export enum TransactionStatus {
  pending = "pending",
  scheduled = "scheduled",
  received = "received",
  partiallySent = "partiallySent",
  sent = "sent",
  fail = "fail",
}


export type TipSplit = {
  __typename: "TipSplit",
  id: string,
  amount: number,
  owner: string,
  status: TransactionStatus,
  from: string,
  to: string,
  createdAt: string,
  updatedAt: string,
  ownerProfile: Profile,
  transfers?: ModelEmployeeTransferConnection | null,
  transactions?: ModelTipTransactionConnection | null,
};

export type ModelEmployeeTransferConnection = {
  __typename: "ModelEmployeeTransferConnection",
  items:  Array<EmployeeTransfer | null >,
  nextToken?: string | null,
};

export type EmployeeTransfer = {
  __typename: "EmployeeTransfer",
  id: string,
  splitId: string,
  employeeId: string,
  amount: number,
  status: TransactionStatus,
  createdAt: string,
  updatedAt: string,
  employee?: Profile | null,
};

export type ModelTipTransactionConnection = {
  __typename: "ModelTipTransactionConnection",
  items:  Array<TipTransaction | null >,
  nextToken?: string | null,
};

export type TipTransaction = {
  __typename: "TipTransaction",
  id: string,
  splitId?: string | null,
  receiverId: string,
  amount: string,
  fee: string,
  currency: string,
  createdAt: string,
  status: TransactionStatus,
  type: TippingType,
  source: TipTransactionSource,
  payoutInvoiceId?: string | null,
  transactionId?: string | null,
  updatedAt: string,
  receiver?: Profile | null,
};

export enum TipTransactionSource {
  QR = "QR",
  terminal = "terminal",
  contactless = "contactless",
}


export type DeleteTipTransactionInput = {
  id: string,
};

export type ModelTipTransactionConditionInput = {
  splitId?: ModelIDInput | null,
  amount?: ModelStringInput | null,
  fee?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  status?: ModelTransactionStatusInput | null,
  type?: ModelTippingTypeInput | null,
  source?: ModelTipTransactionSourceInput | null,
  payoutInvoiceId?: ModelIDInput | null,
  transactionId?: ModelIDInput | null,
  and?: Array< ModelTipTransactionConditionInput | null > | null,
  or?: Array< ModelTipTransactionConditionInput | null > | null,
  not?: ModelTipTransactionConditionInput | null,
};

export type ModelTippingTypeInput = {
  eq?: TippingType | null,
  ne?: TippingType | null,
};

export type ModelTipTransactionSourceInput = {
  eq?: TipTransactionSource | null,
  ne?: TipTransactionSource | null,
};

export type UpdateUserMessageInput = {
  id: string,
  senderId?: string | null,
  receiverId?: string | null,
  content?: string | null,
  createdAt?: string | null,
};

export type ModelUserMessageConditionInput = {
  receiverId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserMessageConditionInput | null > | null,
  or?: Array< ModelUserMessageConditionInput | null > | null,
  not?: ModelUserMessageConditionInput | null,
};

export type UserMessage = {
  __typename: "UserMessage",
  id: string,
  senderId: string,
  receiverId: string,
  content: string,
  createdAt: string,
  updatedAt: string,
  sender?: Profile | null,
};

export type DeleteUserMessageInput = {
  id: string,
};

export type CreateProfileEnterpriseInput = {
  id?: string | null,
  name: string,
  address: LocationInput,
  picture?: string | null,
  companyNumber?: string | null,
  businesses?: Array< string | null > | null,
  active: boolean,
  contactDetails: ProfileEnterpriseContactDetailsInput,
};

export type LocationInput = {
  searchName?: string | null,
  address?: string | null,
  address2?: string | null,
  city?: string | null,
  region?: string | null,
  postcode?: string | null,
  country?: string | null,
  latitude?: string | null,
  longitude?: string | null,
};

export type ProfileEnterpriseContactDetailsInput = {
  name: string,
  role: string,
  phone: string,
  email: string,
};

export type ModelProfileEnterpriseConditionInput = {
  name?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  companyNumber?: ModelStringInput | null,
  businesses?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelProfileEnterpriseConditionInput | null > | null,
  or?: Array< ModelProfileEnterpriseConditionInput | null > | null,
  not?: ModelProfileEnterpriseConditionInput | null,
};

export type ProfileEnterprise = {
  __typename: "ProfileEnterprise",
  id: string,
  name: string,
  address: Location,
  companyNumber?: string | null,
  businesses?: Array< string | null > | null,
  active: boolean,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
  picture?: string | null,
  contactDetails: ProfileEnterpriseContactDetails,
};

export type ProfileEnterpriseContactDetails = {
  __typename: "ProfileEnterpriseContactDetails",
  name: string,
  role: string,
  phone: string,
  email: string,
};

export type UpdateProfileEnterpriseInput = {
  id: string,
  name?: string | null,
  address?: LocationInput | null,
  picture?: string | null,
  companyNumber?: string | null,
  businesses?: Array< string | null > | null,
  active?: boolean | null,
  contactDetails?: ProfileEnterpriseContactDetailsInput | null,
};

export type DeleteProfileEnterpriseInput = {
  id: string,
};

export type CreateBusinessStatisticsInput = {
  id?: string | null,
  data?: Array< BusinessStatisticsInfoInput | null > | null,
  updatedAt?: string | null,
  owner: string,
  lastSplitDate?: string | null,
};

export type BusinessStatisticsInfoInput = {
  date: string,
  totalTips: number,
  totalTipsCount: number,
};

export type UpdateBusinessStatisticsInput = {
  id: string,
  data?: Array< BusinessStatisticsInfoInput | null > | null,
  updatedAt?: string | null,
  owner?: string | null,
  lastSplitDate?: string | null,
};

export type CreateBusinessInput = {
  id?: string | null,
  verified: boolean,
  name: string,
  code: string,
  answers?: Array< AnswerInput | null > | null,
  tipping?: BusinessTippingSettingInput | null,
  marketing?: BusinessMarketingSettingInput | null,
  isOutlet?: boolean | null,
  createdAt?: string | null,
};

export type AnswerInput = {
  questionId?: string | null,
  value?: Array< string | null > | null,
};

export type BusinessTippingSettingInput = {
  merchantId?: string | null,
  policy?: string | null,
  type?: TippingType | null,
  active?: boolean | null,
};

export type BusinessMarketingSettingInput = {
  customerId?: string | null,
  subscriptionId?: string | null,
  active?: boolean | null,
  expirationDate?: string | null,
  sequenceId?: string | null,
  cancelled?: boolean | null,
  cancelledAt?: string | null,
};

export type ModelBusinessConditionInput = {
  verified?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  isOutlet?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelBusinessConditionInput | null > | null,
  or?: Array< ModelBusinessConditionInput | null > | null,
  not?: ModelBusinessConditionInput | null,
};

export type UpdateBusinessInput = {
  id: string,
  verified?: boolean | null,
  name?: string | null,
  code?: string | null,
  answers?: Array< AnswerInput | null > | null,
  tipping?: BusinessTippingSettingInput | null,
  marketing?: BusinessMarketingSettingInput | null,
  isOutlet?: boolean | null,
  createdAt?: string | null,
};

export type DeleteBusinessInput = {
  id: string,
};

export type CreateBusinessDetailsInput = {
  id?: string | null,
  contactName?: string | null,
  jobRole?: string | null,
  phone?: string | null,
  email?: string | null,
  website?: string | null,
  location?: LocationInput | null,
};

export type ModelBusinessDetailsConditionInput = {
  contactName?: ModelStringInput | null,
  jobRole?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  website?: ModelStringInput | null,
  and?: Array< ModelBusinessDetailsConditionInput | null > | null,
  or?: Array< ModelBusinessDetailsConditionInput | null > | null,
  not?: ModelBusinessDetailsConditionInput | null,
};

export type UpdateBusinessDetailsInput = {
  id: string,
  contactName?: string | null,
  jobRole?: string | null,
  phone?: string | null,
  email?: string | null,
  website?: string | null,
  location?: LocationInput | null,
};

export type DeleteBusinessDetailsInput = {
  id: string,
};

export type CreateProfileInput = {
  id?: string | null,
  slug?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  bio?: Array< string | null > | null,
  picture?: string | null,
  location?: LocationInput | null,
  archived?: boolean | null,
};

export type UpdateProfileInput = {
  id: string,
  slug?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  bio?: Array< string | null > | null,
  picture?: string | null,
  location?: LocationInput | null,
  archived?: boolean | null,
};

export type CreateProfileDetailsInput = {
  id?: string | null,
  accountType?: string | null,
  isOnboardingComplete?: boolean | null,
  email?: string | null,
  feedRegion?: string | null,
  tippingAccount?: TippingAccountInput | null,
  phone_number?: string | null,
  phoneNumberConfirmed?: boolean | null,
  pushNotificationToken?: string | null,
  pushNotificationReadDate?: string | null,
  createdAt?: string | null,
};

export type TippingAccountInput = {
  code?: string | null,
  merchant?: Merchant | null,
  accountId?: string | null,
  verified?: boolean | null,
  missingRequirements?: Array< MissingRequirementInput | null > | null,
  pendingValidation?: Array< string | null > | null,
  bankDetails?: BankDetailsInput | null,
};

export type MissingRequirementInput = {
  key?: string | null,
  description?: string | null,
};

export type BankDetailsInput = {
  name?: string | null,
  sortCode?: string | null,
  last4Digits?: string | null,
};

export type UpdateProfileDetailsInput = {
  id: string,
  accountType?: string | null,
  isOnboardingComplete?: boolean | null,
  email?: string | null,
  feedRegion?: string | null,
  tippingAccount?: TippingAccountInput | null,
  phone_number?: string | null,
  phoneNumberConfirmed?: boolean | null,
  pushNotificationToken?: string | null,
  pushNotificationReadDate?: string | null,
  createdAt?: string | null,
};

export type CreateBusinessInviteInput = {
  id?: string | null,
  businessId: string,
  receiver?: string | null,
};

export type CreateBusinessEmployeeInput = {
  id?: string | null,
  jobRole: string,
  businessId: string,
  profileId: string,
  confirmed?: boolean | null,
  employeeId?: string | null,
  tippingPolicyAcknowledgedDate?: string | null,
};

export type ModelBusinessEmployeeConditionInput = {
  jobRole?: ModelStringInput | null,
  profileId?: ModelIDInput | null,
  confirmed?: ModelBooleanInput | null,
  employeeId?: ModelStringInput | null,
  tippingPolicyAcknowledgedDate?: ModelStringInput | null,
  and?: Array< ModelBusinessEmployeeConditionInput | null > | null,
  or?: Array< ModelBusinessEmployeeConditionInput | null > | null,
  not?: ModelBusinessEmployeeConditionInput | null,
};

export type UpdateBusinessEmployeeInput = {
  id: string,
  jobRole?: string | null,
  businessId?: string | null,
  profileId?: string | null,
  confirmed?: boolean | null,
  employeeId?: string | null,
  tippingPolicyAcknowledgedDate?: string | null,
};

export type DeleteBusinessEmployeeInput = {
  id: string,
};

export type CreateBusinessBrandsInput = {
  id?: string | null,
  businessId: string,
  brandId: string,
  status: BrandStatus,
};

export type CreateBrandInput = {
  id?: string | null,
  name: string,
  logo?: string | null,
  active?: boolean | null,
  productType?: string | null,
};

export type UpdateBrandInput = {
  id: string,
  name?: string | null,
  logo?: string | null,
  active?: boolean | null,
  productType?: string | null,
};

export type CreateExperienceInput = {
  id?: string | null,
  jobTitle?: string | null,
  businessId?: string | null,
  companyName: string,
  profileId: string,
  startDate?: string | null,
  endDate?: string | null,
  employmentType?: string | null,
  location?: string | null,
  description?: string | null,
};

export type UpdateExperienceInput = {
  id: string,
  jobTitle?: string | null,
  businessId?: string | null,
  companyName?: string | null,
  profileId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  employmentType?: string | null,
  location?: string | null,
  description?: string | null,
};

export type CreatePostInput = {
  id?: string | null,
  hubId: string,
  creatorId: string,
  content?: Array< string | null > | null,
  createdAt?: string | null,
  archived?: boolean | null,
  feedRegion: string,
  regions?: Array< string > | null,
  attachments?: Array< AttachmentInput | null > | null,
};

export type AttachmentInput = {
  mediaType: string,
  source: string,
  alt?: string | null,
};

export type ModelPostConditionInput = {
  hubId?: ModelStringInput | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  feedRegion?: ModelStringInput | null,
  regions?: ModelStringInput | null,
  and?: Array< ModelPostConditionInput | null > | null,
  or?: Array< ModelPostConditionInput | null > | null,
  not?: ModelPostConditionInput | null,
};

export type Post = {
  __typename: "Post",
  id: string,
  hubId: string,
  creatorId: string,
  content?: Array< string | null > | null,
  createdAt?: string | null,
  archived?: boolean | null,
  feedRegion: string,
  regions?: Array< string > | null,
  attachments?:  Array<attachment | null > | null,
  updatedAt: string,
  creator: Profile,
  comments?: ModelCommentConnection | null,
  reported?: ModelReportInfoConnection | null,
  likes?: ModelPostLikeConnection | null,
};

export type attachment = {
  __typename: "attachment",
  mediaType: string,
  source: string,
  alt?: string | null,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  postId?: string | null,
  commentId?: string | null,
  content?: Array< string | null > | null,
  creatorId: string,
  createdAt: string,
  archived?: boolean | null,
  attachments?:  Array<attachment | null > | null,
  updatedAt: string,
  creator: Profile,
  post?: Post | null,
  comments?: ModelCommentConnection | null,
  reported?: ModelReportInfoConnection | null,
  likes?: ModelCommentLikeConnection | null,
};

export type ModelCommentLikeConnection = {
  __typename: "ModelCommentLikeConnection",
  items:  Array<CommentLike | null >,
  nextToken?: string | null,
};

export type CommentLike = {
  __typename: "CommentLike",
  id: string,
  commentId: string,
  profileId: string,
  createdAt: string,
  updatedAt: string,
  profile: Profile,
};

export type ModelPostLikeConnection = {
  __typename: "ModelPostLikeConnection",
  items:  Array<PostLike | null >,
  nextToken?: string | null,
};

export type PostLike = {
  __typename: "PostLike",
  id: string,
  postId: string,
  profileId: string,
  createdAt: string,
  updatedAt: string,
  profile: Profile,
};

export type UpdatePostInput = {
  id: string,
  hubId?: string | null,
  creatorId?: string | null,
  content?: Array< string | null > | null,
  createdAt?: string | null,
  archived?: boolean | null,
  feedRegion?: string | null,
  regions?: Array< string > | null,
  attachments?: Array< AttachmentInput | null > | null,
};

export type DeletePostInput = {
  id: string,
};

export type CreateJobInput = {
  id?: string | null,
  owner?: string | null,
  jobType: string,
  businessId: string,
  jobRole: string,
  title: string,
  description?: Array< string | null > | null,
  salary?: SalaryInput | null,
  region: string,
  requireCV?: boolean | null,
  requireCoverLetter?: boolean | null,
  createdAt?: string | null,
  archived?: boolean | null,
  startDate?: string | null,
  endDate?: string | null,
};

export type SalaryInput = {
  type: string,
  from?: string | null,
  to?: string | null,
};

export type UpdateJobInput = {
  id: string,
  owner?: string | null,
  jobType?: string | null,
  businessId?: string | null,
  jobRole?: string | null,
  title?: string | null,
  description?: Array< string | null > | null,
  salary?: SalaryInput | null,
  region?: string | null,
  requireCV?: boolean | null,
  requireCoverLetter?: boolean | null,
  createdAt?: string | null,
  archived?: boolean | null,
  startDate?: string | null,
  endDate?: string | null,
};

export type CreateProfileJobInput = {
  id?: string | null,
  profileId: string,
  jobId: string,
  coverLetter?: Array< string | null > | null,
  saved?: boolean | null,
  savedDate?: string | null,
  applied?: boolean | null,
  appliedDate?: string | null,
  createdAt?: string | null,
  rejected?: boolean | null,
};

export type UpdateProfileJobInput = {
  id: string,
  profileId?: string | null,
  jobId?: string | null,
  coverLetter?: Array< string | null > | null,
  saved?: boolean | null,
  savedDate?: string | null,
  applied?: boolean | null,
  appliedDate?: string | null,
  createdAt?: string | null,
  rejected?: boolean | null,
};

export type CreateCommentInput = {
  id?: string | null,
  postId?: string | null,
  commentId?: string | null,
  content?: Array< string | null > | null,
  creatorId: string,
  createdAt?: string | null,
  archived?: boolean | null,
  attachments?: Array< AttachmentInput | null > | null,
};

export type ModelCommentConditionInput = {
  postId?: ModelIDInput | null,
  commentId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
};

export type UpdateCommentInput = {
  id: string,
  postId?: string | null,
  commentId?: string | null,
  content?: Array< string | null > | null,
  creatorId?: string | null,
  createdAt?: string | null,
  archived?: boolean | null,
  attachments?: Array< AttachmentInput | null > | null,
};

export type DeleteCommentInput = {
  id: string,
};

export type CreateReportInfoInput = {
  id?: string | null,
  postId?: string | null,
  userId?: string | null,
  commentId?: string | null,
  jobId?: string | null,
  creatorId: string,
  createdAt?: string | null,
  message: string,
};

export type ModelReportInfoConditionInput = {
  postId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  commentId?: ModelIDInput | null,
  jobId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  message?: ModelStringInput | null,
  and?: Array< ModelReportInfoConditionInput | null > | null,
  or?: Array< ModelReportInfoConditionInput | null > | null,
  not?: ModelReportInfoConditionInput | null,
};

export type UpdateReportInfoInput = {
  id: string,
  postId?: string | null,
  userId?: string | null,
  commentId?: string | null,
  jobId?: string | null,
  creatorId?: string | null,
  createdAt?: string | null,
  message?: string | null,
};

export type DeleteReportInfoInput = {
  id: string,
};

export type CreatePostLikeInput = {
  id?: string | null,
  postId: string,
  profileId: string,
};

export type ModelPostLikeConditionInput = {
  postId?: ModelIDInput | null,
  and?: Array< ModelPostLikeConditionInput | null > | null,
  or?: Array< ModelPostLikeConditionInput | null > | null,
  not?: ModelPostLikeConditionInput | null,
};

export type UpdatePostLikeInput = {
  id: string,
  postId?: string | null,
  profileId?: string | null,
};

export type DeletePostLikeInput = {
  id: string,
};

export type CreateCommentLikeInput = {
  id?: string | null,
  commentId: string,
  profileId: string,
};

export type ModelCommentLikeConditionInput = {
  commentId?: ModelIDInput | null,
  and?: Array< ModelCommentLikeConditionInput | null > | null,
  or?: Array< ModelCommentLikeConditionInput | null > | null,
  not?: ModelCommentLikeConditionInput | null,
};

export type UpdateCommentLikeInput = {
  id: string,
  commentId?: string | null,
  profileId?: string | null,
};

export type DeleteCommentLikeInput = {
  id: string,
};

export type CreateUserNotificationInput = {
  id?: string | null,
  receiverId: string,
  senderId?: string | null,
  type: NotificationType,
  targetId?: string | null,
  subTargetId?: string | null,
  additionalTargetId?: string | null,
  read: boolean,
  title?: string | null,
  message?: string | null,
  createdAt?: string | null,
};

export type UpdateUserNotificationInput = {
  id: string,
  receiverId?: string | null,
  senderId?: string | null,
  type?: NotificationType | null,
  targetId?: string | null,
  subTargetId?: string | null,
  additionalTargetId?: string | null,
  read?: boolean | null,
  title?: string | null,
  message?: string | null,
  createdAt?: string | null,
};

export type CreatePublicEmailInput = {
  id?: string | null,
  email: string,
  allowMarketing: boolean,
};

export type CreateTipSplitInput = {
  id?: string | null,
  amount: number,
  owner: string,
  status: TransactionStatus,
  from: string,
  to: string,
  createdAt?: string | null,
};

export type UpdateTipSplitInput = {
  id: string,
  amount?: number | null,
  owner?: string | null,
  status?: TransactionStatus | null,
  from?: string | null,
  to?: string | null,
  createdAt?: string | null,
};

export type CreateEmployeeTransferInput = {
  id?: string | null,
  splitId: string,
  employeeId: string,
  amount: number,
  status: TransactionStatus,
  createdAt?: string | null,
};

export type ModelEmployeeTransferConditionInput = {
  splitId?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  status?: ModelTransactionStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelEmployeeTransferConditionInput | null > | null,
  or?: Array< ModelEmployeeTransferConditionInput | null > | null,
  not?: ModelEmployeeTransferConditionInput | null,
};

export type UpdateEmployeeTransferInput = {
  id: string,
  splitId?: string | null,
  employeeId?: string | null,
  amount?: number | null,
  status?: TransactionStatus | null,
  createdAt?: string | null,
};

export type DeleteEmployeeTransferInput = {
  id: string,
};

export type CreateTipTransactionInput = {
  id?: string | null,
  splitId?: string | null,
  receiverId: string,
  amount: string,
  fee: string,
  currency: string,
  createdAt?: string | null,
  status: TransactionStatus,
  type: TippingType,
  source: TipTransactionSource,
  payoutInvoiceId?: string | null,
  transactionId?: string | null,
};

export type UpdateTipTransactionInput = {
  id: string,
  splitId?: string | null,
  receiverId?: string | null,
  amount?: string | null,
  fee?: string | null,
  currency?: string | null,
  createdAt?: string | null,
  status?: TransactionStatus | null,
  type?: TippingType | null,
  source?: TipTransactionSource | null,
  payoutInvoiceId?: string | null,
  transactionId?: string | null,
};

export type CreateBusinessSetupTippingInviteInput = {
  id?: string | null,
  sender: string,
  createdAt?: string | null,
};

export type ModelBusinessSetupTippingInviteConditionInput = {
  createdAt?: ModelStringInput | null,
  and?: Array< ModelBusinessSetupTippingInviteConditionInput | null > | null,
  or?: Array< ModelBusinessSetupTippingInviteConditionInput | null > | null,
  not?: ModelBusinessSetupTippingInviteConditionInput | null,
};

export type BusinessSetupTippingInvite = {
  __typename: "BusinessSetupTippingInvite",
  id: string,
  sender: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBusinessSetupTippingInviteInput = {
  id: string,
  sender?: string | null,
  createdAt?: string | null,
};

export type DeleteBusinessSetupTippingInviteInput = {
  id: string,
};

export type CreateUserMessageInput = {
  id?: string | null,
  senderId: string,
  receiverId: string,
  content: string,
  createdAt?: string | null,
};

export type CreateUserPhoneValidationInput = {
  id?: string | null,
  userId: string,
  phoneNumber: string,
  code: string,
  createdAt?: string | null,
};

export type ModelUserPhoneValidationConditionInput = {
  phoneNumber?: ModelStringInput | null,
  code?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserPhoneValidationConditionInput | null > | null,
  or?: Array< ModelUserPhoneValidationConditionInput | null > | null,
  not?: ModelUserPhoneValidationConditionInput | null,
};

export type UserPhoneValidation = {
  __typename: "UserPhoneValidation",
  id: string,
  userId: string,
  phoneNumber: string,
  code: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserPhoneValidationInput = {
  id: string,
  userId?: string | null,
  phoneNumber?: string | null,
  code?: string | null,
  createdAt?: string | null,
};

export type DeleteUserPhoneValidationInput = {
  id: string,
};

export type CreatePayoutInvoiceInput = {
  id?: string | null,
  createdAt?: string | null,
  file: string,
  amount: number,
  fee: number,
  currency: string,
  status: PayoutStatus,
  payedAt?: string | null,
};

export enum PayoutStatus {
  sent = "sent",
  paid = "paid",
}


export type ModelPayoutInvoiceConditionInput = {
  createdAt?: ModelStringInput | null,
  file?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  fee?: ModelIntInput | null,
  currency?: ModelStringInput | null,
  status?: ModelPayoutStatusInput | null,
  payedAt?: ModelStringInput | null,
  and?: Array< ModelPayoutInvoiceConditionInput | null > | null,
  or?: Array< ModelPayoutInvoiceConditionInput | null > | null,
  not?: ModelPayoutInvoiceConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelPayoutStatusInput = {
  eq?: PayoutStatus | null,
  ne?: PayoutStatus | null,
};

export type PayoutInvoice = {
  __typename: "PayoutInvoice",
  id: string,
  createdAt: string,
  file: string,
  amount: number,
  fee: number,
  currency: string,
  status: PayoutStatus,
  payedAt?: string | null,
  updatedAt: string,
  tipTransactions?: ModelTipTransactionConnection | null,
};

export type UpdatePayoutInvoiceInput = {
  id: string,
  createdAt?: string | null,
  file?: string | null,
  amount?: number | null,
  fee?: number | null,
  currency?: string | null,
  status?: PayoutStatus | null,
  payedAt?: string | null,
};

export type DeletePayoutInvoiceInput = {
  id: string,
};

export type ModelProfileEnterpriseFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  companyNumber?: ModelStringInput | null,
  businesses?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelProfileEnterpriseFilterInput | null > | null,
  or?: Array< ModelProfileEnterpriseFilterInput | null > | null,
  not?: ModelProfileEnterpriseFilterInput | null,
};

export type ModelProfileEnterpriseConnection = {
  __typename: "ModelProfileEnterpriseConnection",
  items:  Array<ProfileEnterprise | null >,
  nextToken?: string | null,
};

export type ModelBusinessStatisticsFilterInput = {
  id?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  lastSplitDate?: ModelStringInput | null,
  and?: Array< ModelBusinessStatisticsFilterInput | null > | null,
  or?: Array< ModelBusinessStatisticsFilterInput | null > | null,
  not?: ModelBusinessStatisticsFilterInput | null,
};

export type ModelBusinessStatisticsConnection = {
  __typename: "ModelBusinessStatisticsConnection",
  items:  Array<BusinessStatistics | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelBusinessFilterInput = {
  id?: ModelIDInput | null,
  verified?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  isOutlet?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelBusinessFilterInput | null > | null,
  or?: Array< ModelBusinessFilterInput | null > | null,
  not?: ModelBusinessFilterInput | null,
};

export type ModelBusinessConnection = {
  __typename: "ModelBusinessConnection",
  items:  Array<Business | null >,
  nextToken?: string | null,
};

export type SearchableBusinessFilterInput = {
  id?: SearchableIDFilterInput | null,
  verified?: SearchableBooleanFilterInput | null,
  name?: SearchableStringFilterInput | null,
  code?: SearchableStringFilterInput | null,
  isOutlet?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableBusinessFilterInput | null > | null,
  or?: Array< SearchableBusinessFilterInput | null > | null,
  not?: SearchableBusinessFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableBusinessSortInput = {
  field?: SearchableBusinessSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableBusinessSortableFields {
  id = "id",
  verified = "verified",
  name = "name",
  code = "code",
  isOutlet = "isOutlet",
  createdAt = "createdAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableBusinessConnection = {
  __typename: "SearchableBusinessConnection",
  items:  Array<Business | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelBusinessDetailsFilterInput = {
  id?: ModelIDInput | null,
  contactName?: ModelStringInput | null,
  jobRole?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  website?: ModelStringInput | null,
  and?: Array< ModelBusinessDetailsFilterInput | null > | null,
  or?: Array< ModelBusinessDetailsFilterInput | null > | null,
  not?: ModelBusinessDetailsFilterInput | null,
};

export type ModelBusinessDetailsConnection = {
  __typename: "ModelBusinessDetailsConnection",
  items:  Array<BusinessDetails | null >,
  nextToken?: string | null,
};

export type ModelProfileFilterInput = {
  id?: ModelIDInput | null,
  slug?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  and?: Array< ModelProfileFilterInput | null > | null,
  or?: Array< ModelProfileFilterInput | null > | null,
  not?: ModelProfileFilterInput | null,
};

export type ModelProfileConnection = {
  __typename: "ModelProfileConnection",
  items:  Array<Profile | null >,
  nextToken?: string | null,
};

export type SearchableProfileFilterInput = {
  id?: SearchableIDFilterInput | null,
  slug?: SearchableStringFilterInput | null,
  firstName?: SearchableStringFilterInput | null,
  lastName?: SearchableStringFilterInput | null,
  bio?: SearchableStringFilterInput | null,
  picture?: SearchableStringFilterInput | null,
  archived?: SearchableBooleanFilterInput | null,
  and?: Array< SearchableProfileFilterInput | null > | null,
  or?: Array< SearchableProfileFilterInput | null > | null,
  not?: SearchableProfileFilterInput | null,
};

export type SearchableProfileSortInput = {
  field?: SearchableProfileSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProfileSortableFields {
  id = "id",
  slug = "slug",
  firstName = "firstName",
  lastName = "lastName",
  bio = "bio",
  picture = "picture",
  archived = "archived",
}


export type SearchableProfileConnection = {
  __typename: "SearchableProfileConnection",
  items:  Array<Profile | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelProfileDetailsFilterInput = {
  id?: ModelIDInput | null,
  accountType?: ModelStringInput | null,
  isOnboardingComplete?: ModelBooleanInput | null,
  email?: ModelStringInput | null,
  feedRegion?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  phoneNumberConfirmed?: ModelBooleanInput | null,
  pushNotificationToken?: ModelStringInput | null,
  pushNotificationReadDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProfileDetailsFilterInput | null > | null,
  or?: Array< ModelProfileDetailsFilterInput | null > | null,
  not?: ModelProfileDetailsFilterInput | null,
};

export type ModelProfileDetailsConnection = {
  __typename: "ModelProfileDetailsConnection",
  items:  Array<ProfileDetails | null >,
  nextToken?: string | null,
};

export type SearchableProfileDetailsFilterInput = {
  id?: SearchableIDFilterInput | null,
  accountType?: SearchableStringFilterInput | null,
  isOnboardingComplete?: SearchableBooleanFilterInput | null,
  email?: SearchableStringFilterInput | null,
  feedRegion?: SearchableStringFilterInput | null,
  phone_number?: SearchableStringFilterInput | null,
  phoneNumberConfirmed?: SearchableBooleanFilterInput | null,
  pushNotificationToken?: SearchableStringFilterInput | null,
  pushNotificationReadDate?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableProfileDetailsFilterInput | null > | null,
  or?: Array< SearchableProfileDetailsFilterInput | null > | null,
  not?: SearchableProfileDetailsFilterInput | null,
};

export type SearchableProfileDetailsSortInput = {
  field?: SearchableProfileDetailsSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProfileDetailsSortableFields {
  id = "id",
  accountType = "accountType",
  isOnboardingComplete = "isOnboardingComplete",
  email = "email",
  feedRegion = "feedRegion",
  phone_number = "phone_number",
  phoneNumberConfirmed = "phoneNumberConfirmed",
  pushNotificationToken = "pushNotificationToken",
  pushNotificationReadDate = "pushNotificationReadDate",
  createdAt = "createdAt",
}


export type SearchableProfileDetailsConnection = {
  __typename: "SearchableProfileDetailsConnection",
  items:  Array<ProfileDetails | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelBusinessEmployeeFilterInput = {
  id?: ModelIDInput | null,
  jobRole?: ModelStringInput | null,
  businessId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  confirmed?: ModelBooleanInput | null,
  employeeId?: ModelStringInput | null,
  tippingPolicyAcknowledgedDate?: ModelStringInput | null,
  and?: Array< ModelBusinessEmployeeFilterInput | null > | null,
  or?: Array< ModelBusinessEmployeeFilterInput | null > | null,
  not?: ModelBusinessEmployeeFilterInput | null,
};

export type ModelBusinessBrandsFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  brandId?: ModelIDInput | null,
  status?: ModelBrandStatusInput | null,
  and?: Array< ModelBusinessBrandsFilterInput | null > | null,
  or?: Array< ModelBusinessBrandsFilterInput | null > | null,
  not?: ModelBusinessBrandsFilterInput | null,
};

export type ModelBrandFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  productType?: ModelStringInput | null,
  and?: Array< ModelBrandFilterInput | null > | null,
  or?: Array< ModelBrandFilterInput | null > | null,
  not?: ModelBrandFilterInput | null,
};

export type ModelBrandConnection = {
  __typename: "ModelBrandConnection",
  items:  Array<Brand | null >,
  nextToken?: string | null,
};

export type SearchableBrandFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  logo?: SearchableStringFilterInput | null,
  active?: SearchableBooleanFilterInput | null,
  productType?: SearchableStringFilterInput | null,
  and?: Array< SearchableBrandFilterInput | null > | null,
  or?: Array< SearchableBrandFilterInput | null > | null,
  not?: SearchableBrandFilterInput | null,
};

export type SearchableBrandSortInput = {
  field?: SearchableBrandSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableBrandSortableFields {
  id = "id",
  name = "name",
  logo = "logo",
  active = "active",
  productType = "productType",
}


export type SearchableBrandConnection = {
  __typename: "SearchableBrandConnection",
  items:  Array<Brand | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelExperienceFilterInput = {
  id?: ModelIDInput | null,
  jobTitle?: ModelStringInput | null,
  businessId?: ModelIDInput | null,
  companyName?: ModelStringInput | null,
  profileId?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  employmentType?: ModelStringInput | null,
  location?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelExperienceFilterInput | null > | null,
  or?: Array< ModelExperienceFilterInput | null > | null,
  not?: ModelExperienceFilterInput | null,
};

export type ModelPostFilterInput = {
  id?: ModelIDInput | null,
  hubId?: ModelStringInput | null,
  creatorId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  feedRegion?: ModelStringInput | null,
  regions?: ModelStringInput | null,
  and?: Array< ModelPostFilterInput | null > | null,
  or?: Array< ModelPostFilterInput | null > | null,
  not?: ModelPostFilterInput | null,
};

export type ModelPostConnection = {
  __typename: "ModelPostConnection",
  items:  Array<Post | null >,
  nextToken?: string | null,
};

export type SearchablePostFilterInput = {
  id?: SearchableIDFilterInput | null,
  hubId?: SearchableStringFilterInput | null,
  creatorId?: SearchableIDFilterInput | null,
  content?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  archived?: SearchableBooleanFilterInput | null,
  feedRegion?: SearchableStringFilterInput | null,
  regions?: SearchableStringFilterInput | null,
  and?: Array< SearchablePostFilterInput | null > | null,
  or?: Array< SearchablePostFilterInput | null > | null,
  not?: SearchablePostFilterInput | null,
};

export type SearchablePostSortInput = {
  field?: SearchablePostSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePostSortableFields {
  id = "id",
  hubId = "hubId",
  creatorId = "creatorId",
  content = "content",
  createdAt = "createdAt",
  archived = "archived",
  feedRegion = "feedRegion",
  regions = "regions",
}


export type SearchablePostConnection = {
  __typename: "SearchablePostConnection",
  items:  Array<Post | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelJobFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  jobType?: ModelStringInput | null,
  businessId?: ModelIDInput | null,
  jobRole?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  region?: ModelStringInput | null,
  requireCV?: ModelBooleanInput | null,
  requireCoverLetter?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  and?: Array< ModelJobFilterInput | null > | null,
  or?: Array< ModelJobFilterInput | null > | null,
  not?: ModelJobFilterInput | null,
};

export type ModelJobConnection = {
  __typename: "ModelJobConnection",
  items:  Array<Job | null >,
  nextToken?: string | null,
};

export type SearchableJobFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  jobType?: SearchableStringFilterInput | null,
  businessId?: SearchableIDFilterInput | null,
  jobRole?: SearchableStringFilterInput | null,
  title?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  region?: SearchableStringFilterInput | null,
  requireCV?: SearchableBooleanFilterInput | null,
  requireCoverLetter?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  archived?: SearchableBooleanFilterInput | null,
  startDate?: SearchableStringFilterInput | null,
  endDate?: SearchableStringFilterInput | null,
  and?: Array< SearchableJobFilterInput | null > | null,
  or?: Array< SearchableJobFilterInput | null > | null,
  not?: SearchableJobFilterInput | null,
};

export type SearchableJobSortInput = {
  field?: SearchableJobSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableJobSortableFields {
  id = "id",
  owner = "owner",
  jobType = "jobType",
  businessId = "businessId",
  jobRole = "jobRole",
  title = "title",
  description = "description",
  region = "region",
  requireCV = "requireCV",
  requireCoverLetter = "requireCoverLetter",
  createdAt = "createdAt",
  archived = "archived",
  startDate = "startDate",
  endDate = "endDate",
}


export type SearchableJobConnection = {
  __typename: "SearchableJobConnection",
  items:  Array<Job | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelProfileJobFilterInput = {
  id?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  jobId?: ModelIDInput | null,
  coverLetter?: ModelStringInput | null,
  saved?: ModelBooleanInput | null,
  savedDate?: ModelStringInput | null,
  applied?: ModelBooleanInput | null,
  appliedDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  rejected?: ModelBooleanInput | null,
  and?: Array< ModelProfileJobFilterInput | null > | null,
  or?: Array< ModelProfileJobFilterInput | null > | null,
  not?: ModelProfileJobFilterInput | null,
};

export type SearchableProfileJobFilterInput = {
  id?: SearchableIDFilterInput | null,
  profileId?: SearchableIDFilterInput | null,
  jobId?: SearchableIDFilterInput | null,
  coverLetter?: SearchableStringFilterInput | null,
  saved?: SearchableBooleanFilterInput | null,
  savedDate?: SearchableStringFilterInput | null,
  applied?: SearchableBooleanFilterInput | null,
  appliedDate?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  rejected?: SearchableBooleanFilterInput | null,
  and?: Array< SearchableProfileJobFilterInput | null > | null,
  or?: Array< SearchableProfileJobFilterInput | null > | null,
  not?: SearchableProfileJobFilterInput | null,
};

export type SearchableProfileJobSortInput = {
  field?: SearchableProfileJobSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProfileJobSortableFields {
  id = "id",
  profileId = "profileId",
  jobId = "jobId",
  coverLetter = "coverLetter",
  saved = "saved",
  savedDate = "savedDate",
  applied = "applied",
  appliedDate = "appliedDate",
  createdAt = "createdAt",
  rejected = "rejected",
}


export type SearchableProfileJobConnection = {
  __typename: "SearchableProfileJobConnection",
  items:  Array<ProfileJob | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  commentId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  creatorId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelReportInfoFilterInput = {
  id?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  commentId?: ModelIDInput | null,
  jobId?: ModelIDInput | null,
  creatorId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  message?: ModelStringInput | null,
  and?: Array< ModelReportInfoFilterInput | null > | null,
  or?: Array< ModelReportInfoFilterInput | null > | null,
  not?: ModelReportInfoFilterInput | null,
};

export type ModelPostLikeFilterInput = {
  id?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  and?: Array< ModelPostLikeFilterInput | null > | null,
  or?: Array< ModelPostLikeFilterInput | null > | null,
  not?: ModelPostLikeFilterInput | null,
};

export type ModelCommentLikeFilterInput = {
  id?: ModelIDInput | null,
  commentId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  and?: Array< ModelCommentLikeFilterInput | null > | null,
  or?: Array< ModelCommentLikeFilterInput | null > | null,
  not?: ModelCommentLikeFilterInput | null,
};

export type ModelUserNotificationFilterInput = {
  id?: ModelIDInput | null,
  receiverId?: ModelIDInput | null,
  senderId?: ModelIDInput | null,
  type?: ModelNotificationTypeInput | null,
  targetId?: ModelIDInput | null,
  subTargetId?: ModelIDInput | null,
  additionalTargetId?: ModelIDInput | null,
  read?: ModelBooleanInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserNotificationFilterInput | null > | null,
  or?: Array< ModelUserNotificationFilterInput | null > | null,
  not?: ModelUserNotificationFilterInput | null,
};

export type ModelUserNotificationConnection = {
  __typename: "ModelUserNotificationConnection",
  items:  Array<UserNotification | null >,
  nextToken?: string | null,
};

export type ModelPublicEmailFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  allowMarketing?: ModelBooleanInput | null,
  and?: Array< ModelPublicEmailFilterInput | null > | null,
  or?: Array< ModelPublicEmailFilterInput | null > | null,
  not?: ModelPublicEmailFilterInput | null,
};

export type ModelPublicEmailConnection = {
  __typename: "ModelPublicEmailConnection",
  items:  Array<PublicEmail | null >,
  nextToken?: string | null,
};

export type ModelTipSplitFilterInput = {
  id?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  owner?: ModelIDInput | null,
  status?: ModelTransactionStatusInput | null,
  from?: ModelStringInput | null,
  to?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTipSplitFilterInput | null > | null,
  or?: Array< ModelTipSplitFilterInput | null > | null,
  not?: ModelTipSplitFilterInput | null,
};

export type ModelTipSplitConnection = {
  __typename: "ModelTipSplitConnection",
  items:  Array<TipSplit | null >,
  nextToken?: string | null,
};

export type SearchableTipSplitFilterInput = {
  id?: SearchableIDFilterInput | null,
  amount?: SearchableFloatFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  from?: SearchableStringFilterInput | null,
  to?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableTipSplitFilterInput | null > | null,
  or?: Array< SearchableTipSplitFilterInput | null > | null,
  not?: SearchableTipSplitFilterInput | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableTipSplitSortInput = {
  field?: SearchableTipSplitSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableTipSplitSortableFields {
  id = "id",
  amount = "amount",
  owner = "owner",
  from = "from",
  to = "to",
  createdAt = "createdAt",
}


export type SearchableTipSplitConnection = {
  __typename: "SearchableTipSplitConnection",
  items:  Array<TipSplit | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelEmployeeTransferFilterInput = {
  id?: ModelIDInput | null,
  splitId?: ModelIDInput | null,
  employeeId?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  status?: ModelTransactionStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelEmployeeTransferFilterInput | null > | null,
  or?: Array< ModelEmployeeTransferFilterInput | null > | null,
  not?: ModelEmployeeTransferFilterInput | null,
};

export type ModelTipTransactionFilterInput = {
  id?: ModelIDInput | null,
  splitId?: ModelIDInput | null,
  receiverId?: ModelIDInput | null,
  amount?: ModelStringInput | null,
  fee?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  status?: ModelTransactionStatusInput | null,
  type?: ModelTippingTypeInput | null,
  source?: ModelTipTransactionSourceInput | null,
  payoutInvoiceId?: ModelIDInput | null,
  transactionId?: ModelIDInput | null,
  and?: Array< ModelTipTransactionFilterInput | null > | null,
  or?: Array< ModelTipTransactionFilterInput | null > | null,
  not?: ModelTipTransactionFilterInput | null,
};

export type SearchableTipTransactionFilterInput = {
  id?: SearchableIDFilterInput | null,
  splitId?: SearchableIDFilterInput | null,
  receiverId?: SearchableIDFilterInput | null,
  amount?: SearchableStringFilterInput | null,
  fee?: SearchableStringFilterInput | null,
  currency?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  payoutInvoiceId?: SearchableIDFilterInput | null,
  transactionId?: SearchableIDFilterInput | null,
  and?: Array< SearchableTipTransactionFilterInput | null > | null,
  or?: Array< SearchableTipTransactionFilterInput | null > | null,
  not?: SearchableTipTransactionFilterInput | null,
};

export type SearchableTipTransactionSortInput = {
  field?: SearchableTipTransactionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableTipTransactionSortableFields {
  id = "id",
  splitId = "splitId",
  receiverId = "receiverId",
  amount = "amount",
  fee = "fee",
  currency = "currency",
  createdAt = "createdAt",
  payoutInvoiceId = "payoutInvoiceId",
  transactionId = "transactionId",
}


export type SearchableTipTransactionConnection = {
  __typename: "SearchableTipTransactionConnection",
  items:  Array<TipTransaction | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelBusinessSetupTippingInviteFilterInput = {
  id?: ModelIDInput | null,
  sender?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelBusinessSetupTippingInviteFilterInput | null > | null,
  or?: Array< ModelBusinessSetupTippingInviteFilterInput | null > | null,
  not?: ModelBusinessSetupTippingInviteFilterInput | null,
};

export type ModelBusinessSetupTippingInviteConnection = {
  __typename: "ModelBusinessSetupTippingInviteConnection",
  items:  Array<BusinessSetupTippingInvite | null >,
  nextToken?: string | null,
};

export type ModelUserMessageFilterInput = {
  id?: ModelIDInput | null,
  senderId?: ModelIDInput | null,
  receiverId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserMessageFilterInput | null > | null,
  or?: Array< ModelUserMessageFilterInput | null > | null,
  not?: ModelUserMessageFilterInput | null,
};

export type ModelUserMessageConnection = {
  __typename: "ModelUserMessageConnection",
  items:  Array<UserMessage | null >,
  nextToken?: string | null,
};

export type ModelUserPhoneValidationFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  phoneNumber?: ModelStringInput | null,
  code?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserPhoneValidationFilterInput | null > | null,
  or?: Array< ModelUserPhoneValidationFilterInput | null > | null,
  not?: ModelUserPhoneValidationFilterInput | null,
};

export type ModelUserPhoneValidationConnection = {
  __typename: "ModelUserPhoneValidationConnection",
  items:  Array<UserPhoneValidation | null >,
  nextToken?: string | null,
};

export type ModelPayoutInvoiceFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  file?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  fee?: ModelIntInput | null,
  currency?: ModelStringInput | null,
  status?: ModelPayoutStatusInput | null,
  payedAt?: ModelStringInput | null,
  and?: Array< ModelPayoutInvoiceFilterInput | null > | null,
  or?: Array< ModelPayoutInvoiceFilterInput | null > | null,
  not?: ModelPayoutInvoiceFilterInput | null,
};

export type ModelPayoutInvoiceConnection = {
  __typename: "ModelPayoutInvoiceConnection",
  items:  Array<PayoutInvoice | null >,
  nextToken?: string | null,
};

export type DeleteBusinessStatisticsMutationVariables = {
  input: DeleteBusinessStatisticsInput,
  condition?: ModelBusinessStatisticsConditionInput | null,
};

export type DeleteBusinessStatisticsMutation = {
  deleteBusinessStatistics?:  {
    __typename: "BusinessStatistics",
    id: string,
    data?:  Array< {
      __typename: "BusinessStatisticsInfo",
      date: string,
      totalTips: number,
      totalTipsCount: number,
    } | null > | null,
    updatedAt: string,
    owner: string,
    lastSplitDate?: string | null,
    createdAt: string,
  } | null,
};

export type DeleteProfileMutationVariables = {
  input: DeleteProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type DeleteProfileMutation = {
  deleteProfile?:  {
    __typename: "Profile",
    id: string,
    slug?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    bio?: Array< string | null > | null,
    picture?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    archived?: boolean | null,
    details?:  {
      __typename: "ProfileDetails",
      id: string,
      accountType?: string | null,
      isOnboardingComplete?: boolean | null,
      email?: string | null,
      feedRegion?: string | null,
      tippingAccount?:  {
        __typename: "TippingAccount",
        code?: string | null,
        merchant?: Merchant | null,
        accountId?: string | null,
        verified?: boolean | null,
        missingRequirements?:  Array< {
          __typename: "MissingRequirement",
          key?: string | null,
          description?: string | null,
        } | null > | null,
        pendingValidation?: Array< string | null > | null,
        bankDetails?:  {
          __typename: "BankDetails",
          name?: string | null,
          sortCode?: string | null,
          last4Digits?: string | null,
        } | null,
      } | null,
      phone_number?: string | null,
      phoneNumberConfirmed?: boolean | null,
      pushNotificationToken?: string | null,
      pushNotificationReadDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employers?:  {
      __typename: "ModelBusinessEmployeeConnection",
      items:  Array< {
        __typename: "BusinessEmployee",
        id: string,
        jobRole: string,
        businessId: string,
        profileId: string,
        employeeId?: string | null,
        tippingPolicyAcknowledgedDate?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        confirmed?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        jobTitle?: string | null,
        businessId?: string | null,
        companyName: string,
        profileId: string,
        startDate?: string | null,
        endDate?: string | null,
        employmentType?: string | null,
        location?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        profile?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelProfileJobConnection",
      items:  Array< {
        __typename: "ProfileJob",
        id: string,
        profileId: string,
        jobId: string,
        coverLetter?: Array< string | null > | null,
        saved?: boolean | null,
        savedDate?: string | null,
        applied?: boolean | null,
        appliedDate?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        job:  {
          __typename: "Job",
          id: string,
          owner?: string | null,
          jobType: string,
          businessId: string,
          jobRole: string,
          title: string,
          description?: Array< string | null > | null,
          salary?:  {
            __typename: "Salary",
            type: string,
            from?: string | null,
            to?: string | null,
          } | null,
          region: string,
          requireCV?: boolean | null,
          requireCoverLetter?: boolean | null,
          createdAt?: string | null,
          archived?: boolean | null,
          startDate?: string | null,
          endDate?: string | null,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        rejected?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteProfileDetailsMutationVariables = {
  input: DeleteProfileDetailsInput,
  condition?: ModelProfileDetailsConditionInput | null,
};

export type DeleteProfileDetailsMutation = {
  deleteProfileDetails?:  {
    __typename: "ProfileDetails",
    id: string,
    accountType?: string | null,
    isOnboardingComplete?: boolean | null,
    email?: string | null,
    feedRegion?: string | null,
    tippingAccount?:  {
      __typename: "TippingAccount",
      code?: string | null,
      merchant?: Merchant | null,
      accountId?: string | null,
      verified?: boolean | null,
      missingRequirements?:  Array< {
        __typename: "MissingRequirement",
        key?: string | null,
        description?: string | null,
      } | null > | null,
      pendingValidation?: Array< string | null > | null,
      bankDetails?:  {
        __typename: "BankDetails",
        name?: string | null,
        sortCode?: string | null,
        last4Digits?: string | null,
      } | null,
    } | null,
    phone_number?: string | null,
    phoneNumberConfirmed?: boolean | null,
    pushNotificationToken?: string | null,
    pushNotificationReadDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBusinessInviteMutationVariables = {
  input: UpdateBusinessInviteInput,
  condition?: ModelBusinessInviteConditionInput | null,
};

export type UpdateBusinessInviteMutation = {
  updateBusinessInvite?:  {
    __typename: "BusinessInvite",
    id: string,
    businessId: string,
    receiver?: string | null,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteBusinessInviteMutationVariables = {
  input: DeleteBusinessInviteInput,
  condition?: ModelBusinessInviteConditionInput | null,
};

export type DeleteBusinessInviteMutation = {
  deleteBusinessInvite?:  {
    __typename: "BusinessInvite",
    id: string,
    businessId: string,
    receiver?: string | null,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateBusinessBrandsMutationVariables = {
  input: UpdateBusinessBrandsInput,
  condition?: ModelBusinessBrandsConditionInput | null,
};

export type UpdateBusinessBrandsMutation = {
  updateBusinessBrands?:  {
    __typename: "BusinessBrands",
    id: string,
    businessId: string,
    brandId: string,
    status: BrandStatus,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profileId?: string | null,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      logo?: string | null,
      active?: boolean | null,
      productType?: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type DeleteBusinessBrandsMutationVariables = {
  input: DeleteBusinessBrandsInput,
  condition?: ModelBusinessBrandsConditionInput | null,
};

export type DeleteBusinessBrandsMutation = {
  deleteBusinessBrands?:  {
    __typename: "BusinessBrands",
    id: string,
    businessId: string,
    brandId: string,
    status: BrandStatus,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profileId?: string | null,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      logo?: string | null,
      active?: boolean | null,
      productType?: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type DeleteBrandMutationVariables = {
  input: DeleteBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type DeleteBrandMutation = {
  deleteBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    logo?: string | null,
    active?: boolean | null,
    productType?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteExperienceMutationVariables = {
  input: DeleteExperienceInput,
  condition?: ModelExperienceConditionInput | null,
};

export type DeleteExperienceMutation = {
  deleteExperience?:  {
    __typename: "Experience",
    id: string,
    jobTitle?: string | null,
    businessId?: string | null,
    companyName: string,
    profileId: string,
    startDate?: string | null,
    endDate?: string | null,
    employmentType?: string | null,
    location?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteJobMutationVariables = {
  input: DeleteJobInput,
  condition?: ModelJobConditionInput | null,
};

export type DeleteJobMutation = {
  deleteJob?:  {
    __typename: "Job",
    id: string,
    owner?: string | null,
    jobType: string,
    businessId: string,
    jobRole: string,
    title: string,
    description?: Array< string | null > | null,
    salary?:  {
      __typename: "Salary",
      type: string,
      from?: string | null,
      to?: string | null,
    } | null,
    region: string,
    requireCV?: boolean | null,
    requireCoverLetter?: boolean | null,
    createdAt?: string | null,
    archived?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteProfileJobMutationVariables = {
  input: DeleteProfileJobInput,
  condition?: ModelProfileJobConditionInput | null,
};

export type DeleteProfileJobMutation = {
  deleteProfileJob?:  {
    __typename: "ProfileJob",
    id: string,
    profileId: string,
    jobId: string,
    coverLetter?: Array< string | null > | null,
    saved?: boolean | null,
    savedDate?: string | null,
    applied?: boolean | null,
    appliedDate?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    job:  {
      __typename: "Job",
      id: string,
      owner?: string | null,
      jobType: string,
      businessId: string,
      jobRole: string,
      title: string,
      description?: Array< string | null > | null,
      salary?:  {
        __typename: "Salary",
        type: string,
        from?: string | null,
        to?: string | null,
      } | null,
      region: string,
      requireCV?: boolean | null,
      requireCoverLetter?: boolean | null,
      createdAt?: string | null,
      archived?: boolean | null,
      startDate?: string | null,
      endDate?: string | null,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    rejected?: boolean | null,
  } | null,
};

export type DeleteUserNotificationMutationVariables = {
  input: DeleteUserNotificationInput,
  condition?: ModelUserNotificationConditionInput | null,
};

export type DeleteUserNotificationMutation = {
  deleteUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    receiverId: string,
    senderId?: string | null,
    type: NotificationType,
    targetId?: string | null,
    subTargetId?: string | null,
    additionalTargetId?: string | null,
    read: boolean,
    title?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdatePublicEmailMutationVariables = {
  input: UpdatePublicEmailInput,
  condition?: ModelPublicEmailConditionInput | null,
};

export type UpdatePublicEmailMutation = {
  updatePublicEmail?:  {
    __typename: "PublicEmail",
    id: string,
    email: string,
    allowMarketing: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePublicEmailMutationVariables = {
  input: DeletePublicEmailInput,
  condition?: ModelPublicEmailConditionInput | null,
};

export type DeletePublicEmailMutation = {
  deletePublicEmail?:  {
    __typename: "PublicEmail",
    id: string,
    email: string,
    allowMarketing: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTipSplitMutationVariables = {
  input: DeleteTipSplitInput,
  condition?: ModelTipSplitConditionInput | null,
};

export type DeleteTipSplitMutation = {
  deleteTipSplit?:  {
    __typename: "TipSplit",
    id: string,
    amount: number,
    owner: string,
    status: TransactionStatus,
    from: string,
    to: string,
    createdAt: string,
    updatedAt: string,
    ownerProfile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    transfers?:  {
      __typename: "ModelEmployeeTransferConnection",
      items:  Array< {
        __typename: "EmployeeTransfer",
        id: string,
        splitId: string,
        employeeId: string,
        amount: number,
        status: TransactionStatus,
        createdAt: string,
        updatedAt: string,
        employee?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteTipTransactionMutationVariables = {
  input: DeleteTipTransactionInput,
  condition?: ModelTipTransactionConditionInput | null,
};

export type DeleteTipTransactionMutation = {
  deleteTipTransaction?:  {
    __typename: "TipTransaction",
    id: string,
    splitId?: string | null,
    receiverId: string,
    amount: string,
    fee: string,
    currency: string,
    createdAt: string,
    status: TransactionStatus,
    type: TippingType,
    source: TipTransactionSource,
    payoutInvoiceId?: string | null,
    transactionId?: string | null,
    updatedAt: string,
    receiver?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateUserMessageMutationVariables = {
  input: UpdateUserMessageInput,
  condition?: ModelUserMessageConditionInput | null,
};

export type UpdateUserMessageMutation = {
  updateUserMessage?:  {
    __typename: "UserMessage",
    id: string,
    senderId: string,
    receiverId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteUserMessageMutationVariables = {
  input: DeleteUserMessageInput,
  condition?: ModelUserMessageConditionInput | null,
};

export type DeleteUserMessageMutation = {
  deleteUserMessage?:  {
    __typename: "UserMessage",
    id: string,
    senderId: string,
    receiverId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateProfileEnterpriseMutationVariables = {
  input: CreateProfileEnterpriseInput,
  condition?: ModelProfileEnterpriseConditionInput | null,
};

export type CreateProfileEnterpriseMutation = {
  createProfileEnterprise?:  {
    __typename: "ProfileEnterprise",
    id: string,
    name: string,
    address:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    },
    companyNumber?: string | null,
    businesses?: Array< string | null > | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    picture?: string | null,
    contactDetails:  {
      __typename: "ProfileEnterpriseContactDetails",
      name: string,
      role: string,
      phone: string,
      email: string,
    },
  } | null,
};

export type UpdateProfileEnterpriseMutationVariables = {
  input: UpdateProfileEnterpriseInput,
  condition?: ModelProfileEnterpriseConditionInput | null,
};

export type UpdateProfileEnterpriseMutation = {
  updateProfileEnterprise?:  {
    __typename: "ProfileEnterprise",
    id: string,
    name: string,
    address:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    },
    companyNumber?: string | null,
    businesses?: Array< string | null > | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    picture?: string | null,
    contactDetails:  {
      __typename: "ProfileEnterpriseContactDetails",
      name: string,
      role: string,
      phone: string,
      email: string,
    },
  } | null,
};

export type DeleteProfileEnterpriseMutationVariables = {
  input: DeleteProfileEnterpriseInput,
  condition?: ModelProfileEnterpriseConditionInput | null,
};

export type DeleteProfileEnterpriseMutation = {
  deleteProfileEnterprise?:  {
    __typename: "ProfileEnterprise",
    id: string,
    name: string,
    address:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    },
    companyNumber?: string | null,
    businesses?: Array< string | null > | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    picture?: string | null,
    contactDetails:  {
      __typename: "ProfileEnterpriseContactDetails",
      name: string,
      role: string,
      phone: string,
      email: string,
    },
  } | null,
};

export type CreateBusinessStatisticsMutationVariables = {
  input: CreateBusinessStatisticsInput,
  condition?: ModelBusinessStatisticsConditionInput | null,
};

export type CreateBusinessStatisticsMutation = {
  createBusinessStatistics?:  {
    __typename: "BusinessStatistics",
    id: string,
    data?:  Array< {
      __typename: "BusinessStatisticsInfo",
      date: string,
      totalTips: number,
      totalTipsCount: number,
    } | null > | null,
    updatedAt: string,
    owner: string,
    lastSplitDate?: string | null,
    createdAt: string,
  } | null,
};

export type UpdateBusinessStatisticsMutationVariables = {
  input: UpdateBusinessStatisticsInput,
  condition?: ModelBusinessStatisticsConditionInput | null,
};

export type UpdateBusinessStatisticsMutation = {
  updateBusinessStatistics?:  {
    __typename: "BusinessStatistics",
    id: string,
    data?:  Array< {
      __typename: "BusinessStatisticsInfo",
      date: string,
      totalTips: number,
      totalTipsCount: number,
    } | null > | null,
    updatedAt: string,
    owner: string,
    lastSplitDate?: string | null,
    createdAt: string,
  } | null,
};

export type CreateBusinessMutationVariables = {
  input: CreateBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type CreateBusinessMutation = {
  createBusiness?:  {
    __typename: "Business",
    id: string,
    verified: boolean,
    name: string,
    tipping?:  {
      __typename: "BusinessTippingSetting",
      merchantId?: string | null,
      policy?: string | null,
      type?: TippingType | null,
      active?: boolean | null,
    } | null,
    marketing?:  {
      __typename: "BusinessMarketingSetting",
      customerId?: string | null,
      subscriptionId?: string | null,
      active?: boolean | null,
      expirationDate?: string | null,
      sequenceId?: string | null,
      cancelled?: boolean | null,
      cancelledAt?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    code: string,
    answers?:  Array< {
      __typename: "Answer",
      questionId?: string | null,
      value?: Array< string | null > | null,
    } | null > | null,
    isOutlet?: boolean | null,
    details?:  {
      __typename: "BusinessDetails",
      id: string,
      contactName?: string | null,
      jobRole?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ownerProfile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invites?:  {
      __typename: "ModelBusinessInviteConnection",
      items:  Array< {
        __typename: "BusinessInvite",
        id: string,
        businessId: string,
        receiver?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    employees?:  {
      __typename: "ModelBusinessEmployeeConnection",
      items:  Array< {
        __typename: "BusinessEmployee",
        id: string,
        jobRole: string,
        businessId: string,
        profileId: string,
        employeeId?: string | null,
        tippingPolicyAcknowledgedDate?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        confirmed?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brands?:  {
      __typename: "ModelBusinessBrandsConnection",
      items:  Array< {
        __typename: "BusinessBrands",
        id: string,
        businessId: string,
        brandId: string,
        status: BrandStatus,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profileId?: string | null,
        brand:  {
          __typename: "Brand",
          id: string,
          name: string,
          logo?: string | null,
          active?: boolean | null,
          productType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateBusinessMutationVariables = {
  input: UpdateBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type UpdateBusinessMutation = {
  updateBusiness?:  {
    __typename: "Business",
    id: string,
    verified: boolean,
    name: string,
    tipping?:  {
      __typename: "BusinessTippingSetting",
      merchantId?: string | null,
      policy?: string | null,
      type?: TippingType | null,
      active?: boolean | null,
    } | null,
    marketing?:  {
      __typename: "BusinessMarketingSetting",
      customerId?: string | null,
      subscriptionId?: string | null,
      active?: boolean | null,
      expirationDate?: string | null,
      sequenceId?: string | null,
      cancelled?: boolean | null,
      cancelledAt?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    code: string,
    answers?:  Array< {
      __typename: "Answer",
      questionId?: string | null,
      value?: Array< string | null > | null,
    } | null > | null,
    isOutlet?: boolean | null,
    details?:  {
      __typename: "BusinessDetails",
      id: string,
      contactName?: string | null,
      jobRole?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ownerProfile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invites?:  {
      __typename: "ModelBusinessInviteConnection",
      items:  Array< {
        __typename: "BusinessInvite",
        id: string,
        businessId: string,
        receiver?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    employees?:  {
      __typename: "ModelBusinessEmployeeConnection",
      items:  Array< {
        __typename: "BusinessEmployee",
        id: string,
        jobRole: string,
        businessId: string,
        profileId: string,
        employeeId?: string | null,
        tippingPolicyAcknowledgedDate?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        confirmed?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brands?:  {
      __typename: "ModelBusinessBrandsConnection",
      items:  Array< {
        __typename: "BusinessBrands",
        id: string,
        businessId: string,
        brandId: string,
        status: BrandStatus,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profileId?: string | null,
        brand:  {
          __typename: "Brand",
          id: string,
          name: string,
          logo?: string | null,
          active?: boolean | null,
          productType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteBusinessMutationVariables = {
  input: DeleteBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type DeleteBusinessMutation = {
  deleteBusiness?:  {
    __typename: "Business",
    id: string,
    verified: boolean,
    name: string,
    tipping?:  {
      __typename: "BusinessTippingSetting",
      merchantId?: string | null,
      policy?: string | null,
      type?: TippingType | null,
      active?: boolean | null,
    } | null,
    marketing?:  {
      __typename: "BusinessMarketingSetting",
      customerId?: string | null,
      subscriptionId?: string | null,
      active?: boolean | null,
      expirationDate?: string | null,
      sequenceId?: string | null,
      cancelled?: boolean | null,
      cancelledAt?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    code: string,
    answers?:  Array< {
      __typename: "Answer",
      questionId?: string | null,
      value?: Array< string | null > | null,
    } | null > | null,
    isOutlet?: boolean | null,
    details?:  {
      __typename: "BusinessDetails",
      id: string,
      contactName?: string | null,
      jobRole?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ownerProfile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invites?:  {
      __typename: "ModelBusinessInviteConnection",
      items:  Array< {
        __typename: "BusinessInvite",
        id: string,
        businessId: string,
        receiver?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    employees?:  {
      __typename: "ModelBusinessEmployeeConnection",
      items:  Array< {
        __typename: "BusinessEmployee",
        id: string,
        jobRole: string,
        businessId: string,
        profileId: string,
        employeeId?: string | null,
        tippingPolicyAcknowledgedDate?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        confirmed?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brands?:  {
      __typename: "ModelBusinessBrandsConnection",
      items:  Array< {
        __typename: "BusinessBrands",
        id: string,
        businessId: string,
        brandId: string,
        status: BrandStatus,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profileId?: string | null,
        brand:  {
          __typename: "Brand",
          id: string,
          name: string,
          logo?: string | null,
          active?: boolean | null,
          productType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateBusinessDetailsMutationVariables = {
  input: CreateBusinessDetailsInput,
  condition?: ModelBusinessDetailsConditionInput | null,
};

export type CreateBusinessDetailsMutation = {
  createBusinessDetails?:  {
    __typename: "BusinessDetails",
    id: string,
    contactName?: string | null,
    jobRole?: string | null,
    phone?: string | null,
    email?: string | null,
    website?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBusinessDetailsMutationVariables = {
  input: UpdateBusinessDetailsInput,
  condition?: ModelBusinessDetailsConditionInput | null,
};

export type UpdateBusinessDetailsMutation = {
  updateBusinessDetails?:  {
    __typename: "BusinessDetails",
    id: string,
    contactName?: string | null,
    jobRole?: string | null,
    phone?: string | null,
    email?: string | null,
    website?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBusinessDetailsMutationVariables = {
  input: DeleteBusinessDetailsInput,
  condition?: ModelBusinessDetailsConditionInput | null,
};

export type DeleteBusinessDetailsMutation = {
  deleteBusinessDetails?:  {
    __typename: "BusinessDetails",
    id: string,
    contactName?: string | null,
    jobRole?: string | null,
    phone?: string | null,
    email?: string | null,
    website?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProfileMutationVariables = {
  input: CreateProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type CreateProfileMutation = {
  createProfile?:  {
    __typename: "Profile",
    id: string,
    slug?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    bio?: Array< string | null > | null,
    picture?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    archived?: boolean | null,
    details?:  {
      __typename: "ProfileDetails",
      id: string,
      accountType?: string | null,
      isOnboardingComplete?: boolean | null,
      email?: string | null,
      feedRegion?: string | null,
      tippingAccount?:  {
        __typename: "TippingAccount",
        code?: string | null,
        merchant?: Merchant | null,
        accountId?: string | null,
        verified?: boolean | null,
        missingRequirements?:  Array< {
          __typename: "MissingRequirement",
          key?: string | null,
          description?: string | null,
        } | null > | null,
        pendingValidation?: Array< string | null > | null,
        bankDetails?:  {
          __typename: "BankDetails",
          name?: string | null,
          sortCode?: string | null,
          last4Digits?: string | null,
        } | null,
      } | null,
      phone_number?: string | null,
      phoneNumberConfirmed?: boolean | null,
      pushNotificationToken?: string | null,
      pushNotificationReadDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employers?:  {
      __typename: "ModelBusinessEmployeeConnection",
      items:  Array< {
        __typename: "BusinessEmployee",
        id: string,
        jobRole: string,
        businessId: string,
        profileId: string,
        employeeId?: string | null,
        tippingPolicyAcknowledgedDate?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        confirmed?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        jobTitle?: string | null,
        businessId?: string | null,
        companyName: string,
        profileId: string,
        startDate?: string | null,
        endDate?: string | null,
        employmentType?: string | null,
        location?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        profile?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelProfileJobConnection",
      items:  Array< {
        __typename: "ProfileJob",
        id: string,
        profileId: string,
        jobId: string,
        coverLetter?: Array< string | null > | null,
        saved?: boolean | null,
        savedDate?: string | null,
        applied?: boolean | null,
        appliedDate?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        job:  {
          __typename: "Job",
          id: string,
          owner?: string | null,
          jobType: string,
          businessId: string,
          jobRole: string,
          title: string,
          description?: Array< string | null > | null,
          salary?:  {
            __typename: "Salary",
            type: string,
            from?: string | null,
            to?: string | null,
          } | null,
          region: string,
          requireCV?: boolean | null,
          requireCoverLetter?: boolean | null,
          createdAt?: string | null,
          archived?: boolean | null,
          startDate?: string | null,
          endDate?: string | null,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        rejected?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateProfileMutationVariables = {
  input: UpdateProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type UpdateProfileMutation = {
  updateProfile?:  {
    __typename: "Profile",
    id: string,
    slug?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    bio?: Array< string | null > | null,
    picture?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    archived?: boolean | null,
    details?:  {
      __typename: "ProfileDetails",
      id: string,
      accountType?: string | null,
      isOnboardingComplete?: boolean | null,
      email?: string | null,
      feedRegion?: string | null,
      tippingAccount?:  {
        __typename: "TippingAccount",
        code?: string | null,
        merchant?: Merchant | null,
        accountId?: string | null,
        verified?: boolean | null,
        missingRequirements?:  Array< {
          __typename: "MissingRequirement",
          key?: string | null,
          description?: string | null,
        } | null > | null,
        pendingValidation?: Array< string | null > | null,
        bankDetails?:  {
          __typename: "BankDetails",
          name?: string | null,
          sortCode?: string | null,
          last4Digits?: string | null,
        } | null,
      } | null,
      phone_number?: string | null,
      phoneNumberConfirmed?: boolean | null,
      pushNotificationToken?: string | null,
      pushNotificationReadDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employers?:  {
      __typename: "ModelBusinessEmployeeConnection",
      items:  Array< {
        __typename: "BusinessEmployee",
        id: string,
        jobRole: string,
        businessId: string,
        profileId: string,
        employeeId?: string | null,
        tippingPolicyAcknowledgedDate?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        confirmed?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        jobTitle?: string | null,
        businessId?: string | null,
        companyName: string,
        profileId: string,
        startDate?: string | null,
        endDate?: string | null,
        employmentType?: string | null,
        location?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        profile?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelProfileJobConnection",
      items:  Array< {
        __typename: "ProfileJob",
        id: string,
        profileId: string,
        jobId: string,
        coverLetter?: Array< string | null > | null,
        saved?: boolean | null,
        savedDate?: string | null,
        applied?: boolean | null,
        appliedDate?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        job:  {
          __typename: "Job",
          id: string,
          owner?: string | null,
          jobType: string,
          businessId: string,
          jobRole: string,
          title: string,
          description?: Array< string | null > | null,
          salary?:  {
            __typename: "Salary",
            type: string,
            from?: string | null,
            to?: string | null,
          } | null,
          region: string,
          requireCV?: boolean | null,
          requireCoverLetter?: boolean | null,
          createdAt?: string | null,
          archived?: boolean | null,
          startDate?: string | null,
          endDate?: string | null,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        rejected?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateProfileDetailsMutationVariables = {
  input: CreateProfileDetailsInput,
  condition?: ModelProfileDetailsConditionInput | null,
};

export type CreateProfileDetailsMutation = {
  createProfileDetails?:  {
    __typename: "ProfileDetails",
    id: string,
    accountType?: string | null,
    isOnboardingComplete?: boolean | null,
    email?: string | null,
    feedRegion?: string | null,
    tippingAccount?:  {
      __typename: "TippingAccount",
      code?: string | null,
      merchant?: Merchant | null,
      accountId?: string | null,
      verified?: boolean | null,
      missingRequirements?:  Array< {
        __typename: "MissingRequirement",
        key?: string | null,
        description?: string | null,
      } | null > | null,
      pendingValidation?: Array< string | null > | null,
      bankDetails?:  {
        __typename: "BankDetails",
        name?: string | null,
        sortCode?: string | null,
        last4Digits?: string | null,
      } | null,
    } | null,
    phone_number?: string | null,
    phoneNumberConfirmed?: boolean | null,
    pushNotificationToken?: string | null,
    pushNotificationReadDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProfileDetailsMutationVariables = {
  input: UpdateProfileDetailsInput,
  condition?: ModelProfileDetailsConditionInput | null,
};

export type UpdateProfileDetailsMutation = {
  updateProfileDetails?:  {
    __typename: "ProfileDetails",
    id: string,
    accountType?: string | null,
    isOnboardingComplete?: boolean | null,
    email?: string | null,
    feedRegion?: string | null,
    tippingAccount?:  {
      __typename: "TippingAccount",
      code?: string | null,
      merchant?: Merchant | null,
      accountId?: string | null,
      verified?: boolean | null,
      missingRequirements?:  Array< {
        __typename: "MissingRequirement",
        key?: string | null,
        description?: string | null,
      } | null > | null,
      pendingValidation?: Array< string | null > | null,
      bankDetails?:  {
        __typename: "BankDetails",
        name?: string | null,
        sortCode?: string | null,
        last4Digits?: string | null,
      } | null,
    } | null,
    phone_number?: string | null,
    phoneNumberConfirmed?: boolean | null,
    pushNotificationToken?: string | null,
    pushNotificationReadDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBusinessInviteMutationVariables = {
  input: CreateBusinessInviteInput,
  condition?: ModelBusinessInviteConditionInput | null,
};

export type CreateBusinessInviteMutation = {
  createBusinessInvite?:  {
    __typename: "BusinessInvite",
    id: string,
    businessId: string,
    receiver?: string | null,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateBusinessEmployeeMutationVariables = {
  input: CreateBusinessEmployeeInput,
  condition?: ModelBusinessEmployeeConditionInput | null,
};

export type CreateBusinessEmployeeMutation = {
  createBusinessEmployee?:  {
    __typename: "BusinessEmployee",
    id: string,
    jobRole: string,
    businessId: string,
    profileId: string,
    employeeId?: string | null,
    tippingPolicyAcknowledgedDate?: string | null,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    confirmed?: boolean | null,
  } | null,
};

export type UpdateBusinessEmployeeMutationVariables = {
  input: UpdateBusinessEmployeeInput,
  condition?: ModelBusinessEmployeeConditionInput | null,
};

export type UpdateBusinessEmployeeMutation = {
  updateBusinessEmployee?:  {
    __typename: "BusinessEmployee",
    id: string,
    jobRole: string,
    businessId: string,
    profileId: string,
    employeeId?: string | null,
    tippingPolicyAcknowledgedDate?: string | null,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    confirmed?: boolean | null,
  } | null,
};

export type DeleteBusinessEmployeeMutationVariables = {
  input: DeleteBusinessEmployeeInput,
  condition?: ModelBusinessEmployeeConditionInput | null,
};

export type DeleteBusinessEmployeeMutation = {
  deleteBusinessEmployee?:  {
    __typename: "BusinessEmployee",
    id: string,
    jobRole: string,
    businessId: string,
    profileId: string,
    employeeId?: string | null,
    tippingPolicyAcknowledgedDate?: string | null,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    confirmed?: boolean | null,
  } | null,
};

export type CreateBusinessBrandsMutationVariables = {
  input: CreateBusinessBrandsInput,
  condition?: ModelBusinessBrandsConditionInput | null,
};

export type CreateBusinessBrandsMutation = {
  createBusinessBrands?:  {
    __typename: "BusinessBrands",
    id: string,
    businessId: string,
    brandId: string,
    status: BrandStatus,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profileId?: string | null,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      logo?: string | null,
      active?: boolean | null,
      productType?: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type CreateBrandMutationVariables = {
  input: CreateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type CreateBrandMutation = {
  createBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    logo?: string | null,
    active?: boolean | null,
    productType?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBrandMutationVariables = {
  input: UpdateBrandInput,
  condition?: ModelBrandConditionInput | null,
};

export type UpdateBrandMutation = {
  updateBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    logo?: string | null,
    active?: boolean | null,
    productType?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateExperienceMutationVariables = {
  input: CreateExperienceInput,
  condition?: ModelExperienceConditionInput | null,
};

export type CreateExperienceMutation = {
  createExperience?:  {
    __typename: "Experience",
    id: string,
    jobTitle?: string | null,
    businessId?: string | null,
    companyName: string,
    profileId: string,
    startDate?: string | null,
    endDate?: string | null,
    employmentType?: string | null,
    location?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateExperienceMutationVariables = {
  input: UpdateExperienceInput,
  condition?: ModelExperienceConditionInput | null,
};

export type UpdateExperienceMutation = {
  updateExperience?:  {
    __typename: "Experience",
    id: string,
    jobTitle?: string | null,
    businessId?: string | null,
    companyName: string,
    profileId: string,
    startDate?: string | null,
    endDate?: string | null,
    employmentType?: string | null,
    location?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreatePostMutationVariables = {
  input: CreatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type CreatePostMutation = {
  createPost?:  {
    __typename: "Post",
    id: string,
    hubId: string,
    creatorId: string,
    content?: Array< string | null > | null,
    createdAt?: string | null,
    archived?: boolean | null,
    feedRegion: string,
    regions?: Array< string > | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelPostLikeConnection",
      items:  Array< {
        __typename: "PostLike",
        id: string,
        postId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdatePostMutationVariables = {
  input: UpdatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type UpdatePostMutation = {
  updatePost?:  {
    __typename: "Post",
    id: string,
    hubId: string,
    creatorId: string,
    content?: Array< string | null > | null,
    createdAt?: string | null,
    archived?: boolean | null,
    feedRegion: string,
    regions?: Array< string > | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelPostLikeConnection",
      items:  Array< {
        __typename: "PostLike",
        id: string,
        postId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeletePostMutationVariables = {
  input: DeletePostInput,
  condition?: ModelPostConditionInput | null,
};

export type DeletePostMutation = {
  deletePost?:  {
    __typename: "Post",
    id: string,
    hubId: string,
    creatorId: string,
    content?: Array< string | null > | null,
    createdAt?: string | null,
    archived?: boolean | null,
    feedRegion: string,
    regions?: Array< string > | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelPostLikeConnection",
      items:  Array< {
        __typename: "PostLike",
        id: string,
        postId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateJobMutationVariables = {
  input: CreateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type CreateJobMutation = {
  createJob?:  {
    __typename: "Job",
    id: string,
    owner?: string | null,
    jobType: string,
    businessId: string,
    jobRole: string,
    title: string,
    description?: Array< string | null > | null,
    salary?:  {
      __typename: "Salary",
      type: string,
      from?: string | null,
      to?: string | null,
    } | null,
    region: string,
    requireCV?: boolean | null,
    requireCoverLetter?: boolean | null,
    createdAt?: string | null,
    archived?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateJobMutationVariables = {
  input: UpdateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type UpdateJobMutation = {
  updateJob?:  {
    __typename: "Job",
    id: string,
    owner?: string | null,
    jobType: string,
    businessId: string,
    jobRole: string,
    title: string,
    description?: Array< string | null > | null,
    salary?:  {
      __typename: "Salary",
      type: string,
      from?: string | null,
      to?: string | null,
    } | null,
    region: string,
    requireCV?: boolean | null,
    requireCoverLetter?: boolean | null,
    createdAt?: string | null,
    archived?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateProfileJobMutationVariables = {
  input: CreateProfileJobInput,
  condition?: ModelProfileJobConditionInput | null,
};

export type CreateProfileJobMutation = {
  createProfileJob?:  {
    __typename: "ProfileJob",
    id: string,
    profileId: string,
    jobId: string,
    coverLetter?: Array< string | null > | null,
    saved?: boolean | null,
    savedDate?: string | null,
    applied?: boolean | null,
    appliedDate?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    job:  {
      __typename: "Job",
      id: string,
      owner?: string | null,
      jobType: string,
      businessId: string,
      jobRole: string,
      title: string,
      description?: Array< string | null > | null,
      salary?:  {
        __typename: "Salary",
        type: string,
        from?: string | null,
        to?: string | null,
      } | null,
      region: string,
      requireCV?: boolean | null,
      requireCoverLetter?: boolean | null,
      createdAt?: string | null,
      archived?: boolean | null,
      startDate?: string | null,
      endDate?: string | null,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    rejected?: boolean | null,
  } | null,
};

export type UpdateProfileJobMutationVariables = {
  input: UpdateProfileJobInput,
  condition?: ModelProfileJobConditionInput | null,
};

export type UpdateProfileJobMutation = {
  updateProfileJob?:  {
    __typename: "ProfileJob",
    id: string,
    profileId: string,
    jobId: string,
    coverLetter?: Array< string | null > | null,
    saved?: boolean | null,
    savedDate?: string | null,
    applied?: boolean | null,
    appliedDate?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    job:  {
      __typename: "Job",
      id: string,
      owner?: string | null,
      jobType: string,
      businessId: string,
      jobRole: string,
      title: string,
      description?: Array< string | null > | null,
      salary?:  {
        __typename: "Salary",
        type: string,
        from?: string | null,
        to?: string | null,
      } | null,
      region: string,
      requireCV?: boolean | null,
      requireCoverLetter?: boolean | null,
      createdAt?: string | null,
      archived?: boolean | null,
      startDate?: string | null,
      endDate?: string | null,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    rejected?: boolean | null,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    postId?: string | null,
    commentId?: string | null,
    content?: Array< string | null > | null,
    creatorId: string,
    createdAt: string,
    archived?: boolean | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    post?:  {
      __typename: "Post",
      id: string,
      hubId: string,
      creatorId: string,
      content?: Array< string | null > | null,
      createdAt?: string | null,
      archived?: boolean | null,
      feedRegion: string,
      regions?: Array< string > | null,
      attachments?:  Array< {
        __typename: "attachment",
        mediaType: string,
        source: string,
        alt?: string | null,
      } | null > | null,
      updatedAt: string,
      creator:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          postId?: string | null,
          commentId?: string | null,
          content?: Array< string | null > | null,
          creatorId: string,
          createdAt: string,
          archived?: boolean | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          post?:  {
            __typename: "Post",
            id: string,
            hubId: string,
            creatorId: string,
            content?: Array< string | null > | null,
            createdAt?: string | null,
            archived?: boolean | null,
            feedRegion: string,
            regions?: Array< string > | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelPostLikeConnection",
              items:  Array< {
                __typename: "PostLike",
                id: string,
                postId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelCommentLikeConnection",
            items:  Array< {
              __typename: "CommentLike",
              id: string,
              commentId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelPostLikeConnection",
        items:  Array< {
          __typename: "PostLike",
          id: string,
          postId: string,
          profileId: string,
          createdAt: string,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelCommentLikeConnection",
      items:  Array< {
        __typename: "CommentLike",
        id: string,
        commentId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
    postId?: string | null,
    commentId?: string | null,
    content?: Array< string | null > | null,
    creatorId: string,
    createdAt: string,
    archived?: boolean | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    post?:  {
      __typename: "Post",
      id: string,
      hubId: string,
      creatorId: string,
      content?: Array< string | null > | null,
      createdAt?: string | null,
      archived?: boolean | null,
      feedRegion: string,
      regions?: Array< string > | null,
      attachments?:  Array< {
        __typename: "attachment",
        mediaType: string,
        source: string,
        alt?: string | null,
      } | null > | null,
      updatedAt: string,
      creator:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          postId?: string | null,
          commentId?: string | null,
          content?: Array< string | null > | null,
          creatorId: string,
          createdAt: string,
          archived?: boolean | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          post?:  {
            __typename: "Post",
            id: string,
            hubId: string,
            creatorId: string,
            content?: Array< string | null > | null,
            createdAt?: string | null,
            archived?: boolean | null,
            feedRegion: string,
            regions?: Array< string > | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelPostLikeConnection",
              items:  Array< {
                __typename: "PostLike",
                id: string,
                postId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelCommentLikeConnection",
            items:  Array< {
              __typename: "CommentLike",
              id: string,
              commentId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelPostLikeConnection",
        items:  Array< {
          __typename: "PostLike",
          id: string,
          postId: string,
          profileId: string,
          createdAt: string,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelCommentLikeConnection",
      items:  Array< {
        __typename: "CommentLike",
        id: string,
        commentId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    postId?: string | null,
    commentId?: string | null,
    content?: Array< string | null > | null,
    creatorId: string,
    createdAt: string,
    archived?: boolean | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    post?:  {
      __typename: "Post",
      id: string,
      hubId: string,
      creatorId: string,
      content?: Array< string | null > | null,
      createdAt?: string | null,
      archived?: boolean | null,
      feedRegion: string,
      regions?: Array< string > | null,
      attachments?:  Array< {
        __typename: "attachment",
        mediaType: string,
        source: string,
        alt?: string | null,
      } | null > | null,
      updatedAt: string,
      creator:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          postId?: string | null,
          commentId?: string | null,
          content?: Array< string | null > | null,
          creatorId: string,
          createdAt: string,
          archived?: boolean | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          post?:  {
            __typename: "Post",
            id: string,
            hubId: string,
            creatorId: string,
            content?: Array< string | null > | null,
            createdAt?: string | null,
            archived?: boolean | null,
            feedRegion: string,
            regions?: Array< string > | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelPostLikeConnection",
              items:  Array< {
                __typename: "PostLike",
                id: string,
                postId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelCommentLikeConnection",
            items:  Array< {
              __typename: "CommentLike",
              id: string,
              commentId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelPostLikeConnection",
        items:  Array< {
          __typename: "PostLike",
          id: string,
          postId: string,
          profileId: string,
          createdAt: string,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelCommentLikeConnection",
      items:  Array< {
        __typename: "CommentLike",
        id: string,
        commentId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateReportInfoMutationVariables = {
  input: CreateReportInfoInput,
  condition?: ModelReportInfoConditionInput | null,
};

export type CreateReportInfoMutation = {
  createReportInfo?:  {
    __typename: "ReportInfo",
    id: string,
    postId?: string | null,
    userId?: string | null,
    commentId?: string | null,
    jobId?: string | null,
    creatorId: string,
    createdAt: string,
    message: string,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateReportInfoMutationVariables = {
  input: UpdateReportInfoInput,
  condition?: ModelReportInfoConditionInput | null,
};

export type UpdateReportInfoMutation = {
  updateReportInfo?:  {
    __typename: "ReportInfo",
    id: string,
    postId?: string | null,
    userId?: string | null,
    commentId?: string | null,
    jobId?: string | null,
    creatorId: string,
    createdAt: string,
    message: string,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteReportInfoMutationVariables = {
  input: DeleteReportInfoInput,
  condition?: ModelReportInfoConditionInput | null,
};

export type DeleteReportInfoMutation = {
  deleteReportInfo?:  {
    __typename: "ReportInfo",
    id: string,
    postId?: string | null,
    userId?: string | null,
    commentId?: string | null,
    jobId?: string | null,
    creatorId: string,
    createdAt: string,
    message: string,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreatePostLikeMutationVariables = {
  input: CreatePostLikeInput,
  condition?: ModelPostLikeConditionInput | null,
};

export type CreatePostLikeMutation = {
  createPostLike?:  {
    __typename: "PostLike",
    id: string,
    postId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdatePostLikeMutationVariables = {
  input: UpdatePostLikeInput,
  condition?: ModelPostLikeConditionInput | null,
};

export type UpdatePostLikeMutation = {
  updatePostLike?:  {
    __typename: "PostLike",
    id: string,
    postId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeletePostLikeMutationVariables = {
  input: DeletePostLikeInput,
  condition?: ModelPostLikeConditionInput | null,
};

export type DeletePostLikeMutation = {
  deletePostLike?:  {
    __typename: "PostLike",
    id: string,
    postId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateCommentLikeMutationVariables = {
  input: CreateCommentLikeInput,
  condition?: ModelCommentLikeConditionInput | null,
};

export type CreateCommentLikeMutation = {
  createCommentLike?:  {
    __typename: "CommentLike",
    id: string,
    commentId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateCommentLikeMutationVariables = {
  input: UpdateCommentLikeInput,
  condition?: ModelCommentLikeConditionInput | null,
};

export type UpdateCommentLikeMutation = {
  updateCommentLike?:  {
    __typename: "CommentLike",
    id: string,
    commentId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteCommentLikeMutationVariables = {
  input: DeleteCommentLikeInput,
  condition?: ModelCommentLikeConditionInput | null,
};

export type DeleteCommentLikeMutation = {
  deleteCommentLike?:  {
    __typename: "CommentLike",
    id: string,
    commentId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateUserNotificationMutationVariables = {
  input: CreateUserNotificationInput,
  condition?: ModelUserNotificationConditionInput | null,
};

export type CreateUserNotificationMutation = {
  createUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    receiverId: string,
    senderId?: string | null,
    type: NotificationType,
    targetId?: string | null,
    subTargetId?: string | null,
    additionalTargetId?: string | null,
    read: boolean,
    title?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateUserNotificationMutationVariables = {
  input: UpdateUserNotificationInput,
  condition?: ModelUserNotificationConditionInput | null,
};

export type UpdateUserNotificationMutation = {
  updateUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    receiverId: string,
    senderId?: string | null,
    type: NotificationType,
    targetId?: string | null,
    subTargetId?: string | null,
    additionalTargetId?: string | null,
    read: boolean,
    title?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreatePublicEmailMutationVariables = {
  input: CreatePublicEmailInput,
  condition?: ModelPublicEmailConditionInput | null,
};

export type CreatePublicEmailMutation = {
  createPublicEmail?:  {
    __typename: "PublicEmail",
    id: string,
    email: string,
    allowMarketing: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTipSplitMutationVariables = {
  input: CreateTipSplitInput,
  condition?: ModelTipSplitConditionInput | null,
};

export type CreateTipSplitMutation = {
  createTipSplit?:  {
    __typename: "TipSplit",
    id: string,
    amount: number,
    owner: string,
    status: TransactionStatus,
    from: string,
    to: string,
    createdAt: string,
    updatedAt: string,
    ownerProfile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    transfers?:  {
      __typename: "ModelEmployeeTransferConnection",
      items:  Array< {
        __typename: "EmployeeTransfer",
        id: string,
        splitId: string,
        employeeId: string,
        amount: number,
        status: TransactionStatus,
        createdAt: string,
        updatedAt: string,
        employee?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateTipSplitMutationVariables = {
  input: UpdateTipSplitInput,
  condition?: ModelTipSplitConditionInput | null,
};

export type UpdateTipSplitMutation = {
  updateTipSplit?:  {
    __typename: "TipSplit",
    id: string,
    amount: number,
    owner: string,
    status: TransactionStatus,
    from: string,
    to: string,
    createdAt: string,
    updatedAt: string,
    ownerProfile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    transfers?:  {
      __typename: "ModelEmployeeTransferConnection",
      items:  Array< {
        __typename: "EmployeeTransfer",
        id: string,
        splitId: string,
        employeeId: string,
        amount: number,
        status: TransactionStatus,
        createdAt: string,
        updatedAt: string,
        employee?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateEmployeeTransferMutationVariables = {
  input: CreateEmployeeTransferInput,
  condition?: ModelEmployeeTransferConditionInput | null,
};

export type CreateEmployeeTransferMutation = {
  createEmployeeTransfer?:  {
    __typename: "EmployeeTransfer",
    id: string,
    splitId: string,
    employeeId: string,
    amount: number,
    status: TransactionStatus,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateEmployeeTransferMutationVariables = {
  input: UpdateEmployeeTransferInput,
  condition?: ModelEmployeeTransferConditionInput | null,
};

export type UpdateEmployeeTransferMutation = {
  updateEmployeeTransfer?:  {
    __typename: "EmployeeTransfer",
    id: string,
    splitId: string,
    employeeId: string,
    amount: number,
    status: TransactionStatus,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteEmployeeTransferMutationVariables = {
  input: DeleteEmployeeTransferInput,
  condition?: ModelEmployeeTransferConditionInput | null,
};

export type DeleteEmployeeTransferMutation = {
  deleteEmployeeTransfer?:  {
    __typename: "EmployeeTransfer",
    id: string,
    splitId: string,
    employeeId: string,
    amount: number,
    status: TransactionStatus,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateTipTransactionMutationVariables = {
  input: CreateTipTransactionInput,
  condition?: ModelTipTransactionConditionInput | null,
};

export type CreateTipTransactionMutation = {
  createTipTransaction?:  {
    __typename: "TipTransaction",
    id: string,
    splitId?: string | null,
    receiverId: string,
    amount: string,
    fee: string,
    currency: string,
    createdAt: string,
    status: TransactionStatus,
    type: TippingType,
    source: TipTransactionSource,
    payoutInvoiceId?: string | null,
    transactionId?: string | null,
    updatedAt: string,
    receiver?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateTipTransactionMutationVariables = {
  input: UpdateTipTransactionInput,
  condition?: ModelTipTransactionConditionInput | null,
};

export type UpdateTipTransactionMutation = {
  updateTipTransaction?:  {
    __typename: "TipTransaction",
    id: string,
    splitId?: string | null,
    receiverId: string,
    amount: string,
    fee: string,
    currency: string,
    createdAt: string,
    status: TransactionStatus,
    type: TippingType,
    source: TipTransactionSource,
    payoutInvoiceId?: string | null,
    transactionId?: string | null,
    updatedAt: string,
    receiver?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateBusinessSetupTippingInviteMutationVariables = {
  input: CreateBusinessSetupTippingInviteInput,
  condition?: ModelBusinessSetupTippingInviteConditionInput | null,
};

export type CreateBusinessSetupTippingInviteMutation = {
  createBusinessSetupTippingInvite?:  {
    __typename: "BusinessSetupTippingInvite",
    id: string,
    sender: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBusinessSetupTippingInviteMutationVariables = {
  input: UpdateBusinessSetupTippingInviteInput,
  condition?: ModelBusinessSetupTippingInviteConditionInput | null,
};

export type UpdateBusinessSetupTippingInviteMutation = {
  updateBusinessSetupTippingInvite?:  {
    __typename: "BusinessSetupTippingInvite",
    id: string,
    sender: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBusinessSetupTippingInviteMutationVariables = {
  input: DeleteBusinessSetupTippingInviteInput,
  condition?: ModelBusinessSetupTippingInviteConditionInput | null,
};

export type DeleteBusinessSetupTippingInviteMutation = {
  deleteBusinessSetupTippingInvite?:  {
    __typename: "BusinessSetupTippingInvite",
    id: string,
    sender: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMessageMutationVariables = {
  input: CreateUserMessageInput,
  condition?: ModelUserMessageConditionInput | null,
};

export type CreateUserMessageMutation = {
  createUserMessage?:  {
    __typename: "UserMessage",
    id: string,
    senderId: string,
    receiverId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateUserPhoneValidationMutationVariables = {
  input: CreateUserPhoneValidationInput,
  condition?: ModelUserPhoneValidationConditionInput | null,
};

export type CreateUserPhoneValidationMutation = {
  createUserPhoneValidation?:  {
    __typename: "UserPhoneValidation",
    id: string,
    userId: string,
    phoneNumber: string,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserPhoneValidationMutationVariables = {
  input: UpdateUserPhoneValidationInput,
  condition?: ModelUserPhoneValidationConditionInput | null,
};

export type UpdateUserPhoneValidationMutation = {
  updateUserPhoneValidation?:  {
    __typename: "UserPhoneValidation",
    id: string,
    userId: string,
    phoneNumber: string,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserPhoneValidationMutationVariables = {
  input: DeleteUserPhoneValidationInput,
  condition?: ModelUserPhoneValidationConditionInput | null,
};

export type DeleteUserPhoneValidationMutation = {
  deleteUserPhoneValidation?:  {
    __typename: "UserPhoneValidation",
    id: string,
    userId: string,
    phoneNumber: string,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePayoutInvoiceMutationVariables = {
  input: CreatePayoutInvoiceInput,
  condition?: ModelPayoutInvoiceConditionInput | null,
};

export type CreatePayoutInvoiceMutation = {
  createPayoutInvoice?:  {
    __typename: "PayoutInvoice",
    id: string,
    createdAt: string,
    file: string,
    amount: number,
    fee: number,
    currency: string,
    status: PayoutStatus,
    payedAt?: string | null,
    updatedAt: string,
    tipTransactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdatePayoutInvoiceMutationVariables = {
  input: UpdatePayoutInvoiceInput,
  condition?: ModelPayoutInvoiceConditionInput | null,
};

export type UpdatePayoutInvoiceMutation = {
  updatePayoutInvoice?:  {
    __typename: "PayoutInvoice",
    id: string,
    createdAt: string,
    file: string,
    amount: number,
    fee: number,
    currency: string,
    status: PayoutStatus,
    payedAt?: string | null,
    updatedAt: string,
    tipTransactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeletePayoutInvoiceMutationVariables = {
  input: DeletePayoutInvoiceInput,
  condition?: ModelPayoutInvoiceConditionInput | null,
};

export type DeletePayoutInvoiceMutation = {
  deletePayoutInvoice?:  {
    __typename: "PayoutInvoice",
    id: string,
    createdAt: string,
    file: string,
    amount: number,
    fee: number,
    currency: string,
    status: PayoutStatus,
    payedAt?: string | null,
    updatedAt: string,
    tipTransactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetProfileEnterpriseQueryVariables = {
  id: string,
};

export type GetProfileEnterpriseQuery = {
  getProfileEnterprise?:  {
    __typename: "ProfileEnterprise",
    id: string,
    name: string,
    address:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    },
    companyNumber?: string | null,
    businesses?: Array< string | null > | null,
    active: boolean,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    picture?: string | null,
    contactDetails:  {
      __typename: "ProfileEnterpriseContactDetails",
      name: string,
      role: string,
      phone: string,
      email: string,
    },
  } | null,
};

export type ListProfileEnterprisesQueryVariables = {
  filter?: ModelProfileEnterpriseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileEnterprisesQuery = {
  listProfileEnterprises?:  {
    __typename: "ModelProfileEnterpriseConnection",
    items:  Array< {
      __typename: "ProfileEnterprise",
      id: string,
      name: string,
      address:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      },
      companyNumber?: string | null,
      businesses?: Array< string | null > | null,
      active: boolean,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      picture?: string | null,
      contactDetails:  {
        __typename: "ProfileEnterpriseContactDetails",
        name: string,
        role: string,
        phone: string,
        email: string,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessStatisticsQueryVariables = {
  id: string,
};

export type GetBusinessStatisticsQuery = {
  getBusinessStatistics?:  {
    __typename: "BusinessStatistics",
    id: string,
    data?:  Array< {
      __typename: "BusinessStatisticsInfo",
      date: string,
      totalTips: number,
      totalTipsCount: number,
    } | null > | null,
    updatedAt: string,
    owner: string,
    lastSplitDate?: string | null,
    createdAt: string,
  } | null,
};

export type ListBusinessStatisticssQueryVariables = {
  filter?: ModelBusinessStatisticsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessStatisticssQuery = {
  listBusinessStatisticss?:  {
    __typename: "ModelBusinessStatisticsConnection",
    items:  Array< {
      __typename: "BusinessStatistics",
      id: string,
      data?:  Array< {
        __typename: "BusinessStatisticsInfo",
        date: string,
        totalTips: number,
        totalTipsCount: number,
      } | null > | null,
      updatedAt: string,
      owner: string,
      lastSplitDate?: string | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BusinessByCodeQueryVariables = {
  code?: string | null,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBusinessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BusinessByCodeQuery = {
  businessByCode?:  {
    __typename: "ModelBusinessConnection",
    items:  Array< {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BusinessByNameQueryVariables = {
  name?: string | null,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBusinessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BusinessByNameQuery = {
  businessByName?:  {
    __typename: "ModelBusinessConnection",
    items:  Array< {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchBusinesssQueryVariables = {
  filter?: SearchableBusinessFilterInput | null,
  sort?: SearchableBusinessSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchBusinesssQuery = {
  searchBusinesss?:  {
    __typename: "SearchableBusinessConnection",
    items:  Array< {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetBusinessQueryVariables = {
  id: string,
};

export type GetBusinessQuery = {
  getBusiness?:  {
    __typename: "Business",
    id: string,
    verified: boolean,
    name: string,
    tipping?:  {
      __typename: "BusinessTippingSetting",
      merchantId?: string | null,
      policy?: string | null,
      type?: TippingType | null,
      active?: boolean | null,
    } | null,
    marketing?:  {
      __typename: "BusinessMarketingSetting",
      customerId?: string | null,
      subscriptionId?: string | null,
      active?: boolean | null,
      expirationDate?: string | null,
      sequenceId?: string | null,
      cancelled?: boolean | null,
      cancelledAt?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    code: string,
    answers?:  Array< {
      __typename: "Answer",
      questionId?: string | null,
      value?: Array< string | null > | null,
    } | null > | null,
    isOutlet?: boolean | null,
    details?:  {
      __typename: "BusinessDetails",
      id: string,
      contactName?: string | null,
      jobRole?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ownerProfile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invites?:  {
      __typename: "ModelBusinessInviteConnection",
      items:  Array< {
        __typename: "BusinessInvite",
        id: string,
        businessId: string,
        receiver?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    employees?:  {
      __typename: "ModelBusinessEmployeeConnection",
      items:  Array< {
        __typename: "BusinessEmployee",
        id: string,
        jobRole: string,
        businessId: string,
        profileId: string,
        employeeId?: string | null,
        tippingPolicyAcknowledgedDate?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        confirmed?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    brands?:  {
      __typename: "ModelBusinessBrandsConnection",
      items:  Array< {
        __typename: "BusinessBrands",
        id: string,
        businessId: string,
        brandId: string,
        status: BrandStatus,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profileId?: string | null,
        brand:  {
          __typename: "Brand",
          id: string,
          name: string,
          logo?: string | null,
          active?: boolean | null,
          productType?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListBusinesssQueryVariables = {
  filter?: ModelBusinessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinesssQuery = {
  listBusinesss?:  {
    __typename: "ModelBusinessConnection",
    items:  Array< {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessDetailsQueryVariables = {
  id: string,
};

export type GetBusinessDetailsQuery = {
  getBusinessDetails?:  {
    __typename: "BusinessDetails",
    id: string,
    contactName?: string | null,
    jobRole?: string | null,
    phone?: string | null,
    email?: string | null,
    website?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBusinessDetailssQueryVariables = {
  filter?: ModelBusinessDetailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessDetailssQuery = {
  listBusinessDetailss?:  {
    __typename: "ModelBusinessDetailsConnection",
    items:  Array< {
      __typename: "BusinessDetails",
      id: string,
      contactName?: string | null,
      jobRole?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProfilesBySlugQueryVariables = {
  slug?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetProfilesBySlugQuery = {
  getProfilesBySlug?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProfilesQueryVariables = {
  filter?: SearchableProfileFilterInput | null,
  sort?: SearchableProfileSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchProfilesQuery = {
  searchProfiles?:  {
    __typename: "SearchableProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetProfileQueryVariables = {
  id: string,
};

export type GetProfileQuery = {
  getProfile?:  {
    __typename: "Profile",
    id: string,
    slug?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    bio?: Array< string | null > | null,
    picture?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    archived?: boolean | null,
    details?:  {
      __typename: "ProfileDetails",
      id: string,
      accountType?: string | null,
      isOnboardingComplete?: boolean | null,
      email?: string | null,
      feedRegion?: string | null,
      tippingAccount?:  {
        __typename: "TippingAccount",
        code?: string | null,
        merchant?: Merchant | null,
        accountId?: string | null,
        verified?: boolean | null,
        missingRequirements?:  Array< {
          __typename: "MissingRequirement",
          key?: string | null,
          description?: string | null,
        } | null > | null,
        pendingValidation?: Array< string | null > | null,
        bankDetails?:  {
          __typename: "BankDetails",
          name?: string | null,
          sortCode?: string | null,
          last4Digits?: string | null,
        } | null,
      } | null,
      phone_number?: string | null,
      phoneNumberConfirmed?: boolean | null,
      pushNotificationToken?: string | null,
      pushNotificationReadDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employers?:  {
      __typename: "ModelBusinessEmployeeConnection",
      items:  Array< {
        __typename: "BusinessEmployee",
        id: string,
        jobRole: string,
        businessId: string,
        profileId: string,
        employeeId?: string | null,
        tippingPolicyAcknowledgedDate?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        confirmed?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        jobTitle?: string | null,
        businessId?: string | null,
        companyName: string,
        profileId: string,
        startDate?: string | null,
        endDate?: string | null,
        employmentType?: string | null,
        location?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        profile?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelProfileJobConnection",
      items:  Array< {
        __typename: "ProfileJob",
        id: string,
        profileId: string,
        jobId: string,
        coverLetter?: Array< string | null > | null,
        saved?: boolean | null,
        savedDate?: string | null,
        applied?: boolean | null,
        appliedDate?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        job:  {
          __typename: "Job",
          id: string,
          owner?: string | null,
          jobType: string,
          businessId: string,
          jobRole: string,
          title: string,
          description?: Array< string | null > | null,
          salary?:  {
            __typename: "Salary",
            type: string,
            from?: string | null,
            to?: string | null,
          } | null,
          region: string,
          requireCV?: boolean | null,
          requireCoverLetter?: boolean | null,
          createdAt?: string | null,
          archived?: boolean | null,
          startDate?: string | null,
          endDate?: string | null,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        rejected?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListProfilesQueryVariables = {
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesQuery = {
  listProfiles?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProfileDetailsQueryVariables = {
  id: string,
};

export type GetProfileDetailsQuery = {
  getProfileDetails?:  {
    __typename: "ProfileDetails",
    id: string,
    accountType?: string | null,
    isOnboardingComplete?: boolean | null,
    email?: string | null,
    feedRegion?: string | null,
    tippingAccount?:  {
      __typename: "TippingAccount",
      code?: string | null,
      merchant?: Merchant | null,
      accountId?: string | null,
      verified?: boolean | null,
      missingRequirements?:  Array< {
        __typename: "MissingRequirement",
        key?: string | null,
        description?: string | null,
      } | null > | null,
      pendingValidation?: Array< string | null > | null,
      bankDetails?:  {
        __typename: "BankDetails",
        name?: string | null,
        sortCode?: string | null,
        last4Digits?: string | null,
      } | null,
    } | null,
    phone_number?: string | null,
    phoneNumberConfirmed?: boolean | null,
    pushNotificationToken?: string | null,
    pushNotificationReadDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProfileDetailssQueryVariables = {
  filter?: ModelProfileDetailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileDetailssQuery = {
  listProfileDetailss?:  {
    __typename: "ModelProfileDetailsConnection",
    items:  Array< {
      __typename: "ProfileDetails",
      id: string,
      accountType?: string | null,
      isOnboardingComplete?: boolean | null,
      email?: string | null,
      feedRegion?: string | null,
      tippingAccount?:  {
        __typename: "TippingAccount",
        code?: string | null,
        merchant?: Merchant | null,
        accountId?: string | null,
        verified?: boolean | null,
        missingRequirements?:  Array< {
          __typename: "MissingRequirement",
          key?: string | null,
          description?: string | null,
        } | null > | null,
        pendingValidation?: Array< string | null > | null,
        bankDetails?:  {
          __typename: "BankDetails",
          name?: string | null,
          sortCode?: string | null,
          last4Digits?: string | null,
        } | null,
      } | null,
      phone_number?: string | null,
      phoneNumberConfirmed?: boolean | null,
      pushNotificationToken?: string | null,
      pushNotificationReadDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProfileDetailssQueryVariables = {
  filter?: SearchableProfileDetailsFilterInput | null,
  sort?: SearchableProfileDetailsSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchProfileDetailssQuery = {
  searchProfileDetailss?:  {
    __typename: "SearchableProfileDetailsConnection",
    items:  Array< {
      __typename: "ProfileDetails",
      id: string,
      accountType?: string | null,
      isOnboardingComplete?: boolean | null,
      email?: string | null,
      feedRegion?: string | null,
      tippingAccount?:  {
        __typename: "TippingAccount",
        code?: string | null,
        merchant?: Merchant | null,
        accountId?: string | null,
        verified?: boolean | null,
        missingRequirements?:  Array< {
          __typename: "MissingRequirement",
          key?: string | null,
          description?: string | null,
        } | null > | null,
        pendingValidation?: Array< string | null > | null,
        bankDetails?:  {
          __typename: "BankDetails",
          name?: string | null,
          sortCode?: string | null,
          last4Digits?: string | null,
        } | null,
      } | null,
      phone_number?: string | null,
      phoneNumberConfirmed?: boolean | null,
      pushNotificationToken?: string | null,
      pushNotificationReadDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type BusinessEmployeeByProfileQueryVariables = {
  profileId?: string | null,
  businessId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBusinessEmployeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BusinessEmployeeByProfileQuery = {
  businessEmployeeByProfile?:  {
    __typename: "ModelBusinessEmployeeConnection",
    items:  Array< {
      __typename: "BusinessEmployee",
      id: string,
      jobRole: string,
      businessId: string,
      profileId: string,
      employeeId?: string | null,
      tippingPolicyAcknowledgedDate?: string | null,
      createdAt: string,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      profile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      confirmed?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessEmployeeQueryVariables = {
  id: string,
};

export type GetBusinessEmployeeQuery = {
  getBusinessEmployee?:  {
    __typename: "BusinessEmployee",
    id: string,
    jobRole: string,
    businessId: string,
    profileId: string,
    employeeId?: string | null,
    tippingPolicyAcknowledgedDate?: string | null,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    confirmed?: boolean | null,
  } | null,
};

export type ListBusinessEmployeesQueryVariables = {
  filter?: ModelBusinessEmployeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessEmployeesQuery = {
  listBusinessEmployees?:  {
    __typename: "ModelBusinessEmployeeConnection",
    items:  Array< {
      __typename: "BusinessEmployee",
      id: string,
      jobRole: string,
      businessId: string,
      profileId: string,
      employeeId?: string | null,
      tippingPolicyAcknowledgedDate?: string | null,
      createdAt: string,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      profile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      confirmed?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessBrandsQueryVariables = {
  id: string,
};

export type GetBusinessBrandsQuery = {
  getBusinessBrands?:  {
    __typename: "BusinessBrands",
    id: string,
    businessId: string,
    brandId: string,
    status: BrandStatus,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profileId?: string | null,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      logo?: string | null,
      active?: boolean | null,
      productType?: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type ListBusinessBrandssQueryVariables = {
  filter?: ModelBusinessBrandsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessBrandssQuery = {
  listBusinessBrandss?:  {
    __typename: "ModelBusinessBrandsConnection",
    items:  Array< {
      __typename: "BusinessBrands",
      id: string,
      businessId: string,
      brandId: string,
      status: BrandStatus,
      createdAt: string,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      profileId?: string | null,
      brand:  {
        __typename: "Brand",
        id: string,
        name: string,
        logo?: string | null,
        active?: boolean | null,
        productType?: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BusinessBrandsByBrandQueryVariables = {
  brandId?: string | null,
  businessId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBusinessBrandsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BusinessBrandsByBrandQuery = {
  businessBrandsByBrand?:  {
    __typename: "ModelBusinessBrandsConnection",
    items:  Array< {
      __typename: "BusinessBrands",
      id: string,
      businessId: string,
      brandId: string,
      status: BrandStatus,
      createdAt: string,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      profileId?: string | null,
      brand:  {
        __typename: "Brand",
        id: string,
        name: string,
        logo?: string | null,
        active?: boolean | null,
        productType?: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BusinessBrandsByBusinessQueryVariables = {
  businessId?: string | null,
  brandId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBusinessBrandsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BusinessBrandsByBusinessQuery = {
  businessBrandsByBusiness?:  {
    __typename: "ModelBusinessBrandsConnection",
    items:  Array< {
      __typename: "BusinessBrands",
      id: string,
      businessId: string,
      brandId: string,
      status: BrandStatus,
      createdAt: string,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      profileId?: string | null,
      brand:  {
        __typename: "Brand",
        id: string,
        name: string,
        logo?: string | null,
        active?: boolean | null,
        productType?: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBrandQueryVariables = {
  id: string,
};

export type GetBrandQuery = {
  getBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    logo?: string | null,
    active?: boolean | null,
    productType?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBrandsQueryVariables = {
  filter?: ModelBrandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrandsQuery = {
  listBrands?:  {
    __typename: "ModelBrandConnection",
    items:  Array< {
      __typename: "Brand",
      id: string,
      name: string,
      logo?: string | null,
      active?: boolean | null,
      productType?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchBrandsQueryVariables = {
  filter?: SearchableBrandFilterInput | null,
  sort?: SearchableBrandSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchBrandsQuery = {
  searchBrands?:  {
    __typename: "SearchableBrandConnection",
    items:  Array< {
      __typename: "Brand",
      id: string,
      name: string,
      logo?: string | null,
      active?: boolean | null,
      productType?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetExperienceQueryVariables = {
  id: string,
};

export type GetExperienceQuery = {
  getExperience?:  {
    __typename: "Experience",
    id: string,
    jobTitle?: string | null,
    businessId?: string | null,
    companyName: string,
    profileId: string,
    startDate?: string | null,
    endDate?: string | null,
    employmentType?: string | null,
    location?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListExperiencesQueryVariables = {
  filter?: ModelExperienceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExperiencesQuery = {
  listExperiences?:  {
    __typename: "ModelExperienceConnection",
    items:  Array< {
      __typename: "Experience",
      id: string,
      jobTitle?: string | null,
      businessId?: string | null,
      companyName: string,
      profileId: string,
      startDate?: string | null,
      endDate?: string | null,
      employmentType?: string | null,
      location?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPostQueryVariables = {
  id: string,
};

export type GetPostQuery = {
  getPost?:  {
    __typename: "Post",
    id: string,
    hubId: string,
    creatorId: string,
    content?: Array< string | null > | null,
    createdAt?: string | null,
    archived?: boolean | null,
    feedRegion: string,
    regions?: Array< string > | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelPostLikeConnection",
      items:  Array< {
        __typename: "PostLike",
        id: string,
        postId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListPostsQueryVariables = {
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostsQuery = {
  listPosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      hubId: string,
      creatorId: string,
      content?: Array< string | null > | null,
      createdAt?: string | null,
      archived?: boolean | null,
      feedRegion: string,
      regions?: Array< string > | null,
      attachments?:  Array< {
        __typename: "attachment",
        mediaType: string,
        source: string,
        alt?: string | null,
      } | null > | null,
      updatedAt: string,
      creator:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          postId?: string | null,
          commentId?: string | null,
          content?: Array< string | null > | null,
          creatorId: string,
          createdAt: string,
          archived?: boolean | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          post?:  {
            __typename: "Post",
            id: string,
            hubId: string,
            creatorId: string,
            content?: Array< string | null > | null,
            createdAt?: string | null,
            archived?: boolean | null,
            feedRegion: string,
            regions?: Array< string > | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelPostLikeConnection",
              items:  Array< {
                __typename: "PostLike",
                id: string,
                postId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelCommentLikeConnection",
            items:  Array< {
              __typename: "CommentLike",
              id: string,
              commentId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelPostLikeConnection",
        items:  Array< {
          __typename: "PostLike",
          id: string,
          postId: string,
          profileId: string,
          createdAt: string,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPostsQueryVariables = {
  filter?: SearchablePostFilterInput | null,
  sort?: SearchablePostSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchPostsQuery = {
  searchPosts?:  {
    __typename: "SearchablePostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      hubId: string,
      creatorId: string,
      content?: Array< string | null > | null,
      createdAt?: string | null,
      archived?: boolean | null,
      feedRegion: string,
      regions?: Array< string > | null,
      attachments?:  Array< {
        __typename: "attachment",
        mediaType: string,
        source: string,
        alt?: string | null,
      } | null > | null,
      updatedAt: string,
      creator:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          postId?: string | null,
          commentId?: string | null,
          content?: Array< string | null > | null,
          creatorId: string,
          createdAt: string,
          archived?: boolean | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          post?:  {
            __typename: "Post",
            id: string,
            hubId: string,
            creatorId: string,
            content?: Array< string | null > | null,
            createdAt?: string | null,
            archived?: boolean | null,
            feedRegion: string,
            regions?: Array< string > | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelPostLikeConnection",
              items:  Array< {
                __typename: "PostLike",
                id: string,
                postId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelCommentLikeConnection",
            items:  Array< {
              __typename: "CommentLike",
              id: string,
              commentId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelPostLikeConnection",
        items:  Array< {
          __typename: "PostLike",
          id: string,
          postId: string,
          profileId: string,
          createdAt: string,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetJobQueryVariables = {
  id: string,
};

export type GetJobQuery = {
  getJob?:  {
    __typename: "Job",
    id: string,
    owner?: string | null,
    jobType: string,
    businessId: string,
    jobRole: string,
    title: string,
    description?: Array< string | null > | null,
    salary?:  {
      __typename: "Salary",
      type: string,
      from?: string | null,
      to?: string | null,
    } | null,
    region: string,
    requireCV?: boolean | null,
    requireCoverLetter?: boolean | null,
    createdAt?: string | null,
    archived?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListJobsQueryVariables = {
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobsQuery = {
  listJobs?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      owner?: string | null,
      jobType: string,
      businessId: string,
      jobRole: string,
      title: string,
      description?: Array< string | null > | null,
      salary?:  {
        __typename: "Salary",
        type: string,
        from?: string | null,
        to?: string | null,
      } | null,
      region: string,
      requireCV?: boolean | null,
      requireCoverLetter?: boolean | null,
      createdAt?: string | null,
      archived?: boolean | null,
      startDate?: string | null,
      endDate?: string | null,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchJobsQueryVariables = {
  filter?: SearchableJobFilterInput | null,
  sort?: SearchableJobSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchJobsQuery = {
  searchJobs?:  {
    __typename: "SearchableJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      owner?: string | null,
      jobType: string,
      businessId: string,
      jobRole: string,
      title: string,
      description?: Array< string | null > | null,
      salary?:  {
        __typename: "Salary",
        type: string,
        from?: string | null,
        to?: string | null,
      } | null,
      region: string,
      requireCV?: boolean | null,
      requireCoverLetter?: boolean | null,
      createdAt?: string | null,
      archived?: boolean | null,
      startDate?: string | null,
      endDate?: string | null,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type ProfileJobsByProfileQueryVariables = {
  profileId?: string | null,
  jobId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProfileJobsByProfileQuery = {
  profileJobsByProfile?:  {
    __typename: "ModelProfileJobConnection",
    items:  Array< {
      __typename: "ProfileJob",
      id: string,
      profileId: string,
      jobId: string,
      coverLetter?: Array< string | null > | null,
      saved?: boolean | null,
      savedDate?: string | null,
      applied?: boolean | null,
      appliedDate?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      profile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      job:  {
        __typename: "Job",
        id: string,
        owner?: string | null,
        jobType: string,
        businessId: string,
        jobRole: string,
        title: string,
        description?: Array< string | null > | null,
        salary?:  {
          __typename: "Salary",
          type: string,
          from?: string | null,
          to?: string | null,
        } | null,
        region: string,
        requireCV?: boolean | null,
        requireCoverLetter?: boolean | null,
        createdAt?: string | null,
        archived?: boolean | null,
        startDate?: string | null,
        endDate?: string | null,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      rejected?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProfileJobsByJobQueryVariables = {
  jobId?: string | null,
  profileId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProfileJobsByJobQuery = {
  profileJobsByJob?:  {
    __typename: "ModelProfileJobConnection",
    items:  Array< {
      __typename: "ProfileJob",
      id: string,
      profileId: string,
      jobId: string,
      coverLetter?: Array< string | null > | null,
      saved?: boolean | null,
      savedDate?: string | null,
      applied?: boolean | null,
      appliedDate?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      profile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      job:  {
        __typename: "Job",
        id: string,
        owner?: string | null,
        jobType: string,
        businessId: string,
        jobRole: string,
        title: string,
        description?: Array< string | null > | null,
        salary?:  {
          __typename: "Salary",
          type: string,
          from?: string | null,
          to?: string | null,
        } | null,
        region: string,
        requireCV?: boolean | null,
        requireCoverLetter?: boolean | null,
        createdAt?: string | null,
        archived?: boolean | null,
        startDate?: string | null,
        endDate?: string | null,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      rejected?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchProfileJobsQueryVariables = {
  filter?: SearchableProfileJobFilterInput | null,
  sort?: SearchableProfileJobSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchProfileJobsQuery = {
  searchProfileJobs?:  {
    __typename: "SearchableProfileJobConnection",
    items:  Array< {
      __typename: "ProfileJob",
      id: string,
      profileId: string,
      jobId: string,
      coverLetter?: Array< string | null > | null,
      saved?: boolean | null,
      savedDate?: string | null,
      applied?: boolean | null,
      appliedDate?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      profile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      job:  {
        __typename: "Job",
        id: string,
        owner?: string | null,
        jobType: string,
        businessId: string,
        jobRole: string,
        title: string,
        description?: Array< string | null > | null,
        salary?:  {
          __typename: "Salary",
          type: string,
          from?: string | null,
          to?: string | null,
        } | null,
        region: string,
        requireCV?: boolean | null,
        requireCoverLetter?: boolean | null,
        createdAt?: string | null,
        archived?: boolean | null,
        startDate?: string | null,
        endDate?: string | null,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      rejected?: boolean | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetProfileJobQueryVariables = {
  id: string,
};

export type GetProfileJobQuery = {
  getProfileJob?:  {
    __typename: "ProfileJob",
    id: string,
    profileId: string,
    jobId: string,
    coverLetter?: Array< string | null > | null,
    saved?: boolean | null,
    savedDate?: string | null,
    applied?: boolean | null,
    appliedDate?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    job:  {
      __typename: "Job",
      id: string,
      owner?: string | null,
      jobType: string,
      businessId: string,
      jobRole: string,
      title: string,
      description?: Array< string | null > | null,
      salary?:  {
        __typename: "Salary",
        type: string,
        from?: string | null,
        to?: string | null,
      } | null,
      region: string,
      requireCV?: boolean | null,
      requireCoverLetter?: boolean | null,
      createdAt?: string | null,
      archived?: boolean | null,
      startDate?: string | null,
      endDate?: string | null,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    rejected?: boolean | null,
  } | null,
};

export type ListProfileJobsQueryVariables = {
  filter?: ModelProfileJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileJobsQuery = {
  listProfileJobs?:  {
    __typename: "ModelProfileJobConnection",
    items:  Array< {
      __typename: "ProfileJob",
      id: string,
      profileId: string,
      jobId: string,
      coverLetter?: Array< string | null > | null,
      saved?: boolean | null,
      savedDate?: string | null,
      applied?: boolean | null,
      appliedDate?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      profile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      job:  {
        __typename: "Job",
        id: string,
        owner?: string | null,
        jobType: string,
        businessId: string,
        jobRole: string,
        title: string,
        description?: Array< string | null > | null,
        salary?:  {
          __typename: "Salary",
          type: string,
          from?: string | null,
          to?: string | null,
        } | null,
        region: string,
        requireCV?: boolean | null,
        requireCoverLetter?: boolean | null,
        createdAt?: string | null,
        archived?: boolean | null,
        startDate?: string | null,
        endDate?: string | null,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      rejected?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    postId?: string | null,
    commentId?: string | null,
    content?: Array< string | null > | null,
    creatorId: string,
    createdAt: string,
    archived?: boolean | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    post?:  {
      __typename: "Post",
      id: string,
      hubId: string,
      creatorId: string,
      content?: Array< string | null > | null,
      createdAt?: string | null,
      archived?: boolean | null,
      feedRegion: string,
      regions?: Array< string > | null,
      attachments?:  Array< {
        __typename: "attachment",
        mediaType: string,
        source: string,
        alt?: string | null,
      } | null > | null,
      updatedAt: string,
      creator:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          postId?: string | null,
          commentId?: string | null,
          content?: Array< string | null > | null,
          creatorId: string,
          createdAt: string,
          archived?: boolean | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          post?:  {
            __typename: "Post",
            id: string,
            hubId: string,
            creatorId: string,
            content?: Array< string | null > | null,
            createdAt?: string | null,
            archived?: boolean | null,
            feedRegion: string,
            regions?: Array< string > | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelPostLikeConnection",
              items:  Array< {
                __typename: "PostLike",
                id: string,
                postId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelCommentLikeConnection",
            items:  Array< {
              __typename: "CommentLike",
              id: string,
              commentId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelPostLikeConnection",
        items:  Array< {
          __typename: "PostLike",
          id: string,
          postId: string,
          profileId: string,
          createdAt: string,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelCommentLikeConnection",
      items:  Array< {
        __typename: "CommentLike",
        id: string,
        commentId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      postId?: string | null,
      commentId?: string | null,
      content?: Array< string | null > | null,
      creatorId: string,
      createdAt: string,
      archived?: boolean | null,
      attachments?:  Array< {
        __typename: "attachment",
        mediaType: string,
        source: string,
        alt?: string | null,
      } | null > | null,
      updatedAt: string,
      creator:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      post?:  {
        __typename: "Post",
        id: string,
        hubId: string,
        creatorId: string,
        content?: Array< string | null > | null,
        createdAt?: string | null,
        archived?: boolean | null,
        feedRegion: string,
        regions?: Array< string > | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelPostLikeConnection",
          items:  Array< {
            __typename: "PostLike",
            id: string,
            postId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          postId?: string | null,
          commentId?: string | null,
          content?: Array< string | null > | null,
          creatorId: string,
          createdAt: string,
          archived?: boolean | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          post?:  {
            __typename: "Post",
            id: string,
            hubId: string,
            creatorId: string,
            content?: Array< string | null > | null,
            createdAt?: string | null,
            archived?: boolean | null,
            feedRegion: string,
            regions?: Array< string > | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelPostLikeConnection",
              items:  Array< {
                __typename: "PostLike",
                id: string,
                postId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelCommentLikeConnection",
            items:  Array< {
              __typename: "CommentLike",
              id: string,
              commentId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelCommentLikeConnection",
        items:  Array< {
          __typename: "CommentLike",
          id: string,
          commentId: string,
          profileId: string,
          createdAt: string,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByDateQueryVariables = {
  postId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByDateQuery = {
  commentsByDate?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      postId?: string | null,
      commentId?: string | null,
      content?: Array< string | null > | null,
      creatorId: string,
      createdAt: string,
      archived?: boolean | null,
      attachments?:  Array< {
        __typename: "attachment",
        mediaType: string,
        source: string,
        alt?: string | null,
      } | null > | null,
      updatedAt: string,
      creator:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      post?:  {
        __typename: "Post",
        id: string,
        hubId: string,
        creatorId: string,
        content?: Array< string | null > | null,
        createdAt?: string | null,
        archived?: boolean | null,
        feedRegion: string,
        regions?: Array< string > | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelPostLikeConnection",
          items:  Array< {
            __typename: "PostLike",
            id: string,
            postId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          postId?: string | null,
          commentId?: string | null,
          content?: Array< string | null > | null,
          creatorId: string,
          createdAt: string,
          archived?: boolean | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          post?:  {
            __typename: "Post",
            id: string,
            hubId: string,
            creatorId: string,
            content?: Array< string | null > | null,
            createdAt?: string | null,
            archived?: boolean | null,
            feedRegion: string,
            regions?: Array< string > | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelPostLikeConnection",
              items:  Array< {
                __typename: "PostLike",
                id: string,
                postId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelCommentLikeConnection",
            items:  Array< {
              __typename: "CommentLike",
              id: string,
              commentId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelCommentLikeConnection",
        items:  Array< {
          __typename: "CommentLike",
          id: string,
          commentId: string,
          profileId: string,
          createdAt: string,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportInfoQueryVariables = {
  id: string,
};

export type GetReportInfoQuery = {
  getReportInfo?:  {
    __typename: "ReportInfo",
    id: string,
    postId?: string | null,
    userId?: string | null,
    commentId?: string | null,
    jobId?: string | null,
    creatorId: string,
    createdAt: string,
    message: string,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListReportInfosQueryVariables = {
  filter?: ModelReportInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportInfosQuery = {
  listReportInfos?:  {
    __typename: "ModelReportInfoConnection",
    items:  Array< {
      __typename: "ReportInfo",
      id: string,
      postId?: string | null,
      userId?: string | null,
      commentId?: string | null,
      jobId?: string | null,
      creatorId: string,
      createdAt: string,
      message: string,
      updatedAt: string,
      creator:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPostLikeQueryVariables = {
  id: string,
};

export type GetPostLikeQuery = {
  getPostLike?:  {
    __typename: "PostLike",
    id: string,
    postId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListPostLikesQueryVariables = {
  filter?: ModelPostLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostLikesQuery = {
  listPostLikes?:  {
    __typename: "ModelPostLikeConnection",
    items:  Array< {
      __typename: "PostLike",
      id: string,
      postId: string,
      profileId: string,
      createdAt: string,
      updatedAt: string,
      profile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentLikeQueryVariables = {
  id: string,
};

export type GetCommentLikeQuery = {
  getCommentLike?:  {
    __typename: "CommentLike",
    id: string,
    commentId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListCommentLikesQueryVariables = {
  filter?: ModelCommentLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentLikesQuery = {
  listCommentLikes?:  {
    __typename: "ModelCommentLikeConnection",
    items:  Array< {
      __typename: "CommentLike",
      id: string,
      commentId: string,
      profileId: string,
      createdAt: string,
      updatedAt: string,
      profile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserNotificationQueryVariables = {
  id: string,
};

export type GetUserNotificationQuery = {
  getUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    receiverId: string,
    senderId?: string | null,
    type: NotificationType,
    targetId?: string | null,
    subTargetId?: string | null,
    additionalTargetId?: string | null,
    read: boolean,
    title?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListUserNotificationsQueryVariables = {
  filter?: ModelUserNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserNotificationsQuery = {
  listUserNotifications?:  {
    __typename: "ModelUserNotificationConnection",
    items:  Array< {
      __typename: "UserNotification",
      id: string,
      receiverId: string,
      senderId?: string | null,
      type: NotificationType,
      targetId?: string | null,
      subTargetId?: string | null,
      additionalTargetId?: string | null,
      read: boolean,
      title?: string | null,
      message?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      sender?:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserNotificationsByReceiverIdQueryVariables = {
  receiverId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserNotificationsByReceiverIdQuery = {
  userNotificationsByReceiverId?:  {
    __typename: "ModelUserNotificationConnection",
    items:  Array< {
      __typename: "UserNotification",
      id: string,
      receiverId: string,
      senderId?: string | null,
      type: NotificationType,
      targetId?: string | null,
      subTargetId?: string | null,
      additionalTargetId?: string | null,
      read: boolean,
      title?: string | null,
      message?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      sender?:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserNotificationsByDateQueryVariables = {
  receiverId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserNotificationsByDateQuery = {
  userNotificationsByDate?:  {
    __typename: "ModelUserNotificationConnection",
    items:  Array< {
      __typename: "UserNotification",
      id: string,
      receiverId: string,
      senderId?: string | null,
      type: NotificationType,
      targetId?: string | null,
      subTargetId?: string | null,
      additionalTargetId?: string | null,
      read: boolean,
      title?: string | null,
      message?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      sender?:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPublicEmailQueryVariables = {
  id: string,
};

export type GetPublicEmailQuery = {
  getPublicEmail?:  {
    __typename: "PublicEmail",
    id: string,
    email: string,
    allowMarketing: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPublicEmailsQueryVariables = {
  filter?: ModelPublicEmailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPublicEmailsQuery = {
  listPublicEmails?:  {
    __typename: "ModelPublicEmailConnection",
    items:  Array< {
      __typename: "PublicEmail",
      id: string,
      email: string,
      allowMarketing: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTipSplitQueryVariables = {
  id: string,
};

export type GetTipSplitQuery = {
  getTipSplit?:  {
    __typename: "TipSplit",
    id: string,
    amount: number,
    owner: string,
    status: TransactionStatus,
    from: string,
    to: string,
    createdAt: string,
    updatedAt: string,
    ownerProfile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    transfers?:  {
      __typename: "ModelEmployeeTransferConnection",
      items:  Array< {
        __typename: "EmployeeTransfer",
        id: string,
        splitId: string,
        employeeId: string,
        amount: number,
        status: TransactionStatus,
        createdAt: string,
        updatedAt: string,
        employee?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListTipSplitsQueryVariables = {
  filter?: ModelTipSplitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTipSplitsQuery = {
  listTipSplits?:  {
    __typename: "ModelTipSplitConnection",
    items:  Array< {
      __typename: "TipSplit",
      id: string,
      amount: number,
      owner: string,
      status: TransactionStatus,
      from: string,
      to: string,
      createdAt: string,
      updatedAt: string,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      transfers?:  {
        __typename: "ModelEmployeeTransferConnection",
        items:  Array< {
          __typename: "EmployeeTransfer",
          id: string,
          splitId: string,
          employeeId: string,
          amount: number,
          status: TransactionStatus,
          createdAt: string,
          updatedAt: string,
          employee?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactions?:  {
        __typename: "ModelTipTransactionConnection",
        items:  Array< {
          __typename: "TipTransaction",
          id: string,
          splitId?: string | null,
          receiverId: string,
          amount: string,
          fee: string,
          currency: string,
          createdAt: string,
          status: TransactionStatus,
          type: TippingType,
          source: TipTransactionSource,
          payoutInvoiceId?: string | null,
          transactionId?: string | null,
          updatedAt: string,
          receiver?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchTipSplitsQueryVariables = {
  filter?: SearchableTipSplitFilterInput | null,
  sort?: SearchableTipSplitSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchTipSplitsQuery = {
  searchTipSplits?:  {
    __typename: "SearchableTipSplitConnection",
    items:  Array< {
      __typename: "TipSplit",
      id: string,
      amount: number,
      owner: string,
      status: TransactionStatus,
      from: string,
      to: string,
      createdAt: string,
      updatedAt: string,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      transfers?:  {
        __typename: "ModelEmployeeTransferConnection",
        items:  Array< {
          __typename: "EmployeeTransfer",
          id: string,
          splitId: string,
          employeeId: string,
          amount: number,
          status: TransactionStatus,
          createdAt: string,
          updatedAt: string,
          employee?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactions?:  {
        __typename: "ModelTipTransactionConnection",
        items:  Array< {
          __typename: "TipTransaction",
          id: string,
          splitId?: string | null,
          receiverId: string,
          amount: string,
          fee: string,
          currency: string,
          createdAt: string,
          status: TransactionStatus,
          type: TippingType,
          source: TipTransactionSource,
          payoutInvoiceId?: string | null,
          transactionId?: string | null,
          updatedAt: string,
          receiver?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetEmployeeTransferQueryVariables = {
  id: string,
};

export type GetEmployeeTransferQuery = {
  getEmployeeTransfer?:  {
    __typename: "EmployeeTransfer",
    id: string,
    splitId: string,
    employeeId: string,
    amount: number,
    status: TransactionStatus,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListEmployeeTransfersQueryVariables = {
  filter?: ModelEmployeeTransferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmployeeTransfersQuery = {
  listEmployeeTransfers?:  {
    __typename: "ModelEmployeeTransferConnection",
    items:  Array< {
      __typename: "EmployeeTransfer",
      id: string,
      splitId: string,
      employeeId: string,
      amount: number,
      status: TransactionStatus,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TransferByEmployeeQueryVariables = {
  employeeId?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEmployeeTransferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransferByEmployeeQuery = {
  transferByEmployee?:  {
    __typename: "ModelEmployeeTransferConnection",
    items:  Array< {
      __typename: "EmployeeTransfer",
      id: string,
      splitId: string,
      employeeId: string,
      amount: number,
      status: TransactionStatus,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTipTransactionQueryVariables = {
  id: string,
};

export type GetTipTransactionQuery = {
  getTipTransaction?:  {
    __typename: "TipTransaction",
    id: string,
    splitId?: string | null,
    receiverId: string,
    amount: string,
    fee: string,
    currency: string,
    createdAt: string,
    status: TransactionStatus,
    type: TippingType,
    source: TipTransactionSource,
    payoutInvoiceId?: string | null,
    transactionId?: string | null,
    updatedAt: string,
    receiver?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListTipTransactionsQueryVariables = {
  filter?: ModelTipTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTipTransactionsQuery = {
  listTipTransactions?:  {
    __typename: "ModelTipTransactionConnection",
    items:  Array< {
      __typename: "TipTransaction",
      id: string,
      splitId?: string | null,
      receiverId: string,
      amount: string,
      fee: string,
      currency: string,
      createdAt: string,
      status: TransactionStatus,
      type: TippingType,
      source: TipTransactionSource,
      payoutInvoiceId?: string | null,
      transactionId?: string | null,
      updatedAt: string,
      receiver?:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchTipTransactionsQueryVariables = {
  filter?: SearchableTipTransactionFilterInput | null,
  sort?: SearchableTipTransactionSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchTipTransactionsQuery = {
  searchTipTransactions?:  {
    __typename: "SearchableTipTransactionConnection",
    items:  Array< {
      __typename: "TipTransaction",
      id: string,
      splitId?: string | null,
      receiverId: string,
      amount: string,
      fee: string,
      currency: string,
      createdAt: string,
      status: TransactionStatus,
      type: TippingType,
      source: TipTransactionSource,
      payoutInvoiceId?: string | null,
      transactionId?: string | null,
      updatedAt: string,
      receiver?:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetBusinessSetupTippingInviteQueryVariables = {
  id: string,
};

export type GetBusinessSetupTippingInviteQuery = {
  getBusinessSetupTippingInvite?:  {
    __typename: "BusinessSetupTippingInvite",
    id: string,
    sender: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBusinessSetupTippingInvitesQueryVariables = {
  filter?: ModelBusinessSetupTippingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessSetupTippingInvitesQuery = {
  listBusinessSetupTippingInvites?:  {
    __typename: "ModelBusinessSetupTippingInviteConnection",
    items:  Array< {
      __typename: "BusinessSetupTippingInvite",
      id: string,
      sender: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BusinessSetupTippingInviteBySenderQueryVariables = {
  sender?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBusinessSetupTippingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BusinessSetupTippingInviteBySenderQuery = {
  businessSetupTippingInviteBySender?:  {
    __typename: "ModelBusinessSetupTippingInviteConnection",
    items:  Array< {
      __typename: "BusinessSetupTippingInvite",
      id: string,
      sender: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserMessageQueryVariables = {
  id: string,
};

export type GetUserMessageQuery = {
  getUserMessage?:  {
    __typename: "UserMessage",
    id: string,
    senderId: string,
    receiverId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListUserMessagesQueryVariables = {
  filter?: ModelUserMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserMessagesQuery = {
  listUserMessages?:  {
    __typename: "ModelUserMessageConnection",
    items:  Array< {
      __typename: "UserMessage",
      id: string,
      senderId: string,
      receiverId: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      sender?:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserPhoneValidationQueryVariables = {
  id: string,
};

export type GetUserPhoneValidationQuery = {
  getUserPhoneValidation?:  {
    __typename: "UserPhoneValidation",
    id: string,
    userId: string,
    phoneNumber: string,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserPhoneValidationsQueryVariables = {
  filter?: ModelUserPhoneValidationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserPhoneValidationsQuery = {
  listUserPhoneValidations?:  {
    __typename: "ModelUserPhoneValidationConnection",
    items:  Array< {
      __typename: "UserPhoneValidation",
      id: string,
      userId: string,
      phoneNumber: string,
      code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PhoneValidationByUserPhoneQueryVariables = {
  userId?: string | null,
  phoneNumber?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserPhoneValidationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PhoneValidationByUserPhoneQuery = {
  phoneValidationByUserPhone?:  {
    __typename: "ModelUserPhoneValidationConnection",
    items:  Array< {
      __typename: "UserPhoneValidation",
      id: string,
      userId: string,
      phoneNumber: string,
      code: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPayoutInvoiceQueryVariables = {
  id: string,
};

export type GetPayoutInvoiceQuery = {
  getPayoutInvoice?:  {
    __typename: "PayoutInvoice",
    id: string,
    createdAt: string,
    file: string,
    amount: number,
    fee: number,
    currency: string,
    status: PayoutStatus,
    payedAt?: string | null,
    updatedAt: string,
    tipTransactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListPayoutInvoicesQueryVariables = {
  filter?: ModelPayoutInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPayoutInvoicesQuery = {
  listPayoutInvoices?:  {
    __typename: "ModelPayoutInvoiceConnection",
    items:  Array< {
      __typename: "PayoutInvoice",
      id: string,
      createdAt: string,
      file: string,
      amount: number,
      fee: number,
      currency: string,
      status: PayoutStatus,
      payedAt?: string | null,
      updatedAt: string,
      tipTransactions?:  {
        __typename: "ModelTipTransactionConnection",
        items:  Array< {
          __typename: "TipTransaction",
          id: string,
          splitId?: string | null,
          receiverId: string,
          amount: string,
          fee: string,
          currency: string,
          createdAt: string,
          status: TransactionStatus,
          type: TippingType,
          source: TipTransactionSource,
          payoutInvoiceId?: string | null,
          transactionId?: string | null,
          updatedAt: string,
          receiver?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnUserNotificationCreateForUserSubscriptionVariables = {
  receiverId: string,
};

export type OnUserNotificationCreateForUserSubscription = {
  onUserNotificationCreateForUser?:  {
    __typename: "UserNotification",
    id: string,
    receiverId: string,
    senderId?: string | null,
    type: NotificationType,
    targetId?: string | null,
    subTargetId?: string | null,
    additionalTargetId?: string | null,
    read: boolean,
    title?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateBusinessStatisticsSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateBusinessStatisticsSubscription = {
  onCreateBusinessStatistics?:  {
    __typename: "BusinessStatistics",
    id: string,
    data?:  Array< {
      __typename: "BusinessStatisticsInfo",
      date: string,
      totalTips: number,
      totalTipsCount: number,
    } | null > | null,
    updatedAt: string,
    owner: string,
    lastSplitDate?: string | null,
    createdAt: string,
  } | null,
};

export type OnUpdateBusinessStatisticsSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateBusinessStatisticsSubscription = {
  onUpdateBusinessStatistics?:  {
    __typename: "BusinessStatistics",
    id: string,
    data?:  Array< {
      __typename: "BusinessStatisticsInfo",
      date: string,
      totalTips: number,
      totalTipsCount: number,
    } | null > | null,
    updatedAt: string,
    owner: string,
    lastSplitDate?: string | null,
    createdAt: string,
  } | null,
};

export type OnDeleteBusinessStatisticsSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteBusinessStatisticsSubscription = {
  onDeleteBusinessStatistics?:  {
    __typename: "BusinessStatistics",
    id: string,
    data?:  Array< {
      __typename: "BusinessStatisticsInfo",
      date: string,
      totalTips: number,
      totalTipsCount: number,
    } | null > | null,
    updatedAt: string,
    owner: string,
    lastSplitDate?: string | null,
    createdAt: string,
  } | null,
};

export type OnCreateBusinessDetailsSubscriptionVariables = {
};

export type OnCreateBusinessDetailsSubscription = {
  onCreateBusinessDetails?:  {
    __typename: "BusinessDetails",
    id: string,
    contactName?: string | null,
    jobRole?: string | null,
    phone?: string | null,
    email?: string | null,
    website?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBusinessDetailsSubscriptionVariables = {
};

export type OnUpdateBusinessDetailsSubscription = {
  onUpdateBusinessDetails?:  {
    __typename: "BusinessDetails",
    id: string,
    contactName?: string | null,
    jobRole?: string | null,
    phone?: string | null,
    email?: string | null,
    website?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBusinessDetailsSubscriptionVariables = {
};

export type OnDeleteBusinessDetailsSubscription = {
  onDeleteBusinessDetails?:  {
    __typename: "BusinessDetails",
    id: string,
    contactName?: string | null,
    jobRole?: string | null,
    phone?: string | null,
    email?: string | null,
    website?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProfileSubscriptionVariables = {
};

export type OnCreateProfileSubscription = {
  onCreateProfile?:  {
    __typename: "Profile",
    id: string,
    slug?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    bio?: Array< string | null > | null,
    picture?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    archived?: boolean | null,
    details?:  {
      __typename: "ProfileDetails",
      id: string,
      accountType?: string | null,
      isOnboardingComplete?: boolean | null,
      email?: string | null,
      feedRegion?: string | null,
      tippingAccount?:  {
        __typename: "TippingAccount",
        code?: string | null,
        merchant?: Merchant | null,
        accountId?: string | null,
        verified?: boolean | null,
        missingRequirements?:  Array< {
          __typename: "MissingRequirement",
          key?: string | null,
          description?: string | null,
        } | null > | null,
        pendingValidation?: Array< string | null > | null,
        bankDetails?:  {
          __typename: "BankDetails",
          name?: string | null,
          sortCode?: string | null,
          last4Digits?: string | null,
        } | null,
      } | null,
      phone_number?: string | null,
      phoneNumberConfirmed?: boolean | null,
      pushNotificationToken?: string | null,
      pushNotificationReadDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employers?:  {
      __typename: "ModelBusinessEmployeeConnection",
      items:  Array< {
        __typename: "BusinessEmployee",
        id: string,
        jobRole: string,
        businessId: string,
        profileId: string,
        employeeId?: string | null,
        tippingPolicyAcknowledgedDate?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        confirmed?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        jobTitle?: string | null,
        businessId?: string | null,
        companyName: string,
        profileId: string,
        startDate?: string | null,
        endDate?: string | null,
        employmentType?: string | null,
        location?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        profile?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelProfileJobConnection",
      items:  Array< {
        __typename: "ProfileJob",
        id: string,
        profileId: string,
        jobId: string,
        coverLetter?: Array< string | null > | null,
        saved?: boolean | null,
        savedDate?: string | null,
        applied?: boolean | null,
        appliedDate?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        job:  {
          __typename: "Job",
          id: string,
          owner?: string | null,
          jobType: string,
          businessId: string,
          jobRole: string,
          title: string,
          description?: Array< string | null > | null,
          salary?:  {
            __typename: "Salary",
            type: string,
            from?: string | null,
            to?: string | null,
          } | null,
          region: string,
          requireCV?: boolean | null,
          requireCoverLetter?: boolean | null,
          createdAt?: string | null,
          archived?: boolean | null,
          startDate?: string | null,
          endDate?: string | null,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        rejected?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateProfileSubscriptionVariables = {
};

export type OnUpdateProfileSubscription = {
  onUpdateProfile?:  {
    __typename: "Profile",
    id: string,
    slug?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    bio?: Array< string | null > | null,
    picture?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    archived?: boolean | null,
    details?:  {
      __typename: "ProfileDetails",
      id: string,
      accountType?: string | null,
      isOnboardingComplete?: boolean | null,
      email?: string | null,
      feedRegion?: string | null,
      tippingAccount?:  {
        __typename: "TippingAccount",
        code?: string | null,
        merchant?: Merchant | null,
        accountId?: string | null,
        verified?: boolean | null,
        missingRequirements?:  Array< {
          __typename: "MissingRequirement",
          key?: string | null,
          description?: string | null,
        } | null > | null,
        pendingValidation?: Array< string | null > | null,
        bankDetails?:  {
          __typename: "BankDetails",
          name?: string | null,
          sortCode?: string | null,
          last4Digits?: string | null,
        } | null,
      } | null,
      phone_number?: string | null,
      phoneNumberConfirmed?: boolean | null,
      pushNotificationToken?: string | null,
      pushNotificationReadDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employers?:  {
      __typename: "ModelBusinessEmployeeConnection",
      items:  Array< {
        __typename: "BusinessEmployee",
        id: string,
        jobRole: string,
        businessId: string,
        profileId: string,
        employeeId?: string | null,
        tippingPolicyAcknowledgedDate?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        confirmed?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        jobTitle?: string | null,
        businessId?: string | null,
        companyName: string,
        profileId: string,
        startDate?: string | null,
        endDate?: string | null,
        employmentType?: string | null,
        location?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        profile?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelProfileJobConnection",
      items:  Array< {
        __typename: "ProfileJob",
        id: string,
        profileId: string,
        jobId: string,
        coverLetter?: Array< string | null > | null,
        saved?: boolean | null,
        savedDate?: string | null,
        applied?: boolean | null,
        appliedDate?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        job:  {
          __typename: "Job",
          id: string,
          owner?: string | null,
          jobType: string,
          businessId: string,
          jobRole: string,
          title: string,
          description?: Array< string | null > | null,
          salary?:  {
            __typename: "Salary",
            type: string,
            from?: string | null,
            to?: string | null,
          } | null,
          region: string,
          requireCV?: boolean | null,
          requireCoverLetter?: boolean | null,
          createdAt?: string | null,
          archived?: boolean | null,
          startDate?: string | null,
          endDate?: string | null,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        rejected?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteProfileSubscriptionVariables = {
};

export type OnDeleteProfileSubscription = {
  onDeleteProfile?:  {
    __typename: "Profile",
    id: string,
    slug?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    bio?: Array< string | null > | null,
    picture?: string | null,
    location?:  {
      __typename: "Location",
      searchName?: string | null,
      address?: string | null,
      address2?: string | null,
      city?: string | null,
      region?: string | null,
      postcode?: string | null,
      country?: string | null,
      latitude?: string | null,
      longitude?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    archived?: boolean | null,
    details?:  {
      __typename: "ProfileDetails",
      id: string,
      accountType?: string | null,
      isOnboardingComplete?: boolean | null,
      email?: string | null,
      feedRegion?: string | null,
      tippingAccount?:  {
        __typename: "TippingAccount",
        code?: string | null,
        merchant?: Merchant | null,
        accountId?: string | null,
        verified?: boolean | null,
        missingRequirements?:  Array< {
          __typename: "MissingRequirement",
          key?: string | null,
          description?: string | null,
        } | null > | null,
        pendingValidation?: Array< string | null > | null,
        bankDetails?:  {
          __typename: "BankDetails",
          name?: string | null,
          sortCode?: string | null,
          last4Digits?: string | null,
        } | null,
      } | null,
      phone_number?: string | null,
      phoneNumberConfirmed?: boolean | null,
      pushNotificationToken?: string | null,
      pushNotificationReadDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employers?:  {
      __typename: "ModelBusinessEmployeeConnection",
      items:  Array< {
        __typename: "BusinessEmployee",
        id: string,
        jobRole: string,
        businessId: string,
        profileId: string,
        employeeId?: string | null,
        tippingPolicyAcknowledgedDate?: string | null,
        createdAt: string,
        updatedAt: string,
        business:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        confirmed?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    experiences?:  {
      __typename: "ModelExperienceConnection",
      items:  Array< {
        __typename: "Experience",
        id: string,
        jobTitle?: string | null,
        businessId?: string | null,
        companyName: string,
        profileId: string,
        startDate?: string | null,
        endDate?: string | null,
        employmentType?: string | null,
        location?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        profile?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelProfileJobConnection",
      items:  Array< {
        __typename: "ProfileJob",
        id: string,
        profileId: string,
        jobId: string,
        coverLetter?: Array< string | null > | null,
        saved?: boolean | null,
        savedDate?: string | null,
        applied?: boolean | null,
        appliedDate?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        job:  {
          __typename: "Job",
          id: string,
          owner?: string | null,
          jobType: string,
          businessId: string,
          jobRole: string,
          title: string,
          description?: Array< string | null > | null,
          salary?:  {
            __typename: "Salary",
            type: string,
            from?: string | null,
            to?: string | null,
          } | null,
          region: string,
          requireCV?: boolean | null,
          requireCoverLetter?: boolean | null,
          createdAt?: string | null,
          archived?: boolean | null,
          startDate?: string | null,
          endDate?: string | null,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        rejected?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateProfileDetailsSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateProfileDetailsSubscription = {
  onCreateProfileDetails?:  {
    __typename: "ProfileDetails",
    id: string,
    accountType?: string | null,
    isOnboardingComplete?: boolean | null,
    email?: string | null,
    feedRegion?: string | null,
    tippingAccount?:  {
      __typename: "TippingAccount",
      code?: string | null,
      merchant?: Merchant | null,
      accountId?: string | null,
      verified?: boolean | null,
      missingRequirements?:  Array< {
        __typename: "MissingRequirement",
        key?: string | null,
        description?: string | null,
      } | null > | null,
      pendingValidation?: Array< string | null > | null,
      bankDetails?:  {
        __typename: "BankDetails",
        name?: string | null,
        sortCode?: string | null,
        last4Digits?: string | null,
      } | null,
    } | null,
    phone_number?: string | null,
    phoneNumberConfirmed?: boolean | null,
    pushNotificationToken?: string | null,
    pushNotificationReadDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProfileDetailsSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateProfileDetailsSubscription = {
  onUpdateProfileDetails?:  {
    __typename: "ProfileDetails",
    id: string,
    accountType?: string | null,
    isOnboardingComplete?: boolean | null,
    email?: string | null,
    feedRegion?: string | null,
    tippingAccount?:  {
      __typename: "TippingAccount",
      code?: string | null,
      merchant?: Merchant | null,
      accountId?: string | null,
      verified?: boolean | null,
      missingRequirements?:  Array< {
        __typename: "MissingRequirement",
        key?: string | null,
        description?: string | null,
      } | null > | null,
      pendingValidation?: Array< string | null > | null,
      bankDetails?:  {
        __typename: "BankDetails",
        name?: string | null,
        sortCode?: string | null,
        last4Digits?: string | null,
      } | null,
    } | null,
    phone_number?: string | null,
    phoneNumberConfirmed?: boolean | null,
    pushNotificationToken?: string | null,
    pushNotificationReadDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProfileDetailsSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteProfileDetailsSubscription = {
  onDeleteProfileDetails?:  {
    __typename: "ProfileDetails",
    id: string,
    accountType?: string | null,
    isOnboardingComplete?: boolean | null,
    email?: string | null,
    feedRegion?: string | null,
    tippingAccount?:  {
      __typename: "TippingAccount",
      code?: string | null,
      merchant?: Merchant | null,
      accountId?: string | null,
      verified?: boolean | null,
      missingRequirements?:  Array< {
        __typename: "MissingRequirement",
        key?: string | null,
        description?: string | null,
      } | null > | null,
      pendingValidation?: Array< string | null > | null,
      bankDetails?:  {
        __typename: "BankDetails",
        name?: string | null,
        sortCode?: string | null,
        last4Digits?: string | null,
      } | null,
    } | null,
    phone_number?: string | null,
    phoneNumberConfirmed?: boolean | null,
    pushNotificationToken?: string | null,
    pushNotificationReadDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBusinessInviteSubscriptionVariables = {
  businessId?: string | null,
};

export type OnCreateBusinessInviteSubscription = {
  onCreateBusinessInvite?:  {
    __typename: "BusinessInvite",
    id: string,
    businessId: string,
    receiver?: string | null,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateBusinessInviteSubscriptionVariables = {
  businessId?: string | null,
};

export type OnUpdateBusinessInviteSubscription = {
  onUpdateBusinessInvite?:  {
    __typename: "BusinessInvite",
    id: string,
    businessId: string,
    receiver?: string | null,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteBusinessInviteSubscriptionVariables = {
  businessId?: string | null,
};

export type OnDeleteBusinessInviteSubscription = {
  onDeleteBusinessInvite?:  {
    __typename: "BusinessInvite",
    id: string,
    businessId: string,
    receiver?: string | null,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateBusinessEmployeeSubscriptionVariables = {
  businessId?: string | null,
};

export type OnCreateBusinessEmployeeSubscription = {
  onCreateBusinessEmployee?:  {
    __typename: "BusinessEmployee",
    id: string,
    jobRole: string,
    businessId: string,
    profileId: string,
    employeeId?: string | null,
    tippingPolicyAcknowledgedDate?: string | null,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    confirmed?: boolean | null,
  } | null,
};

export type OnUpdateBusinessEmployeeSubscriptionVariables = {
  businessId?: string | null,
};

export type OnUpdateBusinessEmployeeSubscription = {
  onUpdateBusinessEmployee?:  {
    __typename: "BusinessEmployee",
    id: string,
    jobRole: string,
    businessId: string,
    profileId: string,
    employeeId?: string | null,
    tippingPolicyAcknowledgedDate?: string | null,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    confirmed?: boolean | null,
  } | null,
};

export type OnDeleteBusinessEmployeeSubscriptionVariables = {
  businessId?: string | null,
};

export type OnDeleteBusinessEmployeeSubscription = {
  onDeleteBusinessEmployee?:  {
    __typename: "BusinessEmployee",
    id: string,
    jobRole: string,
    businessId: string,
    profileId: string,
    employeeId?: string | null,
    tippingPolicyAcknowledgedDate?: string | null,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    confirmed?: boolean | null,
  } | null,
};

export type OnCreateBusinessBrandsSubscriptionVariables = {
  profileId?: string | null,
};

export type OnCreateBusinessBrandsSubscription = {
  onCreateBusinessBrands?:  {
    __typename: "BusinessBrands",
    id: string,
    businessId: string,
    brandId: string,
    status: BrandStatus,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profileId?: string | null,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      logo?: string | null,
      active?: boolean | null,
      productType?: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnUpdateBusinessBrandsSubscriptionVariables = {
  profileId?: string | null,
};

export type OnUpdateBusinessBrandsSubscription = {
  onUpdateBusinessBrands?:  {
    __typename: "BusinessBrands",
    id: string,
    businessId: string,
    brandId: string,
    status: BrandStatus,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profileId?: string | null,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      logo?: string | null,
      active?: boolean | null,
      productType?: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnDeleteBusinessBrandsSubscriptionVariables = {
  profileId?: string | null,
};

export type OnDeleteBusinessBrandsSubscription = {
  onDeleteBusinessBrands?:  {
    __typename: "BusinessBrands",
    id: string,
    businessId: string,
    brandId: string,
    status: BrandStatus,
    createdAt: string,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    profileId?: string | null,
    brand:  {
      __typename: "Brand",
      id: string,
      name: string,
      logo?: string | null,
      active?: boolean | null,
      productType?: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnCreateBrandSubscriptionVariables = {
};

export type OnCreateBrandSubscription = {
  onCreateBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    logo?: string | null,
    active?: boolean | null,
    productType?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBrandSubscriptionVariables = {
};

export type OnUpdateBrandSubscription = {
  onUpdateBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    logo?: string | null,
    active?: boolean | null,
    productType?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBrandSubscriptionVariables = {
};

export type OnDeleteBrandSubscription = {
  onDeleteBrand?:  {
    __typename: "Brand",
    id: string,
    name: string,
    logo?: string | null,
    active?: boolean | null,
    productType?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateExperienceSubscriptionVariables = {
  profileId?: string | null,
};

export type OnCreateExperienceSubscription = {
  onCreateExperience?:  {
    __typename: "Experience",
    id: string,
    jobTitle?: string | null,
    businessId?: string | null,
    companyName: string,
    profileId: string,
    startDate?: string | null,
    endDate?: string | null,
    employmentType?: string | null,
    location?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateExperienceSubscriptionVariables = {
  profileId?: string | null,
};

export type OnUpdateExperienceSubscription = {
  onUpdateExperience?:  {
    __typename: "Experience",
    id: string,
    jobTitle?: string | null,
    businessId?: string | null,
    companyName: string,
    profileId: string,
    startDate?: string | null,
    endDate?: string | null,
    employmentType?: string | null,
    location?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteExperienceSubscriptionVariables = {
  profileId?: string | null,
};

export type OnDeleteExperienceSubscription = {
  onDeleteExperience?:  {
    __typename: "Experience",
    id: string,
    jobTitle?: string | null,
    businessId?: string | null,
    companyName: string,
    profileId: string,
    startDate?: string | null,
    endDate?: string | null,
    employmentType?: string | null,
    location?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    business?:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreatePostSubscriptionVariables = {
  creatorId?: string | null,
};

export type OnCreatePostSubscription = {
  onCreatePost?:  {
    __typename: "Post",
    id: string,
    hubId: string,
    creatorId: string,
    content?: Array< string | null > | null,
    createdAt?: string | null,
    archived?: boolean | null,
    feedRegion: string,
    regions?: Array< string > | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelPostLikeConnection",
      items:  Array< {
        __typename: "PostLike",
        id: string,
        postId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdatePostSubscriptionVariables = {
  creatorId?: string | null,
};

export type OnUpdatePostSubscription = {
  onUpdatePost?:  {
    __typename: "Post",
    id: string,
    hubId: string,
    creatorId: string,
    content?: Array< string | null > | null,
    createdAt?: string | null,
    archived?: boolean | null,
    feedRegion: string,
    regions?: Array< string > | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelPostLikeConnection",
      items:  Array< {
        __typename: "PostLike",
        id: string,
        postId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeletePostSubscriptionVariables = {
  creatorId?: string | null,
};

export type OnDeletePostSubscription = {
  onDeletePost?:  {
    __typename: "Post",
    id: string,
    hubId: string,
    creatorId: string,
    content?: Array< string | null > | null,
    createdAt?: string | null,
    archived?: boolean | null,
    feedRegion: string,
    regions?: Array< string > | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelPostLikeConnection",
      items:  Array< {
        __typename: "PostLike",
        id: string,
        postId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateJobSubscriptionVariables = {
};

export type OnCreateJobSubscription = {
  onCreateJob?:  {
    __typename: "Job",
    id: string,
    owner?: string | null,
    jobType: string,
    businessId: string,
    jobRole: string,
    title: string,
    description?: Array< string | null > | null,
    salary?:  {
      __typename: "Salary",
      type: string,
      from?: string | null,
      to?: string | null,
    } | null,
    region: string,
    requireCV?: boolean | null,
    requireCoverLetter?: boolean | null,
    createdAt?: string | null,
    archived?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateJobSubscriptionVariables = {
};

export type OnUpdateJobSubscription = {
  onUpdateJob?:  {
    __typename: "Job",
    id: string,
    owner?: string | null,
    jobType: string,
    businessId: string,
    jobRole: string,
    title: string,
    description?: Array< string | null > | null,
    salary?:  {
      __typename: "Salary",
      type: string,
      from?: string | null,
      to?: string | null,
    } | null,
    region: string,
    requireCV?: boolean | null,
    requireCoverLetter?: boolean | null,
    createdAt?: string | null,
    archived?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteJobSubscriptionVariables = {
};

export type OnDeleteJobSubscription = {
  onDeleteJob?:  {
    __typename: "Job",
    id: string,
    owner?: string | null,
    jobType: string,
    businessId: string,
    jobRole: string,
    title: string,
    description?: Array< string | null > | null,
    salary?:  {
      __typename: "Salary",
      type: string,
      from?: string | null,
      to?: string | null,
    } | null,
    region: string,
    requireCV?: boolean | null,
    requireCoverLetter?: boolean | null,
    createdAt?: string | null,
    archived?: boolean | null,
    startDate?: string | null,
    endDate?: string | null,
    updatedAt: string,
    business:  {
      __typename: "Business",
      id: string,
      verified: boolean,
      name: string,
      tipping?:  {
        __typename: "BusinessTippingSetting",
        merchantId?: string | null,
        policy?: string | null,
        type?: TippingType | null,
        active?: boolean | null,
      } | null,
      marketing?:  {
        __typename: "BusinessMarketingSetting",
        customerId?: string | null,
        subscriptionId?: string | null,
        active?: boolean | null,
        expirationDate?: string | null,
        sequenceId?: string | null,
        cancelled?: boolean | null,
        cancelledAt?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      code: string,
      answers?:  Array< {
        __typename: "Answer",
        questionId?: string | null,
        value?: Array< string | null > | null,
      } | null > | null,
      isOutlet?: boolean | null,
      details?:  {
        __typename: "BusinessDetails",
        id: string,
        contactName?: string | null,
        jobRole?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerProfile:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      invites?:  {
        __typename: "ModelBusinessInviteConnection",
        items:  Array< {
          __typename: "BusinessInvite",
          id: string,
          businessId: string,
          receiver?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      brands?:  {
        __typename: "ModelBusinessBrandsConnection",
        items:  Array< {
          __typename: "BusinessBrands",
          id: string,
          businessId: string,
          brandId: string,
          status: BrandStatus,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profileId?: string | null,
          brand:  {
            __typename: "Brand",
            id: string,
            name: string,
            logo?: string | null,
            active?: boolean | null,
            productType?: string | null,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateProfileJobSubscriptionVariables = {
  profileId?: string | null,
};

export type OnCreateProfileJobSubscription = {
  onCreateProfileJob?:  {
    __typename: "ProfileJob",
    id: string,
    profileId: string,
    jobId: string,
    coverLetter?: Array< string | null > | null,
    saved?: boolean | null,
    savedDate?: string | null,
    applied?: boolean | null,
    appliedDate?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    job:  {
      __typename: "Job",
      id: string,
      owner?: string | null,
      jobType: string,
      businessId: string,
      jobRole: string,
      title: string,
      description?: Array< string | null > | null,
      salary?:  {
        __typename: "Salary",
        type: string,
        from?: string | null,
        to?: string | null,
      } | null,
      region: string,
      requireCV?: boolean | null,
      requireCoverLetter?: boolean | null,
      createdAt?: string | null,
      archived?: boolean | null,
      startDate?: string | null,
      endDate?: string | null,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    rejected?: boolean | null,
  } | null,
};

export type OnUpdateProfileJobSubscriptionVariables = {
  profileId?: string | null,
};

export type OnUpdateProfileJobSubscription = {
  onUpdateProfileJob?:  {
    __typename: "ProfileJob",
    id: string,
    profileId: string,
    jobId: string,
    coverLetter?: Array< string | null > | null,
    saved?: boolean | null,
    savedDate?: string | null,
    applied?: boolean | null,
    appliedDate?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    job:  {
      __typename: "Job",
      id: string,
      owner?: string | null,
      jobType: string,
      businessId: string,
      jobRole: string,
      title: string,
      description?: Array< string | null > | null,
      salary?:  {
        __typename: "Salary",
        type: string,
        from?: string | null,
        to?: string | null,
      } | null,
      region: string,
      requireCV?: boolean | null,
      requireCoverLetter?: boolean | null,
      createdAt?: string | null,
      archived?: boolean | null,
      startDate?: string | null,
      endDate?: string | null,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    rejected?: boolean | null,
  } | null,
};

export type OnDeleteProfileJobSubscriptionVariables = {
  profileId?: string | null,
};

export type OnDeleteProfileJobSubscription = {
  onDeleteProfileJob?:  {
    __typename: "ProfileJob",
    id: string,
    profileId: string,
    jobId: string,
    coverLetter?: Array< string | null > | null,
    saved?: boolean | null,
    savedDate?: string | null,
    applied?: boolean | null,
    appliedDate?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    job:  {
      __typename: "Job",
      id: string,
      owner?: string | null,
      jobType: string,
      businessId: string,
      jobRole: string,
      title: string,
      description?: Array< string | null > | null,
      salary?:  {
        __typename: "Salary",
        type: string,
        from?: string | null,
        to?: string | null,
      } | null,
      region: string,
      requireCV?: boolean | null,
      requireCoverLetter?: boolean | null,
      createdAt?: string | null,
      archived?: boolean | null,
      startDate?: string | null,
      endDate?: string | null,
      updatedAt: string,
      business:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    rejected?: boolean | null,
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
  creatorId?: string | null,
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    id: string,
    postId?: string | null,
    commentId?: string | null,
    content?: Array< string | null > | null,
    creatorId: string,
    createdAt: string,
    archived?: boolean | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    post?:  {
      __typename: "Post",
      id: string,
      hubId: string,
      creatorId: string,
      content?: Array< string | null > | null,
      createdAt?: string | null,
      archived?: boolean | null,
      feedRegion: string,
      regions?: Array< string > | null,
      attachments?:  Array< {
        __typename: "attachment",
        mediaType: string,
        source: string,
        alt?: string | null,
      } | null > | null,
      updatedAt: string,
      creator:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          postId?: string | null,
          commentId?: string | null,
          content?: Array< string | null > | null,
          creatorId: string,
          createdAt: string,
          archived?: boolean | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          post?:  {
            __typename: "Post",
            id: string,
            hubId: string,
            creatorId: string,
            content?: Array< string | null > | null,
            createdAt?: string | null,
            archived?: boolean | null,
            feedRegion: string,
            regions?: Array< string > | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelPostLikeConnection",
              items:  Array< {
                __typename: "PostLike",
                id: string,
                postId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelCommentLikeConnection",
            items:  Array< {
              __typename: "CommentLike",
              id: string,
              commentId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelPostLikeConnection",
        items:  Array< {
          __typename: "PostLike",
          id: string,
          postId: string,
          profileId: string,
          createdAt: string,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelCommentLikeConnection",
      items:  Array< {
        __typename: "CommentLike",
        id: string,
        commentId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
  creatorId?: string | null,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    id: string,
    postId?: string | null,
    commentId?: string | null,
    content?: Array< string | null > | null,
    creatorId: string,
    createdAt: string,
    archived?: boolean | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    post?:  {
      __typename: "Post",
      id: string,
      hubId: string,
      creatorId: string,
      content?: Array< string | null > | null,
      createdAt?: string | null,
      archived?: boolean | null,
      feedRegion: string,
      regions?: Array< string > | null,
      attachments?:  Array< {
        __typename: "attachment",
        mediaType: string,
        source: string,
        alt?: string | null,
      } | null > | null,
      updatedAt: string,
      creator:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          postId?: string | null,
          commentId?: string | null,
          content?: Array< string | null > | null,
          creatorId: string,
          createdAt: string,
          archived?: boolean | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          post?:  {
            __typename: "Post",
            id: string,
            hubId: string,
            creatorId: string,
            content?: Array< string | null > | null,
            createdAt?: string | null,
            archived?: boolean | null,
            feedRegion: string,
            regions?: Array< string > | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelPostLikeConnection",
              items:  Array< {
                __typename: "PostLike",
                id: string,
                postId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelCommentLikeConnection",
            items:  Array< {
              __typename: "CommentLike",
              id: string,
              commentId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelPostLikeConnection",
        items:  Array< {
          __typename: "PostLike",
          id: string,
          postId: string,
          profileId: string,
          createdAt: string,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelCommentLikeConnection",
      items:  Array< {
        __typename: "CommentLike",
        id: string,
        commentId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
  creatorId?: string | null,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    id: string,
    postId?: string | null,
    commentId?: string | null,
    content?: Array< string | null > | null,
    creatorId: string,
    createdAt: string,
    archived?: boolean | null,
    attachments?:  Array< {
      __typename: "attachment",
      mediaType: string,
      source: string,
      alt?: string | null,
    } | null > | null,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    post?:  {
      __typename: "Post",
      id: string,
      hubId: string,
      creatorId: string,
      content?: Array< string | null > | null,
      createdAt?: string | null,
      archived?: boolean | null,
      feedRegion: string,
      regions?: Array< string > | null,
      attachments?:  Array< {
        __typename: "attachment",
        mediaType: string,
        source: string,
        alt?: string | null,
      } | null > | null,
      updatedAt: string,
      creator:  {
        __typename: "Profile",
        id: string,
        slug?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        bio?: Array< string | null > | null,
        picture?: string | null,
        location?:  {
          __typename: "Location",
          searchName?: string | null,
          address?: string | null,
          address2?: string | null,
          city?: string | null,
          region?: string | null,
          postcode?: string | null,
          country?: string | null,
          latitude?: string | null,
          longitude?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        business?:  {
          __typename: "Business",
          id: string,
          verified: boolean,
          name: string,
          tipping?:  {
            __typename: "BusinessTippingSetting",
            merchantId?: string | null,
            policy?: string | null,
            type?: TippingType | null,
            active?: boolean | null,
          } | null,
          marketing?:  {
            __typename: "BusinessMarketingSetting",
            customerId?: string | null,
            subscriptionId?: string | null,
            active?: boolean | null,
            expirationDate?: string | null,
            sequenceId?: string | null,
            cancelled?: boolean | null,
            cancelledAt?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          code: string,
          answers?:  Array< {
            __typename: "Answer",
            questionId?: string | null,
            value?: Array< string | null > | null,
          } | null > | null,
          isOutlet?: boolean | null,
          details?:  {
            __typename: "BusinessDetails",
            id: string,
            contactName?: string | null,
            jobRole?: string | null,
            phone?: string | null,
            email?: string | null,
            website?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          ownerProfile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          invites?:  {
            __typename: "ModelBusinessInviteConnection",
            items:  Array< {
              __typename: "BusinessInvite",
              id: string,
              businessId: string,
              receiver?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          brands?:  {
            __typename: "ModelBusinessBrandsConnection",
            items:  Array< {
              __typename: "BusinessBrands",
              id: string,
              businessId: string,
              brandId: string,
              status: BrandStatus,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profileId?: string | null,
              brand:  {
                __typename: "Brand",
                id: string,
                name: string,
                logo?: string | null,
                active?: boolean | null,
                productType?: string | null,
                createdAt: string,
                updatedAt: string,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        archived?: boolean | null,
        details?:  {
          __typename: "ProfileDetails",
          id: string,
          accountType?: string | null,
          isOnboardingComplete?: boolean | null,
          email?: string | null,
          feedRegion?: string | null,
          tippingAccount?:  {
            __typename: "TippingAccount",
            code?: string | null,
            merchant?: Merchant | null,
            accountId?: string | null,
            verified?: boolean | null,
            missingRequirements?:  Array< {
              __typename: "MissingRequirement",
              key?: string | null,
              description?: string | null,
            } | null > | null,
            pendingValidation?: Array< string | null > | null,
            bankDetails?:  {
              __typename: "BankDetails",
              name?: string | null,
              sortCode?: string | null,
              last4Digits?: string | null,
            } | null,
          } | null,
          phone_number?: string | null,
          phoneNumberConfirmed?: boolean | null,
          pushNotificationToken?: string | null,
          pushNotificationReadDate?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        employers?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        experiences?:  {
          __typename: "ModelExperienceConnection",
          items:  Array< {
            __typename: "Experience",
            id: string,
            jobTitle?: string | null,
            businessId?: string | null,
            companyName: string,
            profileId: string,
            startDate?: string | null,
            endDate?: string | null,
            employmentType?: string | null,
            location?: string | null,
            description?: string | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            profile?:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        jobs?:  {
          __typename: "ModelProfileJobConnection",
          items:  Array< {
            __typename: "ProfileJob",
            id: string,
            profileId: string,
            jobId: string,
            coverLetter?: Array< string | null > | null,
            saved?: boolean | null,
            savedDate?: string | null,
            applied?: boolean | null,
            appliedDate?: string | null,
            createdAt?: string | null,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            job:  {
              __typename: "Job",
              id: string,
              owner?: string | null,
              jobType: string,
              businessId: string,
              jobRole: string,
              title: string,
              description?: Array< string | null > | null,
              salary?:  {
                __typename: "Salary",
                type: string,
                from?: string | null,
                to?: string | null,
              } | null,
              region: string,
              requireCV?: boolean | null,
              requireCoverLetter?: boolean | null,
              createdAt?: string | null,
              archived?: boolean | null,
              startDate?: string | null,
              endDate?: string | null,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
            },
            rejected?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
          postId?: string | null,
          commentId?: string | null,
          content?: Array< string | null > | null,
          creatorId: string,
          createdAt: string,
          archived?: boolean | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          post?:  {
            __typename: "Post",
            id: string,
            hubId: string,
            creatorId: string,
            content?: Array< string | null > | null,
            createdAt?: string | null,
            archived?: boolean | null,
            feedRegion: string,
            regions?: Array< string > | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelPostLikeConnection",
              items:  Array< {
                __typename: "PostLike",
                id: string,
                postId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelCommentLikeConnection",
            items:  Array< {
              __typename: "CommentLike",
              id: string,
              commentId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      reported?:  {
        __typename: "ModelReportInfoConnection",
        items:  Array< {
          __typename: "ReportInfo",
          id: string,
          postId?: string | null,
          userId?: string | null,
          commentId?: string | null,
          jobId?: string | null,
          creatorId: string,
          createdAt: string,
          message: string,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelPostLikeConnection",
        items:  Array< {
          __typename: "PostLike",
          id: string,
          postId: string,
          profileId: string,
          createdAt: string,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        postId?: string | null,
        commentId?: string | null,
        content?: Array< string | null > | null,
        creatorId: string,
        createdAt: string,
        archived?: boolean | null,
        attachments?:  Array< {
          __typename: "attachment",
          mediaType: string,
          source: string,
          alt?: string | null,
        } | null > | null,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        post?:  {
          __typename: "Post",
          id: string,
          hubId: string,
          creatorId: string,
          content?: Array< string | null > | null,
          createdAt?: string | null,
          archived?: boolean | null,
          feedRegion: string,
          regions?: Array< string > | null,
          attachments?:  Array< {
            __typename: "attachment",
            mediaType: string,
            source: string,
            alt?: string | null,
          } | null > | null,
          updatedAt: string,
          creator:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
              postId?: string | null,
              commentId?: string | null,
              content?: Array< string | null > | null,
              creatorId: string,
              createdAt: string,
              archived?: boolean | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              post?:  {
                __typename: "Post",
                id: string,
                hubId: string,
                creatorId: string,
                content?: Array< string | null > | null,
                createdAt?: string | null,
                archived?: boolean | null,
                feedRegion: string,
                regions?: Array< string > | null,
                updatedAt: string,
              } | null,
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelCommentLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          reported?:  {
            __typename: "ModelReportInfoConnection",
            items:  Array< {
              __typename: "ReportInfo",
              id: string,
              postId?: string | null,
              userId?: string | null,
              commentId?: string | null,
              jobId?: string | null,
              creatorId: string,
              createdAt: string,
              message: string,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
          likes?:  {
            __typename: "ModelPostLikeConnection",
            items:  Array< {
              __typename: "PostLike",
              id: string,
              postId: string,
              profileId: string,
              createdAt: string,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
            postId?: string | null,
            commentId?: string | null,
            content?: Array< string | null > | null,
            creatorId: string,
            createdAt: string,
            archived?: boolean | null,
            attachments?:  Array< {
              __typename: "attachment",
              mediaType: string,
              source: string,
              alt?: string | null,
            } | null > | null,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            post?:  {
              __typename: "Post",
              id: string,
              hubId: string,
              creatorId: string,
              content?: Array< string | null > | null,
              createdAt?: string | null,
              archived?: boolean | null,
              feedRegion: string,
              regions?: Array< string > | null,
              attachments?:  Array< {
                __typename: "attachment",
                mediaType: string,
                source: string,
                alt?: string | null,
              } | null > | null,
              updatedAt: string,
              creator:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              comments?:  {
                __typename: "ModelCommentConnection",
                nextToken?: string | null,
              } | null,
              reported?:  {
                __typename: "ModelReportInfoConnection",
                nextToken?: string | null,
              } | null,
              likes?:  {
                __typename: "ModelPostLikeConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            comments?:  {
              __typename: "ModelCommentConnection",
              items:  Array< {
                __typename: "Comment",
                id: string,
                postId?: string | null,
                commentId?: string | null,
                content?: Array< string | null > | null,
                creatorId: string,
                createdAt: string,
                archived?: boolean | null,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            likes?:  {
              __typename: "ModelCommentLikeConnection",
              items:  Array< {
                __typename: "CommentLike",
                id: string,
                commentId: string,
                profileId: string,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        reported?:  {
          __typename: "ModelReportInfoConnection",
          items:  Array< {
            __typename: "ReportInfo",
            id: string,
            postId?: string | null,
            userId?: string | null,
            commentId?: string | null,
            jobId?: string | null,
            creatorId: string,
            createdAt: string,
            message: string,
            updatedAt: string,
            creator:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        likes?:  {
          __typename: "ModelCommentLikeConnection",
          items:  Array< {
            __typename: "CommentLike",
            id: string,
            commentId: string,
            profileId: string,
            createdAt: string,
            updatedAt: string,
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reported?:  {
      __typename: "ModelReportInfoConnection",
      items:  Array< {
        __typename: "ReportInfo",
        id: string,
        postId?: string | null,
        userId?: string | null,
        commentId?: string | null,
        jobId?: string | null,
        creatorId: string,
        createdAt: string,
        message: string,
        updatedAt: string,
        creator:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelCommentLikeConnection",
      items:  Array< {
        __typename: "CommentLike",
        id: string,
        commentId: string,
        profileId: string,
        createdAt: string,
        updatedAt: string,
        profile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateReportInfoSubscriptionVariables = {
  creatorId?: string | null,
};

export type OnCreateReportInfoSubscription = {
  onCreateReportInfo?:  {
    __typename: "ReportInfo",
    id: string,
    postId?: string | null,
    userId?: string | null,
    commentId?: string | null,
    jobId?: string | null,
    creatorId: string,
    createdAt: string,
    message: string,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateReportInfoSubscriptionVariables = {
  creatorId?: string | null,
};

export type OnUpdateReportInfoSubscription = {
  onUpdateReportInfo?:  {
    __typename: "ReportInfo",
    id: string,
    postId?: string | null,
    userId?: string | null,
    commentId?: string | null,
    jobId?: string | null,
    creatorId: string,
    createdAt: string,
    message: string,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteReportInfoSubscriptionVariables = {
  creatorId?: string | null,
};

export type OnDeleteReportInfoSubscription = {
  onDeleteReportInfo?:  {
    __typename: "ReportInfo",
    id: string,
    postId?: string | null,
    userId?: string | null,
    commentId?: string | null,
    jobId?: string | null,
    creatorId: string,
    createdAt: string,
    message: string,
    updatedAt: string,
    creator:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreatePostLikeSubscriptionVariables = {
};

export type OnCreatePostLikeSubscription = {
  onCreatePostLike?:  {
    __typename: "PostLike",
    id: string,
    postId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdatePostLikeSubscriptionVariables = {
};

export type OnUpdatePostLikeSubscription = {
  onUpdatePostLike?:  {
    __typename: "PostLike",
    id: string,
    postId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeletePostLikeSubscriptionVariables = {
};

export type OnDeletePostLikeSubscription = {
  onDeletePostLike?:  {
    __typename: "PostLike",
    id: string,
    postId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateCommentLikeSubscriptionVariables = {
};

export type OnCreateCommentLikeSubscription = {
  onCreateCommentLike?:  {
    __typename: "CommentLike",
    id: string,
    commentId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateCommentLikeSubscriptionVariables = {
};

export type OnUpdateCommentLikeSubscription = {
  onUpdateCommentLike?:  {
    __typename: "CommentLike",
    id: string,
    commentId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteCommentLikeSubscriptionVariables = {
};

export type OnDeleteCommentLikeSubscription = {
  onDeleteCommentLike?:  {
    __typename: "CommentLike",
    id: string,
    commentId: string,
    profileId: string,
    createdAt: string,
    updatedAt: string,
    profile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateUserNotificationSubscriptionVariables = {
  receiverId?: string | null,
};

export type OnCreateUserNotificationSubscription = {
  onCreateUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    receiverId: string,
    senderId?: string | null,
    type: NotificationType,
    targetId?: string | null,
    subTargetId?: string | null,
    additionalTargetId?: string | null,
    read: boolean,
    title?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateUserNotificationSubscriptionVariables = {
  receiverId?: string | null,
};

export type OnUpdateUserNotificationSubscription = {
  onUpdateUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    receiverId: string,
    senderId?: string | null,
    type: NotificationType,
    targetId?: string | null,
    subTargetId?: string | null,
    additionalTargetId?: string | null,
    read: boolean,
    title?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteUserNotificationSubscriptionVariables = {
  receiverId?: string | null,
};

export type OnDeleteUserNotificationSubscription = {
  onDeleteUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    receiverId: string,
    senderId?: string | null,
    type: NotificationType,
    targetId?: string | null,
    subTargetId?: string | null,
    additionalTargetId?: string | null,
    read: boolean,
    title?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreatePublicEmailSubscriptionVariables = {
};

export type OnCreatePublicEmailSubscription = {
  onCreatePublicEmail?:  {
    __typename: "PublicEmail",
    id: string,
    email: string,
    allowMarketing: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePublicEmailSubscriptionVariables = {
};

export type OnUpdatePublicEmailSubscription = {
  onUpdatePublicEmail?:  {
    __typename: "PublicEmail",
    id: string,
    email: string,
    allowMarketing: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePublicEmailSubscriptionVariables = {
};

export type OnDeletePublicEmailSubscription = {
  onDeletePublicEmail?:  {
    __typename: "PublicEmail",
    id: string,
    email: string,
    allowMarketing: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTipSplitSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTipSplitSubscription = {
  onCreateTipSplit?:  {
    __typename: "TipSplit",
    id: string,
    amount: number,
    owner: string,
    status: TransactionStatus,
    from: string,
    to: string,
    createdAt: string,
    updatedAt: string,
    ownerProfile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    transfers?:  {
      __typename: "ModelEmployeeTransferConnection",
      items:  Array< {
        __typename: "EmployeeTransfer",
        id: string,
        splitId: string,
        employeeId: string,
        amount: number,
        status: TransactionStatus,
        createdAt: string,
        updatedAt: string,
        employee?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateTipSplitSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTipSplitSubscription = {
  onUpdateTipSplit?:  {
    __typename: "TipSplit",
    id: string,
    amount: number,
    owner: string,
    status: TransactionStatus,
    from: string,
    to: string,
    createdAt: string,
    updatedAt: string,
    ownerProfile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    transfers?:  {
      __typename: "ModelEmployeeTransferConnection",
      items:  Array< {
        __typename: "EmployeeTransfer",
        id: string,
        splitId: string,
        employeeId: string,
        amount: number,
        status: TransactionStatus,
        createdAt: string,
        updatedAt: string,
        employee?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteTipSplitSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTipSplitSubscription = {
  onDeleteTipSplit?:  {
    __typename: "TipSplit",
    id: string,
    amount: number,
    owner: string,
    status: TransactionStatus,
    from: string,
    to: string,
    createdAt: string,
    updatedAt: string,
    ownerProfile:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    transfers?:  {
      __typename: "ModelEmployeeTransferConnection",
      items:  Array< {
        __typename: "EmployeeTransfer",
        id: string,
        splitId: string,
        employeeId: string,
        amount: number,
        status: TransactionStatus,
        createdAt: string,
        updatedAt: string,
        employee?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateEmployeeTransferSubscriptionVariables = {
  employeeId?: string | null,
};

export type OnCreateEmployeeTransferSubscription = {
  onCreateEmployeeTransfer?:  {
    __typename: "EmployeeTransfer",
    id: string,
    splitId: string,
    employeeId: string,
    amount: number,
    status: TransactionStatus,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateEmployeeTransferSubscriptionVariables = {
  employeeId?: string | null,
};

export type OnUpdateEmployeeTransferSubscription = {
  onUpdateEmployeeTransfer?:  {
    __typename: "EmployeeTransfer",
    id: string,
    splitId: string,
    employeeId: string,
    amount: number,
    status: TransactionStatus,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteEmployeeTransferSubscriptionVariables = {
  employeeId?: string | null,
};

export type OnDeleteEmployeeTransferSubscription = {
  onDeleteEmployeeTransfer?:  {
    __typename: "EmployeeTransfer",
    id: string,
    splitId: string,
    employeeId: string,
    amount: number,
    status: TransactionStatus,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateTipTransactionSubscriptionVariables = {
  receiverId?: string | null,
};

export type OnCreateTipTransactionSubscription = {
  onCreateTipTransaction?:  {
    __typename: "TipTransaction",
    id: string,
    splitId?: string | null,
    receiverId: string,
    amount: string,
    fee: string,
    currency: string,
    createdAt: string,
    status: TransactionStatus,
    type: TippingType,
    source: TipTransactionSource,
    payoutInvoiceId?: string | null,
    transactionId?: string | null,
    updatedAt: string,
    receiver?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateTipTransactionSubscriptionVariables = {
  receiverId?: string | null,
};

export type OnUpdateTipTransactionSubscription = {
  onUpdateTipTransaction?:  {
    __typename: "TipTransaction",
    id: string,
    splitId?: string | null,
    receiverId: string,
    amount: string,
    fee: string,
    currency: string,
    createdAt: string,
    status: TransactionStatus,
    type: TippingType,
    source: TipTransactionSource,
    payoutInvoiceId?: string | null,
    transactionId?: string | null,
    updatedAt: string,
    receiver?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteTipTransactionSubscriptionVariables = {
  receiverId?: string | null,
};

export type OnDeleteTipTransactionSubscription = {
  onDeleteTipTransaction?:  {
    __typename: "TipTransaction",
    id: string,
    splitId?: string | null,
    receiverId: string,
    amount: string,
    fee: string,
    currency: string,
    createdAt: string,
    status: TransactionStatus,
    type: TippingType,
    source: TipTransactionSource,
    payoutInvoiceId?: string | null,
    transactionId?: string | null,
    updatedAt: string,
    receiver?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateBusinessSetupTippingInviteSubscriptionVariables = {
  sender?: string | null,
};

export type OnCreateBusinessSetupTippingInviteSubscription = {
  onCreateBusinessSetupTippingInvite?:  {
    __typename: "BusinessSetupTippingInvite",
    id: string,
    sender: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBusinessSetupTippingInviteSubscriptionVariables = {
  sender?: string | null,
};

export type OnUpdateBusinessSetupTippingInviteSubscription = {
  onUpdateBusinessSetupTippingInvite?:  {
    __typename: "BusinessSetupTippingInvite",
    id: string,
    sender: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBusinessSetupTippingInviteSubscriptionVariables = {
  sender?: string | null,
};

export type OnDeleteBusinessSetupTippingInviteSubscription = {
  onDeleteBusinessSetupTippingInvite?:  {
    __typename: "BusinessSetupTippingInvite",
    id: string,
    sender: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserMessageSubscriptionVariables = {
};

export type OnCreateUserMessageSubscription = {
  onCreateUserMessage?:  {
    __typename: "UserMessage",
    id: string,
    senderId: string,
    receiverId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateUserMessageSubscriptionVariables = {
};

export type OnUpdateUserMessageSubscription = {
  onUpdateUserMessage?:  {
    __typename: "UserMessage",
    id: string,
    senderId: string,
    receiverId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteUserMessageSubscriptionVariables = {
};

export type OnDeleteUserMessageSubscription = {
  onDeleteUserMessage?:  {
    __typename: "UserMessage",
    id: string,
    senderId: string,
    receiverId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    sender?:  {
      __typename: "Profile",
      id: string,
      slug?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      bio?: Array< string | null > | null,
      picture?: string | null,
      location?:  {
        __typename: "Location",
        searchName?: string | null,
        address?: string | null,
        address2?: string | null,
        city?: string | null,
        region?: string | null,
        postcode?: string | null,
        country?: string | null,
        latitude?: string | null,
        longitude?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      business?:  {
        __typename: "Business",
        id: string,
        verified: boolean,
        name: string,
        tipping?:  {
          __typename: "BusinessTippingSetting",
          merchantId?: string | null,
          policy?: string | null,
          type?: TippingType | null,
          active?: boolean | null,
        } | null,
        marketing?:  {
          __typename: "BusinessMarketingSetting",
          customerId?: string | null,
          subscriptionId?: string | null,
          active?: boolean | null,
          expirationDate?: string | null,
          sequenceId?: string | null,
          cancelled?: boolean | null,
          cancelledAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        code: string,
        answers?:  Array< {
          __typename: "Answer",
          questionId?: string | null,
          value?: Array< string | null > | null,
        } | null > | null,
        isOutlet?: boolean | null,
        details?:  {
          __typename: "BusinessDetails",
          id: string,
          contactName?: string | null,
          jobRole?: string | null,
          phone?: string | null,
          email?: string | null,
          website?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        ownerProfile:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        },
        invites?:  {
          __typename: "ModelBusinessInviteConnection",
          items:  Array< {
            __typename: "BusinessInvite",
            id: string,
            businessId: string,
            receiver?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelBusinessEmployeeConnection",
          items:  Array< {
            __typename: "BusinessEmployee",
            id: string,
            jobRole: string,
            businessId: string,
            profileId: string,
            employeeId?: string | null,
            tippingPolicyAcknowledgedDate?: string | null,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            confirmed?: boolean | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        brands?:  {
          __typename: "ModelBusinessBrandsConnection",
          items:  Array< {
            __typename: "BusinessBrands",
            id: string,
            businessId: string,
            brandId: string,
            status: BrandStatus,
            createdAt: string,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            profileId?: string | null,
            brand:  {
              __typename: "Brand",
              id: string,
              name: string,
              logo?: string | null,
              active?: boolean | null,
              productType?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      archived?: boolean | null,
      details?:  {
        __typename: "ProfileDetails",
        id: string,
        accountType?: string | null,
        isOnboardingComplete?: boolean | null,
        email?: string | null,
        feedRegion?: string | null,
        tippingAccount?:  {
          __typename: "TippingAccount",
          code?: string | null,
          merchant?: Merchant | null,
          accountId?: string | null,
          verified?: boolean | null,
          missingRequirements?:  Array< {
            __typename: "MissingRequirement",
            key?: string | null,
            description?: string | null,
          } | null > | null,
          pendingValidation?: Array< string | null > | null,
          bankDetails?:  {
            __typename: "BankDetails",
            name?: string | null,
            sortCode?: string | null,
            last4Digits?: string | null,
          } | null,
        } | null,
        phone_number?: string | null,
        phoneNumberConfirmed?: boolean | null,
        pushNotificationToken?: string | null,
        pushNotificationReadDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employers?:  {
        __typename: "ModelBusinessEmployeeConnection",
        items:  Array< {
          __typename: "BusinessEmployee",
          id: string,
          jobRole: string,
          businessId: string,
          profileId: string,
          employeeId?: string | null,
          tippingPolicyAcknowledgedDate?: string | null,
          createdAt: string,
          updatedAt: string,
          business:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          confirmed?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      experiences?:  {
        __typename: "ModelExperienceConnection",
        items:  Array< {
          __typename: "Experience",
          id: string,
          jobTitle?: string | null,
          businessId?: string | null,
          companyName: string,
          profileId: string,
          startDate?: string | null,
          endDate?: string | null,
          employmentType?: string | null,
          location?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          profile?:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelProfileJobConnection",
        items:  Array< {
          __typename: "ProfileJob",
          id: string,
          profileId: string,
          jobId: string,
          coverLetter?: Array< string | null > | null,
          saved?: boolean | null,
          savedDate?: string | null,
          applied?: boolean | null,
          appliedDate?: string | null,
          createdAt?: string | null,
          updatedAt: string,
          profile:  {
            __typename: "Profile",
            id: string,
            slug?: string | null,
            firstName?: string | null,
            lastName?: string | null,
            bio?: Array< string | null > | null,
            picture?: string | null,
            location?:  {
              __typename: "Location",
              searchName?: string | null,
              address?: string | null,
              address2?: string | null,
              city?: string | null,
              region?: string | null,
              postcode?: string | null,
              country?: string | null,
              latitude?: string | null,
              longitude?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            business?:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            } | null,
            archived?: boolean | null,
            details?:  {
              __typename: "ProfileDetails",
              id: string,
              accountType?: string | null,
              isOnboardingComplete?: boolean | null,
              email?: string | null,
              feedRegion?: string | null,
              tippingAccount?:  {
                __typename: "TippingAccount",
                code?: string | null,
                merchant?: Merchant | null,
                accountId?: string | null,
                verified?: boolean | null,
                pendingValidation?: Array< string | null > | null,
              } | null,
              phone_number?: string | null,
              phoneNumberConfirmed?: boolean | null,
              pushNotificationToken?: string | null,
              pushNotificationReadDate?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            employers?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            experiences?:  {
              __typename: "ModelExperienceConnection",
              items:  Array< {
                __typename: "Experience",
                id: string,
                jobTitle?: string | null,
                businessId?: string | null,
                companyName: string,
                profileId: string,
                startDate?: string | null,
                endDate?: string | null,
                employmentType?: string | null,
                location?: string | null,
                description?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            jobs?:  {
              __typename: "ModelProfileJobConnection",
              items:  Array< {
                __typename: "ProfileJob",
                id: string,
                profileId: string,
                jobId: string,
                coverLetter?: Array< string | null > | null,
                saved?: boolean | null,
                savedDate?: string | null,
                applied?: boolean | null,
                appliedDate?: string | null,
                createdAt?: string | null,
                updatedAt: string,
                rejected?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          job:  {
            __typename: "Job",
            id: string,
            owner?: string | null,
            jobType: string,
            businessId: string,
            jobRole: string,
            title: string,
            description?: Array< string | null > | null,
            salary?:  {
              __typename: "Salary",
              type: string,
              from?: string | null,
              to?: string | null,
            } | null,
            region: string,
            requireCV?: boolean | null,
            requireCoverLetter?: boolean | null,
            createdAt?: string | null,
            archived?: boolean | null,
            startDate?: string | null,
            endDate?: string | null,
            updatedAt: string,
            business:  {
              __typename: "Business",
              id: string,
              verified: boolean,
              name: string,
              tipping?:  {
                __typename: "BusinessTippingSetting",
                merchantId?: string | null,
                policy?: string | null,
                type?: TippingType | null,
                active?: boolean | null,
              } | null,
              marketing?:  {
                __typename: "BusinessMarketingSetting",
                customerId?: string | null,
                subscriptionId?: string | null,
                active?: boolean | null,
                expirationDate?: string | null,
                sequenceId?: string | null,
                cancelled?: boolean | null,
                cancelledAt?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              code: string,
              answers?:  Array< {
                __typename: "Answer",
                questionId?: string | null,
                value?: Array< string | null > | null,
              } | null > | null,
              isOutlet?: boolean | null,
              details?:  {
                __typename: "BusinessDetails",
                id: string,
                contactName?: string | null,
                jobRole?: string | null,
                phone?: string | null,
                email?: string | null,
                website?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              ownerProfile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              invites?:  {
                __typename: "ModelBusinessInviteConnection",
                nextToken?: string | null,
              } | null,
              employees?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              brands?:  {
                __typename: "ModelBusinessBrandsConnection",
                nextToken?: string | null,
              } | null,
            },
            reported?:  {
              __typename: "ModelReportInfoConnection",
              items:  Array< {
                __typename: "ReportInfo",
                id: string,
                postId?: string | null,
                userId?: string | null,
                commentId?: string | null,
                jobId?: string | null,
                creatorId: string,
                createdAt: string,
                message: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
          },
          rejected?: boolean | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateUserPhoneValidationSubscriptionVariables = {
  userId?: string | null,
};

export type OnCreateUserPhoneValidationSubscription = {
  onCreateUserPhoneValidation?:  {
    __typename: "UserPhoneValidation",
    id: string,
    userId: string,
    phoneNumber: string,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserPhoneValidationSubscriptionVariables = {
  userId?: string | null,
};

export type OnUpdateUserPhoneValidationSubscription = {
  onUpdateUserPhoneValidation?:  {
    __typename: "UserPhoneValidation",
    id: string,
    userId: string,
    phoneNumber: string,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserPhoneValidationSubscriptionVariables = {
  userId?: string | null,
};

export type OnDeleteUserPhoneValidationSubscription = {
  onDeleteUserPhoneValidation?:  {
    __typename: "UserPhoneValidation",
    id: string,
    userId: string,
    phoneNumber: string,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePayoutInvoiceSubscriptionVariables = {
};

export type OnCreatePayoutInvoiceSubscription = {
  onCreatePayoutInvoice?:  {
    __typename: "PayoutInvoice",
    id: string,
    createdAt: string,
    file: string,
    amount: number,
    fee: number,
    currency: string,
    status: PayoutStatus,
    payedAt?: string | null,
    updatedAt: string,
    tipTransactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdatePayoutInvoiceSubscriptionVariables = {
};

export type OnUpdatePayoutInvoiceSubscription = {
  onUpdatePayoutInvoice?:  {
    __typename: "PayoutInvoice",
    id: string,
    createdAt: string,
    file: string,
    amount: number,
    fee: number,
    currency: string,
    status: PayoutStatus,
    payedAt?: string | null,
    updatedAt: string,
    tipTransactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeletePayoutInvoiceSubscriptionVariables = {
};

export type OnDeletePayoutInvoiceSubscription = {
  onDeletePayoutInvoice?:  {
    __typename: "PayoutInvoice",
    id: string,
    createdAt: string,
    file: string,
    amount: number,
    fee: number,
    currency: string,
    status: PayoutStatus,
    payedAt?: string | null,
    updatedAt: string,
    tipTransactions?:  {
      __typename: "ModelTipTransactionConnection",
      items:  Array< {
        __typename: "TipTransaction",
        id: string,
        splitId?: string | null,
        receiverId: string,
        amount: string,
        fee: string,
        currency: string,
        createdAt: string,
        status: TransactionStatus,
        type: TippingType,
        source: TipTransactionSource,
        payoutInvoiceId?: string | null,
        transactionId?: string | null,
        updatedAt: string,
        receiver?:  {
          __typename: "Profile",
          id: string,
          slug?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          bio?: Array< string | null > | null,
          picture?: string | null,
          location?:  {
            __typename: "Location",
            searchName?: string | null,
            address?: string | null,
            address2?: string | null,
            city?: string | null,
            region?: string | null,
            postcode?: string | null,
            country?: string | null,
            latitude?: string | null,
            longitude?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          business?:  {
            __typename: "Business",
            id: string,
            verified: boolean,
            name: string,
            tipping?:  {
              __typename: "BusinessTippingSetting",
              merchantId?: string | null,
              policy?: string | null,
              type?: TippingType | null,
              active?: boolean | null,
            } | null,
            marketing?:  {
              __typename: "BusinessMarketingSetting",
              customerId?: string | null,
              subscriptionId?: string | null,
              active?: boolean | null,
              expirationDate?: string | null,
              sequenceId?: string | null,
              cancelled?: boolean | null,
              cancelledAt?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            code: string,
            answers?:  Array< {
              __typename: "Answer",
              questionId?: string | null,
              value?: Array< string | null > | null,
            } | null > | null,
            isOutlet?: boolean | null,
            details?:  {
              __typename: "BusinessDetails",
              id: string,
              contactName?: string | null,
              jobRole?: string | null,
              phone?: string | null,
              email?: string | null,
              website?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            ownerProfile:  {
              __typename: "Profile",
              id: string,
              slug?: string | null,
              firstName?: string | null,
              lastName?: string | null,
              bio?: Array< string | null > | null,
              picture?: string | null,
              location?:  {
                __typename: "Location",
                searchName?: string | null,
                address?: string | null,
                address2?: string | null,
                city?: string | null,
                region?: string | null,
                postcode?: string | null,
                country?: string | null,
                latitude?: string | null,
                longitude?: string | null,
              } | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              archived?: boolean | null,
              details?:  {
                __typename: "ProfileDetails",
                id: string,
                accountType?: string | null,
                isOnboardingComplete?: boolean | null,
                email?: string | null,
                feedRegion?: string | null,
                phone_number?: string | null,
                phoneNumberConfirmed?: boolean | null,
                pushNotificationToken?: string | null,
                pushNotificationReadDate?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null,
              employers?:  {
                __typename: "ModelBusinessEmployeeConnection",
                nextToken?: string | null,
              } | null,
              experiences?:  {
                __typename: "ModelExperienceConnection",
                nextToken?: string | null,
              } | null,
              jobs?:  {
                __typename: "ModelProfileJobConnection",
                nextToken?: string | null,
              } | null,
            },
            invites?:  {
              __typename: "ModelBusinessInviteConnection",
              items:  Array< {
                __typename: "BusinessInvite",
                id: string,
                businessId: string,
                receiver?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            employees?:  {
              __typename: "ModelBusinessEmployeeConnection",
              items:  Array< {
                __typename: "BusinessEmployee",
                id: string,
                jobRole: string,
                businessId: string,
                profileId: string,
                employeeId?: string | null,
                tippingPolicyAcknowledgedDate?: string | null,
                createdAt: string,
                updatedAt: string,
                confirmed?: boolean | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            brands?:  {
              __typename: "ModelBusinessBrandsConnection",
              items:  Array< {
                __typename: "BusinessBrands",
                id: string,
                businessId: string,
                brandId: string,
                status: BrandStatus,
                createdAt: string,
                updatedAt: string,
                profileId?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
          } | null,
          archived?: boolean | null,
          details?:  {
            __typename: "ProfileDetails",
            id: string,
            accountType?: string | null,
            isOnboardingComplete?: boolean | null,
            email?: string | null,
            feedRegion?: string | null,
            tippingAccount?:  {
              __typename: "TippingAccount",
              code?: string | null,
              merchant?: Merchant | null,
              accountId?: string | null,
              verified?: boolean | null,
              missingRequirements?:  Array< {
                __typename: "MissingRequirement",
                key?: string | null,
                description?: string | null,
              } | null > | null,
              pendingValidation?: Array< string | null > | null,
              bankDetails?:  {
                __typename: "BankDetails",
                name?: string | null,
                sortCode?: string | null,
                last4Digits?: string | null,
              } | null,
            } | null,
            phone_number?: string | null,
            phoneNumberConfirmed?: boolean | null,
            pushNotificationToken?: string | null,
            pushNotificationReadDate?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          employers?:  {
            __typename: "ModelBusinessEmployeeConnection",
            items:  Array< {
              __typename: "BusinessEmployee",
              id: string,
              jobRole: string,
              businessId: string,
              profileId: string,
              employeeId?: string | null,
              tippingPolicyAcknowledgedDate?: string | null,
              createdAt: string,
              updatedAt: string,
              business:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              },
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              confirmed?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          experiences?:  {
            __typename: "ModelExperienceConnection",
            items:  Array< {
              __typename: "Experience",
              id: string,
              jobTitle?: string | null,
              businessId?: string | null,
              companyName: string,
              profileId: string,
              startDate?: string | null,
              endDate?: string | null,
              employmentType?: string | null,
              location?: string | null,
              description?: string | null,
              createdAt: string,
              updatedAt: string,
              business?:  {
                __typename: "Business",
                id: string,
                verified: boolean,
                name: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                code: string,
                isOutlet?: boolean | null,
              } | null,
              profile?:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          jobs?:  {
            __typename: "ModelProfileJobConnection",
            items:  Array< {
              __typename: "ProfileJob",
              id: string,
              profileId: string,
              jobId: string,
              coverLetter?: Array< string | null > | null,
              saved?: boolean | null,
              savedDate?: string | null,
              applied?: boolean | null,
              appliedDate?: string | null,
              createdAt?: string | null,
              updatedAt: string,
              profile:  {
                __typename: "Profile",
                id: string,
                slug?: string | null,
                firstName?: string | null,
                lastName?: string | null,
                bio?: Array< string | null > | null,
                picture?: string | null,
                createdAt: string,
                updatedAt: string,
                archived?: boolean | null,
              },
              job:  {
                __typename: "Job",
                id: string,
                owner?: string | null,
                jobType: string,
                businessId: string,
                jobRole: string,
                title: string,
                description?: Array< string | null > | null,
                region: string,
                requireCV?: boolean | null,
                requireCoverLetter?: boolean | null,
                createdAt?: string | null,
                archived?: boolean | null,
                startDate?: string | null,
                endDate?: string | null,
                updatedAt: string,
              },
              rejected?: boolean | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};
